import React, { useState } from "react"
import MetaTags from "react-meta-tags"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Input,
  Alert,
  BreadcrumbItem,
  Button,
  TabContent,
  TabPane,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import DataTable from "react-data-table-component"

import {
  editCampaign,
  addCampaign,
  getCampaigns,
  getFollowUpCampaigns,
  deleteCampaign,
  getMarkets,
} from "../../store/campaigns/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Switch from "react-switch"
import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"
import sDrop from "../../assets/images/select-drop.svg"
import phoneIcon from "../../assets/images/phone.svg"
import boltIcon from "../../assets/images/bolt.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import searchIcon from "../../assets/images/serachIconAccent.svg"
import removeIcon from "../../assets/images/removeIconWhite.svg"
import toast, { Toaster } from "react-hot-toast"
import axios from "axios"
import Select from "react-select"

class Campaigns extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirm_alert: false,
      campaign_name: null,
      campaign_id: null,
      campaign_delay: null,
      confirm_button_text: "",
      modal_center: false,
      market_name: null, //new
      market_id: null, //new
      show: false,
      modal_add: false,
      modal_add_follow_Up: false,
      modal_add_select_campaign: false,
      selected_campaign: [],
      followUpModalData: [],
      all_selected: false,
      followupCampaignModal: {
        isOpen: false,
        selectedCampaigns: [],
        isRetain: false,
        leadStage: [],
        prospect: [],
        totalProspect: [],
      },
      selectCampaignModal: { isOpen: false, selectedCampaigns: [] },
      isLoading: false,
      activeTab: "campaigns",
      followupCampaigns: [],
      showProspectCount: false,
    }
  }

  removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  tog_center() {
    this.removeBodyCss()
    this.setState({
      modal_center: !this.state.modal_center,
    })
  }
  tog_add() {
    this.removeBodyCss()
    this.setState({
      modal_add: !this.state.modal_add,
    })
  }

  toggleFollowupModal() {
    this.removeBodyCss()
    if (!this.state.followupCampaignModal?.isOpen) {
      this.getFollowUpModalData()
      this.setState({
        followupCampaignModal: { isOpen: true },
      })
    } else {
      this.setState({
        followupCampaignModal: { isOpen: false },
      })
    }
  }

  toggleSelectCampaignModal() {
    this.removeBodyCss()
    if (!this.state.selectCampaignModal?.isOpen) {
      // API Call function
      const tempArr =
        this.state.followupCampaignModal?.selectedCampaigns?.map(
          item => item.campaign._id
        ) || []
      this.setState({
        selectCampaignModal: { isOpen: true, selectedCampaigns: tempArr },
      })
    } else {
      this.setState({
        selectCampaignModal: { isOpen: false },
      })
    }
  }

  removeSelectedCampaign(index) {
    let tempArr = this.state.followupCampaignModal?.selectedCampaigns || []
    tempArr.splice(index, 1)
    this.setState({
      followupCampaignModal: {
        ...this.state.followupCampaignModal,
        selectedCampaigns: tempArr,
        selectedMarket: null,
      },
    })
  }

  confirmText() {
    return this.state.campaign_name
  }

  editCampaign(name, id, delay, market_id, market_name) {
    this.setState({
      modal_center: true,
      campaign_name: name,
      campaign_id: id,
      campaign_delay: delay,
      market_id: market_id,
      market_name: market_name,
    })
  }

  deleteMe(name, id) {
    this.setState({
      campaign_name: name,
      campaign_id: id,
      confirm_alert: true,
      confirm_button_text: "Yes, remove " + this.state.campaign_name,
    })
  }

  async getFollowUpModalData() {
    this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/campaign/getFollowupModalData",
        config
      )
      if (response.data) {
        this.setState({
          followUpModalData: response.data.data,
          isLoading: false,
        })
      }
    } catch (err) {
      this.notify("error", err.message)
      this.setState({ isLoading: false })
    }
  }

  componentDidMount() {
    this.props.getCampaigns()
    this.props.getMarkets()
  }

  componentDidUpdate(prevProps) {
    console.log(this.props.campaigns)
    if (!prevProps.successMsg && this.props.successMsg) {
      this.notify("success", this.props.successMsg)
    } else if (
      this.props.apiError &&
      typeof this.props.apiError === "string" &&
      !prevProps.apiError
    ) {
      this.notify("error", this.props.apiError)
    } else if (
      !prevProps.apiError &&
      this.props.apiError &&
      typeof this.props.apiError === "object"
    ) {
      if (this.props.apiError.response) {
        this.notify("error", this.props.apiError.response.data.error)
      } else {
        this.notify("error", this.props.apiError.message)
      }
    }
  }

  handleValidSubmit(event, values) {
    this.setState({
      modal_center: false,
    })
    this.props.editCampaign({
      id: this.state.campaign_id,
      name: values.campaign_name,
      delay: values.campaign_delay,
      market: values.market,
    })
  }

  handleCampaignCreate(event, values) {
    this.setState({
      modal_add: false,
    })
    this.props.addCampaign({
      campaignName: values.campaign_name,
      market: values.market,
      delay: values.campaign_delay,
    })
  }
  async handleFollowUpCampaignCreate(event, values) {
    this.setState({ isLoading: true })
    let token = false
    const authUser = localStorage.getItem("authUser")
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    if (!this.state.showProspectCount) {
      // const selectedCampaings =
      //   this.state.followupCampaignModal.selectedCampaigns?.map(
      //     item => item.campaign._id
      //   )
      const selectedCampaings =
        this.state.followupCampaignModal.selectedCampaigns[0]?.campaign._id
      const skipReasonObj = this.state.followUpModalData.skipRules?.filter(
        item => item._id == values?.skipReason
      )
      this.setState(prevState => ({
        followupCampaignModal: {
          ...prevState.followupCampaignModal,
          skipReason: values.skipReason,
          prospectResponded: values.includeProspect,
        },
      }))
      try {
        const prospectesWhoAre = {}
        this.state.followupCampaignModal.prospect?.map(item => {
          prospectesWhoAre[item] = true
        })

        const data = {
          campaign: selectedCampaings,
          isRetainNumber: this.state.followupCampaignModal.isRetain,
          leadStages: this.state.followupCampaignModal.leadStage,
          exclude: this.state.followupCampaignModal.excludeProspect,
          // excludeProspect: this.state.followUpModalData.selectedExcludedProspect,
          skipReason: skipReasonObj?.[0],
          prospectResponded: values.includeProspect
            ? JSON.parse(values.includeProspect)
            : null,
          prospectesWhoAre,
        }
        const response = await axios.post(
          process.env.REACT_APP_PORT +
            "/api/campaign/checkProspectsForFollowUp",
          data,
          config
        )

        if (response.data) {
          this.setState(prevState => ({
            followupCampaignModal: {
              ...prevState.followupCampaignModal,
              totalProspect: response.data.data,
            },
          }))

          this.setState(prevState => ({
            followupCampaignModal: {
              ...prevState.followupCampaignModal,
              name: values.followUpCampaignName,
              market: values.market,
              delay: values.campaign_delay,
            },
            isLoading: false,
            showProspectCount: true,
          }))
        }
      } catch (err) {
        this.notify("error", err.message)
        this.setState({ isLoading: false })
      }
    } else {
      try {
        const arraydata = this.state.followupCampaignModal?.totalProspect?.map(
          item => {
            return item._id
          }
        )
        const data = {
          isRetain: !!this.state.followupCampaignModal.isRetain,
          name: this.state.followupCampaignModal.name,
          prospects: arraydata,
          market: this.state.followupCampaignModal.market,
          delay: +this.state.followupCampaignModal.delay,
        }

        const response = await axios.post(
          process.env.REACT_APP_PORT + "/api/campaign/createFollowUpCampaign",
          data,
          config
        )
        if (response.data) {
          this.props.getFollowUpCampaigns()
          this.notify("success", "Follow Up Campaign Created successfully")
          this.setState({
            followupCampaignModal: { isOpen: false },
            isLoading: false,
            showProspectCount: false,
          })
        }
      } catch (err) {
        this.notify("error", err.message)
        this.setState({ isLoading: false })
      }
    }
  }

  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        toast.error(text)
        break

      default:
        break
    }
  }

  render() {
    // const option = this.state.followUpModalData.leadStages?.map(item => [{value: item.lead_Stage, label: item.lead_Stage}]);
    const prospectOptions = [
      { value: "isPriority", label: "Priority" },
      { value: "isQualified", label: "Qualified" },
      { value: "isDripAdded", label: "Drip" },
    ]
    const exludeProspect = [
      { value: "dnc", label: "DNC" },
      { value: "wrongNumber", label: "Wrong Number" },
      { value: "litigator", label: "Litigator" },
    ]
    const columnStyles = {
      // Define custom styles for each column to set minimum width
      id: { minWidth: "5px" },
      name: { minWidth: "500px" },
      status: { minWidth: "5px" },
      "market.name": { minWidth: "100px" },
      totalSent: { minWidth: "60px" },
      totalReceived: { minWidth: "60px" },
      totalDelivered: { minWidth: "60px" },
      hotLeadCount: { minWidth: "60px" },
      dripCount: { minWidth: "60px" },
      phoneNumbers: { minWidth: "100px" },
      count: { minWidth: "60px" },
      totalDelivered: { minWidth: "80px" },
      totalReceived: { minWidth: "80px" },
      _id: { minWidth: "100px" },
    }

    const tableStyle = {
      borderCollapse: "collapse", // Remove spacing between columns

      width: "100%", // Set the table width as needed
    }
    const paginationOptions = {
      rowsPerPageText: "Rows per page:",
      rangeSeparatorText: "of",
      selectAllRowsItem: true,
      selectAllRowsItemText: "All",
    }
    const columns = [
      {
        name: "#",
        selector: "id",
        // sortable: true,
        cell: (row, index) => (
          <div className="d-flex flex-row align-items-center c-b-wrap">
            <div className="d-flex flex-column gap-2">
              {row.priorityCount ? (
                <i
                  className={"fas fa-bolt"}
                  // title="Add to Priority"
                />
              ) : null}

              {row.unreadCount ? (
                <i
                  className={"fas fa-circle text-danger"}
                  // title="Add to Priority"
                />
              ) : null}
            </div>
            <span className="ps-2"> {index + 1} &nbsp; </span>
          </div>
        ),
      },
      {
        name: "Campaign Name",
        selector: "name",
        sortable: true,
        cell: row => <a href={`/campaign/${row._id}`}>{row?.name}</a>,
        minWidth: "200px",
      },
      {
        name: "Active Batch",
        selector: "activeBatch",
        sortable: true,
        cell: row => {
          if (!row.auto && !row.schedulerRunning) {
            return "Manual"
          }
          if (row.auto) {
            return "Auto"
          }
          if (row.schedulerRunning) {
            return "Schedule"
          }
        },
        minWidth: "130px",
        // (`(${(row.totalSent * 100 / row.prospects.length).toFixed(2)}) %` +
        //   (row.totalSent * 100) / row.prospects.length >= 100 ? null : `(${row.prospects.length - row.totalSent})  Left`)
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        cell: row => {
          if (!row.totalProspects && !row.totalSent) {
            return "✨New"
          }
          if (!isNaN(row.prospectLeft)) {
            if (+row.prospectLeft === 0) {
              return "✅100%"
            }
            const per = (
              ((row.totalProspects - row.prospectLeft) / row.totalProspects) *
              100
            ).toFixed(2)

            if (+per < 100) {
              return "⌛" + per + "% (" + +row.prospectLeft + "  Left)"
            } else {
              return per + "%"
            }
          } else {
            return "-"
          }
          //   `${((row.totalSent * 100) / row.prospects.length).toFixed(2)}%`
          // }
          // ((row.totalSent * 100) / row.prospects.length).toFixed(2)} % ${
          //   (row.totalSent * 100) / row.prospects.length >= 100
          //     ? ` `
          //     : `(${row.prospects.length - row.totalSent})  Left`
        },
        // (`(${(row.totalSent * 100 / row.prospects.length).toFixed(2)}) %` +
        //   (row.totalSent * 100) / row.prospects.length >= 100 ? null : `(${row.prospects.length - row.totalSent})  Left`)
      },
      {
        name: "Market",
        selector: "market",
        sortable: true,
        cell: row => {
          if (row && row.market) {
            return row.market.name
          } else {
            return "-"
          }
        },
      },
      {
        name: "Sent",
        selector: "totalSent",
        sortable: true,
      },
      {
        name: "Received",
        selector: "totalReceived",
        sortable: true,
        minWidth: "105px",
      },
      {
        name: "Delivered",
        selector: "totalDelivered",
        sortable: true,
        minWidth: "105px",
      },
      {
        name: "Hot",
        selector: "hotLeadCount",
        sortable: true,
        cell: row => `🔥 ${row.hotLeadCount}`,
      },
      {
        name: "Drip",
        selector: "dripCount",
        sortable: true,
        cell: row => `💧 ${row.dripCount}`,
      },
      {
        name: "Phone",
        selector: "phoneNumbers",
        sortable: false,
        cell: row =>
          row.phoneNumbers && row.phoneNumbers.length ? (
            <Button className="has-icon has-drop" color="outline-primary">
              <i className="fas fa-phone-alt"></i>
              <div className="drop-wrapper phone-drop cus-scroll max-h-drop">
                <ul>
                  {row.phoneNumbers.map((num, index) => (
                    <li key={index}>
                      <a>
                        <img src={phoneIcon} alt="" />
                        <span>{num.number}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Button>
          ) : (
            <Button
              className="has-icon"
              color="outline-danger"
              title="No phone number added"
            >
              <i className="fas fa-phone-alt"></i>
            </Button>
          ),
      },
      {
        name: "Priority",
        selector: "priorityCount",
        sortable: true,
        cell: row => (
          <div className="d-flex flex-row align-items-center c-b-wrap">
            {/* {row.count ? <i className="fas fa-bolt"></i> : null} */}
            <i className="fas fa-bolt"></i>
            <span className="ms-1">{row.priorityCount}</span>
          </div>
        ),
      },
      {
        name: "Deliverability",
        selector: "totalDelivered",
        sortable: true,
        cell: row => (
          <div className="st-tag pending">
            <span>
              {+row.totalSent
                ? ((+row.totalDelivered / +row.totalSent) * 100).toFixed(1) +
                  "%"
                : "-"}
            </span>
          </div>
        ),
        minWidth: "125px",
      },
      {
        name: "Response",
        selector: "totalReceived",
        sortable: true,
        cell: row => (
          <div className="st-tag approve">
            <span>
              {+row.totalSent
                ? ((+row.totalReceived / +row.totalSent) * 100).toFixed(1) + "%"
                : "-"}
            </span>
          </div>
        ),
        minWidth: "125px",
      },
      {
        name: "Actions",
        selector: "_id",
        sortable: false,
        cell: row => (
          <div>
            <Button
              onClick={() =>
                this.editCampaign(
                  row.name,
                  row._id,
                  row.delay,
                  row?.market?._id,
                  row?.market?.name
                )
              }
              className="has-icon me-2"
              color="outline-primary"
            >
              <i className="fas fa-pencil-alt"></i>
            </Button>
            <Button
              onClick={() => this.deleteMe(row.name, row._id)}
              className="has-icon"
              color="outline-danger"
            >
              <i className="fas fa-trash-alt"></i>
            </Button>
          </div>
        ),
        minWidth: "125px",
      },
    ]
    const option = this.state.followUpModalData?.leadStages?.map(item => {
      return {
        value: item._id,
        label: item.lead_Stage,
      }
    })
    const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: "#f7f7f8" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {},
      multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: "#efefef",
        }
      },
      multiValueLabel: (styles, state) => {
        return {
          ...styles,
          backgroundColor: "#000",
        }
      },
      multiValueRemove: (styles, { data }) => ({
        ...styles,
      }),
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Campaigns | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            <Toaster />
            {this.state.isLoading && (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            )}

            <Row className="align-items-center breadcrumb-box ">
              <div className="col-md-6 col-12">
                <div className="page-title-box">
                  <h4 className="font-size-18">Campaigns</h4>
                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Campaigns</BreadcrumbItem>
                  </ol>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="float-end">
                  <div className={"button-items d-flex st flex-wrap gap-2"}>
                    <button
                      className="main-btn border-btn"
                      // to="/add-campaign"
                      onClick={() => this.toggleFollowupModal()}
                      type="button"
                    >
                      <img src={plusPurple} alt="" />
                      <span>Create Follow-Up Campaign</span>
                    </button>
                    <button
                      className="main-btn"
                      // to="/add-campaign"
                      onClick={() => this.tog_add()}
                      type="button"
                    >
                      <img src={plus} alt="" />
                      <span>Create New Campaign</span>
                    </button>
                  </div>
                </div>
              </div>
              {/* <Col md={12} className={'pb-2'}>
                <span className={'border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block'}></span>
              </Col> */}
              {/* <Col sm={12}>
                <div className="from-group mt-sm-12 mt-3">
                  <div className="inp-grp">
                    <div className="cus-checkbox">
                      <input type="checkbox" id="c1" />
                      <label htmlFor="c1">
                        <span>Show Follow-Up Only</span>
                      </label>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>

            <Row className="mt-2">
              <Col xl={12} md={12} sm={12}>
                <div className="d-flex gap-3 px-3">
                  <div
                    xl={3}
                    md={3}
                    sm={3}
                    className="d-flex justify-content-center"
                  >
                    <button
                      className={
                        this.state.activeTab === "campaigns"
                          ? "active-tab"
                          : "tabs"
                      }
                      onClick={() => {
                        this.setState({
                          activeTab: "campaigns",
                        })
                      }}
                    >
                      Campaigns
                    </button>
                  </div>
                  <div
                    xl={3}
                    md={3}
                    sm={3}
                    className="d-flex justify-content-center"
                  >
                    <button
                      className={
                        this.state.activeTab === "followUpCampaigns"
                          ? "active-tab"
                          : "tabs"
                      }
                      onClick={() => {
                        if (
                          !(
                            this.props.followUpCampaigns &&
                            this.props.followUpCampaigns.length
                          )
                        ) {
                          this.props.getFollowUpCampaigns()
                        }
                        this.setState({
                          activeTab: "followUpCampaigns",
                        })
                      }}
                    >
                      Follow-Up Campaigns
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="c-card">
              <Row>
                <Col>
                  {/* {this.props.apiError &&
                  typeof this.props.apiError === "string" ? (
                    <Alert color="danger">{this.props.apiError}</Alert>
                  ) : null}

                  {this.props.apiError &&
                  typeof this.props.apiError === "object" ? (
                    this.props.apiError.response ? (
                      <Alert color="danger">
                        {this.props.apiError.response.data.error}
                      </Alert>
                    ) : (
                      <Alert color="danger">
                        {this.props.apiError.message}
                      </Alert>
                    )
                  ) : null}

                  {this.props.successMsg ? (
                    <Alert color="success">{this.props.successMsg}</Alert>
                  ) : null} */}

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      {
                        this.state.activeTab === "campaigns" &&
                        this.props?.campaigns ? (
                          this.props.loading ? (
                            <div colSpan={12} className={"text-center"}>
                              <Spinner className="ms-2" color="primary" />
                            </div>
                          ) : (
                            <DataTable
                              title=""
                              columns={columns}
                              data={this.props.campaigns}
                              pagination
                              paginationComponentOptions={paginationOptions}
                              fixedHeader
                              // columnStyles={columnStyles}
                              // className="campaign-table"
                              className="c-table"
                              style={tableStyle}
                            />
                          )
                        ) : // <Table
                        //   id="tech-companies-1"
                        //   className="table veltrix-table c-table"
                        // >
                        //   <Thead>
                        //     <Tr>
                        //       <Th>#</Th>
                        //       <Th>Campaign Name</Th>
                        //       <Th>Status</Th>
                        //       <Th>Market</Th>

                        //       {/* <Th>Status</Th>
                        //    <Th>Edit</Th>
                        //    <Th>Delete</Th> */}
                        //       <Th data-priority="1">Sent</Th>
                        //       <Th data-priority="2">Received</Th>
                        //       <Th data-priority="2">Delivered</Th>
                        //       <Th data-priority="4">Hot</Th>
                        //       <Th data-priority="3">Drip</Th>
                        //       <Th data-priority="3">Phone</Th>
                        //       <Th data-priority="4">Priority</Th>
                        //       <Th data-priority="5">Deliverability</Th>
                        //       <Th data-priority="6">Response</Th>
                        //       <Th data-priority="7">Actions</Th>
                        //     </Tr>
                        //   </Thead>
                        //   <Tbody>
                        //     {this.props.loading ? (
                        //       <Tr>
                        //         <Td colSpan={12} className={"text-center"}>
                        //           <Spinner className="ms-2" color="primary" />
                        //         </Td>
                        //       </Tr>
                        //     ) : null}
                        //     {/* {this.state.activeTab ==="campaigns"?
                        //  <>
                        //  </>} */}
                        //     {this.props.campaigns?.map((item, index) => (
                        //       <Tr key={item._id}>
                        //         <Td valign="middle">
                        //           {/* {item.count == 0 ? (
                        //              <i
                        //                className={"fas fa-bolt ps-2"}
                        //                // title="Add to Priority"
                        //              />
                        //            ) : (
                        //              <i
                        //                className={
                        //                  "fas fa-bolt text-danger ps-2"
                        //                }
                        //                title="Remove from Priority"
                        //              />
                        //            )} */}

                        //           <div className="d-flex flex-row align-items-center c-b-wrap">
                        //             <div className="d-flex flex-column gap-2">
                        //               {item.count ? (
                        //                 <i
                        //                   className={"fas fa-bolt"}
                        //                 // title="Add to Priority"
                        //                 />
                        //               ) : null}

                        //               {item.convstatus == "unread" ? (
                        //                 <i
                        //                   className={
                        //                     "fas fa-circle text-danger"
                        //                   }
                        //                 // title="Add to Priority"
                        //                 />
                        //               ) : null}
                        //             </div>
                        //             <span className="ps-2">{index + 1} </span>
                        //           </div>
                        //         </Td>
                        //         <Td valign="middle">
                        //           <Link
                        //             to={`/campaign/${item._id}`}
                        //             onClick={() => { }}
                        //           >
                        //             {item.name}
                        //             {/* {item.convstatus == "unread" ? (
                        //                <i
                        //                  style={{
                        //                    color: "#fe0606",
                        //                    fontSize: "18px",
                        //                  }}
                        //                  className={
                        //                    "ion ion-md-mail-unread ps-2"
                        //                  }
                        //                ></i>
                        //              ) : null} */}
                        //           </Link>
                        //         </Td>
                        //         <Td>
                        //           {(
                        //             (item.totalSent * 100) /
                        //             item.prospects.length
                        //           ).toFixed(2) + "%"}

                        //           {(item.totalSent * 100) /
                        //             item.prospects.length >=
                        //             100
                        //             ? null
                        //             : `(${item.prospects.length - item.totalSent
                        //             })  Left`}
                        //         </Td>

                        //         <Td valign="middle">
                        //           {item.market && item.market.name}
                        //         </Td>

                        //         {/* <Td valign="middle">
                        //            {item.market && item.market.name}
                        //          </Td>
                        //          <Td valign="middle">
                        //            {item.status != undefined &&
                        //              item.status.charAt(0).toUpperCase() +
                        //                item.status.slice(1)}
                        //          </Td> */}
                        //         {/* <Td valign="middle">200</Td> */}
                        //         <Td valign="middle">{item.totalSent}</Td>
                        //         <Td valign="middle">{item.totalReceived}</Td>
                        //         <Td valign="middle">{item.totalDelivered}</Td>
                        //         <Td valign="middle">🔥 {item.hotLeadCount}</Td>
                        //         <Td valign="middle">💧 {item.dripCount}</Td>

                        //         <Td valign="middle">
                        //           {item.phoneNumbers &&
                        //             item.phoneNumbers.length ? (
                        //             <Button
                        //               className={"has-icon has-drop"}
                        //               color={"outline-primary"}
                        //             >
                        //               <i className="fas fa-phone-alt"></i>
                        //               <div className="drop-wrapper phone-drop cus-scroll max-h-drop">
                        //                 <ul>
                        //                   {item.phoneNumbers?.map(
                        //                     (num, index) => {
                        //                       return (
                        //                         <li key={index}>
                        //                           <a>
                        //                             <img src={phoneIcon} />
                        //                             <span>{num.number}</span>
                        //                           </a>
                        //                         </li>
                        //                       )
                        //                     }
                        //                   )}
                        //                 </ul>
                        //               </div>
                        //             </Button>
                        //           ) : (
                        //             <Button
                        //               className={"has-icon"}
                        //               color={"outline-danger"}
                        //               title="No phone number added"
                        //             >
                        //               <i className="fas fa-phone-alt"></i>
                        //             </Button>
                        //           )}
                        //           {/* <ul className="list-unstyled d-flex flex-column border-1 rounded-2 border position-absolute p-3">
                        //              <li>
                        //                {" "}
                        //                <i className="fas fa-phone-alt"></i>{" "}
                        //                117863781535
                        //              </li>
                        //              <li>
                        //                {" "}
                        //                <i className="fas fa-phone-alt"></i> 2
                        //              </li>
                        //              <li>
                        //                {" "}
                        //                <i className="fas fa-phone-alt"></i> 3
                        //              </li>
                        //              <li>
                        //                {" "}
                        //                <i className="fas fa-phone-alt"></i> 4
                        //              </li>
                        //              <li>
                        //                {" "}
                        //                <i className="fas fa-phone-alt"></i> 5
                        //              </li>
                        //              <li>
                        //                {" "}
                        //                <i className="fas fa-phone-alt"></i> 6
                        //              </li>
                        //            </ul>{" "} */}
                        //         </Td>
                        //         <Td valign="middle">
                        //           <img src={boltIcon} alt="" /> {item.count}
                        //         </Td>
                        //         <Td valign="middle">
                        //           <div className="st-tag pending">
                        //             <span>
                        //               {+item.totalSent
                        //                 ? (
                        //                   (+item.totalDelivered /
                        //                     +item.totalSent) *
                        //                   100
                        //                 ).toFixed(1) + "%"
                        //                 : "-"}
                        //             </span>
                        //           </div>
                        //         </Td>
                        //         <Td valign="middle">
                        //           <div className="st-tag approve">
                        //             <span>
                        //               {+item.totalSent
                        //                 ? (
                        //                   (+item.totalReceived /
                        //                     +item.totalSent) *
                        //                   100
                        //                 ).toFixed(1) + "%"
                        //                 : "-"}
                        //             </span>
                        //           </div>
                        //         </Td>
                        //         {/* <Td valign="middle">
                        //            <Button
                        //              className={"has-icon me-2"}
                        //              color={"outline-primary"}
                        //            >
                        //              <i className="fas fa-pencil-alt"></i>
                        //            </Button>
                        //            <Button
                        //              className={"has-icon"}
                        //              color={"outline-danger"}
                        //            >
                        //              <i className="fas fa-trash-alt"></i>
                        //            </Button>
                        //          </Td> */}
                        //         <Td valign="middle">
                        //           {/* <Button onClick={()=>this.editCampaign(item.name,item._id,item.delay, item.market._id, item.market.name)} className={'has-icon me-2'} color={'outline-primary'}>
                        //                <i className='fas fa-pencil-alt'></i>
                        //              </Button> */}

                        //           <Button
                        //             onClick={() => {
                        //               this.setState({
                        //                 modal_center: true,
                        //                 campaign_name: item?.name
                        //                   ? item?.name
                        //                   : null,
                        //                 campaign_id: item?._id
                        //                   ? item?._id
                        //                   : null,
                        //                 campaign_delay: item?.delay
                        //                   ? item.delay
                        //                   : null,
                        //                 market_id: item?.market?._id
                        //                   ? item.market._id
                        //                   : null,
                        //                 market_name: item?.market?.name
                        //                   ? item.market.name
                        //                   : null,
                        //               })
                        //               // this.editCampaign(item.name,item._id,item.delay, item.market._id, item.market.name)
                        //             }}
                        //             className={"has-icon me-2"}
                        //             color={"outline-primary"}
                        //           >
                        //             <i className="fas fa-pencil-alt"></i>
                        //           </Button>

                        //           {/* <Td valign="middle"> */}
                        //           <Button
                        //             onClick={() =>
                        //               this.deleteMe(item.name, item._id)
                        //             }
                        //             className={"has-icon"}
                        //             color={"outline-danger"}
                        //           >
                        //             <i className="fas fa-trash-alt"></i>
                        //           </Button>
                        //         </Td>
                        //       </Tr>
                        //     ))}
                        //   </Tbody>
                        // </Table>

                        this.state.activeTab === "followUpCampaigns" &&
                          // this.props.followupCampaigns ? (
                          this.props.loading ? (
                          <div colSpan={12} className={"text-center"}>
                            <Spinner className="ms-2" color="primary" />
                          </div>
                        ) : (
                          <DataTable
                            title=""
                            columns={columns}
                            data={this.props.followUpCampaigns}
                            pagination
                            paginationComponentOptions={paginationOptions}
                            fixedHeader
                            // columnStyles={columnStyles}
                            // className="campaign-table"
                            className="c-table"
                            style={tableStyle}
                          />
                        )
                        // ) : //     <Table
                        //       id="tech-companies-1"
                        //       className="table veltrix-table c-table"
                        //     >
                        //       <Thead>
                        //         <Tr>
                        //           <Th>#</Th>
                        //           <Th>Campaign Name</Th>

                        //           <Th>Market</Th>

                        //           {/* <Th>Status</Th>
                        //    <Th>Edit</Th>
                        //    <Th>Delete</Th> */}
                        //           <Th data-priority="1">Sent</Th>
                        //           <Th data-priority="2">Received</Th>
                        //           <Th data-priority="2">Delivered</Th>
                        //           <Th data-priority="4">Hot</Th>
                        //           <Th data-priority="3">Drip</Th>
                        //           <Th data-priority="3">Phone</Th>
                        //           <Th data-priority="4">Priority</Th>
                        //           <Th data-priority="5">Deliverability</Th>
                        //           <Th data-priority="6">Response</Th>
                        //           <Th data-priority="7">Actions</Th>
                        //         </Tr>
                        //       </Thead>
                        //       <Tbody>
                        //         {this.props.loading ? (
                        //           <Tr>
                        //             <Td colSpan={12} className={"text-center"}>
                        //               <Spinner className="ms-2" color="primary" />
                        //             </Td>
                        //           </Tr>
                        //         ) : null}
                        //         {/* {this.state.activeTab ==="campaigns"?
                        //  <>
                        //  </>} */}
                        //         {this.props.followUpCampaigns?.map(
                        //           (item, index) => (
                        //             <Tr key={item._id}>
                        //               <Td valign="middle">
                        //                 {/* {item.count == 0 ? (
                        //         <i
                        //           className={"fas fa-bolt ps-2"}
                        //           // title="Add to Priority"
                        //         />
                        //       ) : (
                        //         <i
                        //           className={
                        //             "fas fa-bolt text-danger ps-2"
                        //           }
                        //           title="Remove from Priority"
                        //         />
                        //       )} */}

                        //                 <div className="d-flex flex-row align-items-center c-b-wrap">
                        //                   <div className="d-flex flex-column gap-2">
                        //                     {item.count ? (
                        //                       <i
                        //                         className={"fas fa-bolt"}
                        //                       // title="Add to Priority"
                        //                       />
                        //                     ) : null}

                        //                     {item.convstatus == "unread" ? (
                        //                       <i
                        //                         className={
                        //                           "fas fa-circle text-danger"
                        //                         }
                        //                       // title="Add to Priority"
                        //                       />
                        //                     ) : null}
                        //                   </div>
                        //                   <span className="ps-2">{index + 1} </span>
                        //                 </div>
                        //               </Td>
                        //               <Td valign="middle">
                        //                 <Link
                        //                   to={`/campaign/${item._id}`}
                        //                   onClick={() => { }}
                        //                 >
                        //                   {item.name}
                        //                 </Link>
                        //               </Td>
                        //               <Td valign="middle">
                        //                 {item.market && item.market.name}
                        //               </Td>

                        //               <Td valign="middle">{item.totalSent}</Td>
                        //               <Td valign="middle">{item.totalReceived}</Td>
                        //               <Td valign="middle">{item.totalDelivered}</Td>
                        //               <Td valign="middle">
                        //                 🔥 {item.hotLeadCount}
                        //               </Td>
                        //               <Td valign="middle">💧 {item.dripCount}</Td>

                        //               <Td valign="middle">
                        //                 {item.phoneNumbers &&
                        //                   item.phoneNumbers.length ? (
                        //                   <Button
                        //                     className={"has-icon has-drop"}
                        //                     color={"outline-primary"}
                        //                   >
                        //                     <i className="fas fa-phone-alt"></i>
                        //                     <div className="drop-wrapper phone-drop cus-scroll max-h-drop">
                        //                       <ul>
                        //                         {item.phoneNumbers?.map(
                        //                           (num, index) => {
                        //                             return (
                        //                               <li key={index}>
                        //                                 <a>
                        //                                   <img src={phoneIcon} />
                        //                                   <span>{num.number}</span>
                        //                                 </a>
                        //                               </li>
                        //                             )
                        //                           }
                        //                         )}
                        //                       </ul>
                        //                     </div>
                        //                   </Button>
                        //                 ) : (
                        //                   <Button
                        //                     className={"has-icon"}
                        //                     color={"outline-danger"}
                        //                     title="No phone number added"
                        //                   >
                        //                     <i className="fas fa-phone-alt"></i>
                        //                   </Button>
                        //                 )}
                        //               </Td>
                        //               <Td valign="middle">
                        //                 <img src={boltIcon} alt="" /> {item.count}
                        //               </Td>
                        //               <Td valign="middle">
                        //                 <div className="st-tag pending">
                        //                   <span>
                        //                     {+item.totalSent
                        //                       ? (
                        //                         (+item.totalDelivered /
                        //                           +item.totalSent) *
                        //                         100
                        //                       ).toFixed(1) + "%"
                        //                       : "-"}
                        //                   </span>
                        //                 </div>
                        //               </Td>
                        //               <Td valign="middle">
                        //                 <div className="st-tag approve">
                        //                   <span>
                        //                     {+item.totalSent
                        //                       ? (
                        //                         (+item.totalReceived /
                        //                           +item.totalSent) *
                        //                         100
                        //                       ).toFixed(1) + "%"
                        //                       : "-"}
                        //                   </span>
                        //                 </div>
                        //               </Td>

                        //               <Td valign="middle">
                        //                 <Button
                        //                   onClick={() => {
                        //                     this.setState({
                        //                       modal_center: true,
                        //                       campaign_name: item?.name
                        //                         ? item?.name
                        //                         : null,
                        //                       campaign_id: item?._id
                        //                         ? item?._id
                        //                         : null,
                        //                       campaign_delay: item?.delay
                        //                         ? item.delay
                        //                         : null,
                        //                       market_id: item?.market?._id
                        //                         ? item.market._id
                        //                         : null,
                        //                       market_name: item?.market?.name
                        //                         ? item.market.name
                        //                         : null,
                        //                     })
                        //                   }}
                        //                   className={"has-icon me-2"}
                        //                   color={"outline-primary"}
                        //                 >
                        //                   <i className="fas fa-pencil-alt"></i>
                        //                 </Button>

                        //                 <Button
                        //                   onClick={() =>
                        //                     this.deleteMe(item.name, item._id)
                        //                   }
                        //                   className={"has-icon"}
                        //                   color={"outline-danger"}
                        //                 >
                        //                   <i className="fas fa-trash-alt"></i>
                        //                 </Button>
                        //               </Td>
                        //             </Tr>
                        //           )
                        //         )}
                        //       </Tbody>
                        //     </Table>
                      }

                      {this.state.confirm_alert ? (
                        <SweetAlert
                          title="Are you sure?"
                          warning
                          showCancel
                          confirmBtnText="Yes, remove it"
                          confirmBtnBsStyle="primary"
                          cancelBtnBsStyle="dark"
                          onConfirm={() => {
                            this.setState({ confirm_alert: false })
                            this.props.deleteCampaign(this.state.campaign_id)
                          }}
                          onCancel={() => {
                            this.setState({ confirm_alert: false })
                          }}
                        >
                          {/* You won't be able to revert this! */}
                          All Prospects, Messages and Conversations associated
                          with <strong>
                            {" "}
                            {this.state.campaign_name}{" "}
                          </strong>{" "}
                          will be deleted!
                        </SweetAlert>
                      ) : null}
                      <Modal
                        isOpen={this.state.modal_center}
                        toggle={() => {
                          this.tog_center()
                        }}
                        backdrop="static"
                        centered={true}
                      >
                        <AvForm
                          className="needs-validation"
                          onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                        >
                          <div className="modal-header">
                            <h5 className="modal-title mt-0">
                              Edit Campaign Name
                            </h5>
                            <button
                              type="button"
                              onClick={() => {
                                this.setState({
                                  modal_center: false,
                                })
                              }}
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <Row>
                              <Col>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="validationCustom01">
                                    Campaign Name
                                  </Label>
                                  <AvField
                                    name="campaign_name"
                                    placeholder="Campaign Name"
                                    type="text"
                                    errorMessage="Enter Campaign Name"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                      pattern: {
                                        value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                                        errorMessage:
                                          "Enter valid campaign name",
                                      },
                                    }}
                                    id="validationCustom01"
                                    value={this.state.campaign_name}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup className="mb-3">
                                  <Label htmlFor="validationCustom02">
                                    Delay Interval Per Text Message(s)
                                  </Label>
                                  <AvField
                                    name="campaign_delay"
                                    placeholder="Delay Interval Per Text Message(s)"
                                    type="number"
                                    errorMessage="Enter Delay"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                      number: { value: true },
                                    }}
                                    id="validationCustom02"
                                    value={this.state.campaign_delay}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup className="mb-3">
                                  <AvField
                                    type="select"
                                    name="market"
                                    validate={{
                                      required: {
                                        value: true,
                                        errorMessage: "Please select market",
                                      },
                                      //  pattern:{value: '^[A-Za-z0-9 ]+$',errorMessage:"Enter valid name"}
                                    }}
                                    value={this.state.market_id}
                                  >
                                    <option value="">Select Market</option>
                                    {this.props.markets.length > 0 &&
                                      this.props.markets?.map(item => (
                                        <option key={item._id} value={item._id}>
                                          {item.name}
                                        </option>
                                      ))}
                                  </AvField>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row className={"text-end"}>
                              <Col>
                                <button
                                  type="button"
                                  className="btn btn-dark me-2"
                                  onClick={() => {
                                    this.setState({
                                      modal_center: false,
                                    })
                                  }}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Update
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </AvForm>
                      </Modal>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal_add}
          toggle={() => {
            this.tog_add()
          }}
          className="c-modal"
          // backdrop="static"
          centered={true}
        >
          <div className="modal-body ">
            <h5 className="modal-title mt-0 mb-3">Create Campaign</h5>
            <button
              type="button"
              onClick={() => {
                this.setState({
                  modal_add: false,
                })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>

            {
              <AvForm
                onValidSubmit={(e, v) => this.handleCampaignCreate(e, v)}
                className="needs-validation"
              >
                <Row>
                  <Col xl="12">
                    <FormGroup className="mb-3">
                      <AvField
                        name="campaign_name"
                        placeholder="Campaign Name"
                        type="text"
                        errorMessage="Enter Campaign Name"
                        className="form-control cc-form-control"
                        validate={{
                          required: { value: true },
                          pattern: {
                            value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                            errorMessage: "Enter valid campaign name",
                          },
                        }}
                        id="validationCustom01"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xl="12">
                    <FormGroup className="mb-3">
                      <AvField
                        type="select"
                        name="market"
                        validate={{
                          required: { value: true },
                        }}
                        value=""
                      >
                        <option value="">Select Market</option>
                        {this.props.markets.length > 0 &&
                          this.props.markets?.map(item => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col xl="12">
                    <FormGroup className="mb-3">
                      <AvField
                        name="campaign_delay"
                        placeholder="Delay Interval Per Text Message(s)"
                        type="number"
                        errorMessage="Enter Delay"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          number: { value: true },
                          min: {
                            value: 1,
                            errorMessage:
                              "Delay cannot be less than or equal to 0 seconds",
                          },
                        }}
                        id="validationCustom02"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                  <Link
                    to="/campaigns"
                    className="main-btn secondary-btn"
                    onClick={() => {
                      this.setState({ modal_add: false })
                    }}
                    role="button"
                  >
                    <span>Cancel</span>
                  </Link>
                  <Button type="submit" color="primary" className="main-btn">
                    <span>Submit</span>
                  </Button>{" "}
                </div>
              </AvForm>
            }
          </div>
        </Modal>

        {/* Follow-Up Campaign Modal Start */}
        <Modal
          isOpen={this.state.followupCampaignModal.isOpen}
          toggle={() => {
            this.toggleFollowupModal()
          }}
          className="c-modal modal-lg"
          // backdrop="static"
          centered={true}
        >
          <div className="modal-body">
            <h5 className="modal-title mt-0 mb-5 ">
              Create Follow-Up Campaign
            </h5>
            <button
              type="button"
              onClick={() => {
                this.toggleFollowupModal()
                this.setState({ showProspectCount: false })
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>
            {!this.state.showProspectCount ? (
              <AvForm
                onValidSubmit={(e, v) =>
                  this.handleFollowUpCampaignCreate(e, v)
                }
                className="needs-validation"
              >
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <Row noGutters={true}>
                      <Col xl={6} md={6} sm={12}>
                        <FormGroup>
                          <AvField
                            name="followUpCampaignName"
                            placeholder="Follow-Up Campaign Name"
                            type="text"
                            errorMessage="Enter Follow-Up Campaign Name"
                            className="form-control cc-form-control"
                            validate={{
                              required: { value: true },
                              pattern: {
                                value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                                errorMessage:
                                  "Enter valid follow-up campaign name",
                              },
                            }}
                            id="validationCustom01"
                            defaultValue={
                              this.state.followupCampaignModal?.name
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xl={6} md={6} sm={12}>
                        <div
                          className="select-camp-btn main-btn border-btn"
                          onClick={() => {
                            this.toggleSelectCampaignModal()
                          }}
                        >
                          <img src={searchIcon} alt="" />
                          Select Campaign
                        </div>
                        <div className="selected-camp-wr">
                          {this.state.followupCampaignModal?.selectedCampaigns?.map(
                            (item, index) => {
                              return (
                                <span
                                  key={index}
                                  className="selected-camp-chip"
                                >
                                  {item.campaign?.name}
                                  <img
                                    src={removeIcon}
                                    alt=""
                                    onClick={() => {
                                      this.removeSelectedCampaign(index)
                                    }}
                                  />
                                </span>
                              )
                            }
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xl={12} md={12} sm={12}>
                    <Row noGutters={true}>
                      <Col xl={8} md={8} sm={8}>
                        <FormGroup>
                          <div className="from-group">
                            <div className="inp-grp">
                              <div className="d-flex align-items-center gap-2">
                                <Switch
                                  onChange={checked => {
                                    this.setState(prevState => ({
                                      followupCampaignModal: {
                                        ...prevState.followupCampaignModal,
                                        isRetain: checked,
                                      },
                                    }))
                                  }}
                                  checked={
                                    !!this.state.followupCampaignModal?.isRetain
                                  }
                                  uncheckedIcon={false} // Hide the unchecked icon
                                  checkedIcon={false} // Hide the checked icon
                                  height={20}
                                  width={35}
                                  onColor="#5932ea"
                                  offColor="#d3d3d3"
                                />
                                <Label
                                  className="m-0 cursor-pointer"
                                  onClick={() => {
                                    this.setState(prevState => ({
                                      followupCampaignModal: {
                                        ...prevState.followupCampaignModal,
                                        isRetain:
                                          !prevState.followupCampaignModal
                                            .isRetain,
                                      },
                                    }))
                                  }}
                                >
                                  Retain Initial Outgoing Numbers?
                                </Label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} md={6} sm={12}>
                    <FormGroup className="mb-3">
                      <Label>Delay Interval Per Text Message(s)</Label>
                      <AvField
                        name="campaign_delay"
                        placeholder="Delay Interval Per Text Message(s)"
                        type="number"
                        errorMessage="Enter Delay"
                        className="form-control"
                        validate={{
                          required: { value: true },
                          number: { value: true },
                          min: {
                            value: 1,
                            errorMessage:
                              "Delay cannot be less than or equal to 0 seconds",
                          },
                        }}
                        id="validationCustom02"
                        value={this.state.followupCampaignModal?.delay}
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={6} md={6} sm={12}>
                    <FormGroup className="mb-3">
                      <Label>Market Associated With Selected Campaign</Label>
                      {this.state.followupCampaignModal && (
                        <AvField
                          type="select"
                          name="market"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            this.state.followupCampaignModal?.selectedMarket
                              ?._id || ""
                          }
                          disabled
                        >
                          <option value="">
                            Select Campaign To See Market
                          </option>
                          {this.props.markets.length > 0 &&
                            this.props.markets?.map(item => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                        </AvField>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} md={6} sm={12}>
                    <FormGroup className="mb-3">
                      <Label>Include Prospects Who Have Responded?</Label>
                      <AvField
                        type="select"
                        name="includeProspect"
                        required
                        errorMessage="This field is required "
                        // validate={{
                        //   required: { value: true },
                        // }}
                        value={
                          this.state.followupCampaignModal?.prospectResponded
                        }
                      >
                        <option value="">Select Option </option>
                        <option value="true">
                          Yes - only include the prospect that have replied{" "}
                        </option>
                        <option value="false">
                          No - only include the prospect that haven't replied
                        </option>
                        <option value="null">
                          All - include both prospect that have and haven't
                          replied{" "}
                        </option>
                      </AvField>
                    </FormGroup>
                  </Col>
                  <Col xl={6} md={6} sm={12}>
                    <FormGroup className="mb-3">
                      <Label>Skip Reason</Label>
                      <AvField
                        type="select"
                        name="skipReason"
                        required
                        errorMessage="Skip Reason is required "
                        // validate={{
                        //   required: { value: true },
                        // }}
                        value={this.state.followupCampaignModal?.skipReason}
                      >
                        <option value="">Select Skip Reason </option>
                        {this.state.followUpModalData.skipRules?.map(item => (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                      </AvField>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} md={6} sm={12}>
                    <FormGroup className="mb-3 form-group select-r has-fix-heigth">
                      <Label>Lead Stage</Label>
                      {
                        <Select
                          required
                          onChange={e => {
                            // const selectedvalue = e?.map(item => item.value)
                            this.setState(prevState => ({
                              followupCampaignModal: {
                                ...prevState.followupCampaignModal,
                                leadStage: e?.map(item => item?.value),
                                seletedLeadStage: e,
                              },
                            }))
                          }}
                          value={
                            this.state.followupCampaignModal?.seletedLeadStage
                          }
                          options={option}
                          // styles={colourStyles}
                          isMulti
                          classNamePrefix="select2-selection"
                          placeholder="Select lead stage(s), or leave blank for all."
                        />
                      }
                    </FormGroup>
                  </Col>
                  <Col xl={6} md={6} sm={12}>
                    <FormGroup className="mb-3 form-group select-r has-fix-heigth">
                      <Label>Prospect Who Are?</Label>
                      <Select
                        // className="selector border-0"
                        required
                        onChange={e => {
                          this.setState(prevState => ({
                            followupCampaignModal: {
                              ...prevState.followupCampaignModal,
                              prospect: e?.map(item => item?.value),
                              selectedProspect: e,
                            },
                          }))
                        }}
                        value={
                          this.state.followupCampaignModal?.selectedProspect
                        }
                        options={prospectOptions}
                        // styles={colourStyles}
                        isMulti
                        isReq={true}
                        classNamePrefix="select2-selection"
                        placeholder="Select prospect status(es), or leave blank for all."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} md={6} sm={12}>
                    <FormGroup className="mb-3 form-group select-r has-fix-heigth">
                      <Label>Exclude Prospect Who Are</Label>
                      {
                        <Select
                          required
                          onChange={e => {
                            this.setState(prevState => ({
                              followupCampaignModal: {
                                ...prevState.followupCampaignModal,
                                excludeProspect: e?.map(item => item?.value),
                                selectedExcludedProspect: e,
                              },
                            }))
                          }}
                          value={
                            this.state.followupCampaignModal
                              ?.selectedExcludedProspect
                          }
                          options={exludeProspect}
                          // styles={colourStyles}
                          isMulti
                          classNamePrefix="select2-selection"
                          // placeholder="Select lead stage(s), or leave blank for all."
                        />
                      }
                    </FormGroup>
                  </Col>
                </Row>

                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2 mt-3">
                  <Link
                    to="/campaigns"
                    className="main-btn secondary-btn"
                    onClick={() => {
                      this.toggleFollowupModal()
                    }}
                    role="button"
                  >
                    <span>Cancel</span>
                  </Link>
                  <Button
                    type="submit"
                    color="primary"
                    className="main-btn"
                    // disabled={
                    //   !(
                    //     this.state.followupCampaignModal.leadStage &&
                    //     this.state.followupCampaignModal.prospect &&
                    //     this.state.followupCampaignModal.selectedCampaigns
                    //   )
                    // }
                  >
                    <span>Check Prospect</span>
                  </Button>{" "}
                </div>
              </AvForm>
            ) : (
              <AvForm
                onValidSubmit={(e, v) =>
                  this.handleFollowUpCampaignCreate(e, v)
                }
                className="needs-validation"
              >
                <div>
                  <AvField
                    name="Total Prospects"
                    placeholder="Follow-Up Campaign Name"
                    type="text"
                    errorMessage="Enter Follow-Up Campaign Name"
                    className="form-control cc-form-control"
                    id="validationCustom01"
                    disabled
                    defaultValue={
                      "Number of Prospect Available       |     " +
                      this.state.followupCampaignModal?.totalProspect?.length
                    }
                  />
                </div>

                <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                  <Button
                    className="main-btn secondary-btn"
                    onClick={() => {
                      this.setState({ showProspectCount: false })
                    }}
                    role="button"
                  >
                    <span>Back</span>
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    className="main-btn"
                    // onClick={() => {
                    //   this.setState({
                    //     showProspectCount: false,
                    //   })
                    // }}
                  >
                    <span>Create</span>
                  </Button>{" "}
                </div>
              </AvForm>
            )}
          </div>
        </Modal>
        {/* select campaign modal start */}
        <Modal
          isOpen={this.state.selectCampaignModal.isOpen}
          toggle={() => {
            this.toggleSelectCampaignModal()
          }}
          className="c-modal"
          centered={true}
        >
          <div className="modal-body ">
            <h5 className="modal-title mt-0 mb-3">Select Campaign</h5>
            <button
              type="button"
              onClick={() => {
                this.toggleSelectCampaignModal()
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src={close} alt="" />
            </button>

            <div className="select-modal-wr">
              <Table responsive="sm" className="table veltrix-table">
                <Thead style={{ textAlign: "center" }}>
                  <Tr>
                    <Th>Action</Th>
                    <Th>Name</Th>
                    <Th>Prospect available</Th>
                  </Tr>
                </Thead>

                <Tbody style={{ textAlign: "center" }}>
                  {this.state.followUpModalData.campaigns?.map(
                    (item, index) => {
                      return (
                        <Tr
                          key={index}
                          onClick={() => {
                            if (
                              this.state.selectCampaignModal?.selectedCampaigns?.includes(
                                item.campaign._id
                              )
                            ) {
                              // const tempArr = []
                              // this.state.selectCampaignModal?.selectedCampaigns.forEach(
                              //   camp => {
                              //     if (camp != item._id) {
                              //       tempArr.push(camp)
                              //     }
                              //   }
                              // )
                              this.setState(prevState => ({
                                selectCampaignModal: {
                                  ...prevState.selectCampaignModal,
                                  selectedCampaigns: [],
                                },
                              }))
                            } else {
                              const tempArr = [item.campaign._id]
                              // const tempArr =
                              //   this.state.selectCampaignModal
                              //     ?.selectedCampaigns || []
                              // tempArr.push(item._id)
                              this.setState(prevState => ({
                                selectCampaignModal: {
                                  ...prevState.selectCampaignModal,
                                  selectedCampaigns: tempArr,
                                },
                              }))
                            }
                          }}
                        >
                          <Td valign="middle">
                            <div className="checkbox-wrapper-mail">
                              <Input
                                // className="form-check checkbox-xl"
                                key={index}
                                checked={this.state.selectCampaignModal?.selectedCampaigns?.includes(
                                  item.campaign._id
                                )}
                                type="checkbox"
                                id={`chk${index}`} // Use a unique id for each checkbox
                              />
                              <Label htmlFor="chk12" className="toggle" />
                            </div>
                          </Td>
                          <Td>
                            <div className="file-name justify-content-start text-left">
                              <p>{item.campaign?.name}</p>
                            </div>
                          </Td>
                          <Td valign="middle">
                            <div className="file-name">
                              <p className="overflow-200">
                                {item.prospectAvailable}
                              </p>
                            </div>
                          </Td>
                        </Tr>
                      )
                    }
                  )}
                </Tbody>
              </Table>
            </div>
            <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2 border-top-2">
              <Link
                to="/campaigns"
                className=" main-btn border-btn"
                // style={{backgroundColor:"#efefef",border:'1px solid #5932ea', color:'black !important'}}
                onClick={() => {
                  this.setState({ selectCampaignModal: { isOpen: false } })
                }}
                role="button"
              >
                <span>Cancel</span>
              </Link>
              <Button
                type="submit"
                color="primary"
                className="main-btn"
                onClick={() => {
                  const tempArr = []
                  this.state.selectCampaignModal?.selectedCampaigns?.forEach(
                    campId => {
                      let filteredCamp =
                        this.state.followUpModalData.campaigns?.filter(
                          item => item.campaign._id == campId
                        )
                      if (filteredCamp && filteredCamp.length)
                        tempArr.push(filteredCamp[0])
                    }
                  )
                  const selectedMarket = this.props.markets?.filter(
                    item => item._id == tempArr[0].campaign.market
                  )
                  this.setState(prevState => ({
                    selectCampaignModal: { isOpen: false },
                    followupCampaignModal: {
                      ...prevState.followupCampaignModal,
                      selectedCampaigns: tempArr,
                      selectedMarket: selectedMarket[0],
                    },
                  }))
                }}
              >
                <span>Submit</span>
              </Button>{" "}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

Campaigns.propTypes = {
  loading: PropTypes.bool,
  apiError: PropTypes.any,
  campaigns: PropTypes.array,
  followUpCampaigns: PropTypes.array,
  getCampaigns: PropTypes.func,
  getFollowUpCampaigns: PropTypes.func,
  successMsg: PropTypes.any,
  editCampaign: PropTypes.func,
  addCampaign: PropTypes.func,
  deleteCampaign: PropTypes.func,

  markets: PropTypes.array,
  getMarkets: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    loading,
    apiError,
    successMsg,
    campaigns,
    followUpCampaigns,
    editCampaign,
    addCampaign,
    deleteCampaign,
    getCampaigns,
    getFollowUpCampaigns,
    getMarkets,
    markets,
  } = state.Campaign
  return {
    loading,
    apiError,
    successMsg,
    campaigns,
    followUpCampaigns,
    getCampaigns,
    getFollowUpCampaigns,
    deleteCampaign,
    editCampaign,
    addCampaign,
    getMarkets,
    markets,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    getCampaigns,
    getFollowUpCampaigns,
    editCampaign,
    addCampaign,
    deleteCampaign,
    getMarkets,
  })(Campaigns)
)
