import React, { useState, useRef } from "react"
import MetaTags from "react-meta-tags"
import Axios from "axios"
import { useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  BreadcrumbItem,
  Button,
  Spinner,
  Container,
  Tooltip,
  Progress,
} from "reactstrap"

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import toast, { Toaster } from "react-hot-toast"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"
import { act } from "@testing-library/react"
import moment from "moment"
import CSVReader from "react-csv-reader"
import illus from "../../assets/images/chat-illustration.svg"
import Joi from "joi"
import io from "socket.io-client"

const ContactCart = () => {
  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occured")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }
  // states

  const contactFormRowData = [
    {
      key: "FirstName",
      title: "First Name",
      type: "text",
      validation: Joi.string().required().trim().max(50).label("First Name"),
    },
    {
      key: "LastName",
      title: "Last Name",
      type: "text",
      validation: Joi.string().required().trim().max(50).label("Last Name"),
    },
    {
      key: "MailingAddress",
      title: "Mailing Address",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .label("Mailing Address"),
    },
    {
      key: "MailingCity",
      title: "Mailing City",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .label("Mailing City"),
    },
    {
      key: "MailingState",
      title: "Mailing State",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .label("Mailing State"),
    },
    {
      key: "MailingZip",
      title: "Mailing Zip",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .min(5)
        .label("Mailing Zip")
        .pattern(/^[0-9]+$/)

        .messages({
          "string.empty": "Mailing Zip cannot be empty",
          "any.required": "Mailing Zip is required",
          "string.min": "Mailing Zip should have a minimum length of {#limit}",
          "string.max": "Mailing Zip should have a maximum length of {#limit}",
          "string.pattern.base": "Mailing Zip must contain only numbers",
        }),
    },
    {
      key: "PropertyAddress",
      title: "Property Address",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .label("Property Address"),
    },
    {
      key: "PropertyCity",
      title: "Property City",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .label("Property City"),
    },
    {
      key: "PropertyState",
      title: "Property State",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .label("Property State"),
    },
    {
      key: "PropertyZip",
      title: "Property Zip",
      type: "text",
      validation: Joi.string()
        // .required()
        .trim()
        .allow(null, '')
        .min(5)
        .label("Property Zip")
        .pattern(/^[0-9]+$/)
        // .max(6)
        .messages({
          "string.empty": "Property Zip cannot be empty",
          "any.required": "Property Zip is required",
          "string.min": "Property Zip should have a minimum length of {#limit}",
          "string.max": "Property Zip should have a maximum length of {#limit}",
          "string.pattern.base": "Property Zip must contain only numbers",
        }),
    },
    {
      key: "PhoneNumbers",
      title: "Phone Number",
      type: "text",
      individual: true,
      validation: Joi.array()
        .required()
        .items(
          Joi.string()
            .pattern(/^[0-9]+$/)
            .min(10)
            .max(10)
            .trim()
            // .messages({
            //   "string.empty": "Phone Number cannot be empty",
            //   "any.required": "Phone Number is required",
            //   "string.min": "Phone Number should have a minimum length of {#limit}",
            //   "string.max": "Phone Number should have a maximum length of {#limit}",
            //   "string.pattern.base": "Phone Number must contain only numbers",
            // }),
        )
        .min(1)
        .label("Phone Number"),
    },
  ]

  const createInitialFormState = data => {
    let obj = {}
    contactFormRowData.map(item => {
      if (item.key === "PhoneNumbers") {
        obj[item.key] = data?.[item.key] || [" "]
      } else {
        obj[item.key] = data?.[item.key] || ""
      }
    })
    return obj
  }

  const downloadTemplate = [
    "FirstName",
    "LastName",
    "MailingAddress",
    "MailingCity",
    "MailingState",
    "MailingZip",
    "PropertyAddress",
    "PropertyCity",
    "PropertyState",
    "PropertyZip",
    "Phone1",
    "Phone2",
    "Phone3",
    "Phone4",
    "Phone5",
    "Phone6",
    "Phone7",
    "Phone8",
    "Phone9",
    "Phone10",
  ]

  const bulkContactFileTemplateInitial = [
    {
      key: "FirstName",
      defaultValue: "FirstName",
      label: "First Name",
      required: true,
    },
    {
      key: "LastName",
      defaultValue: "LastName",
      label: "Last Name",
      required: true,
    },
    {
      key: "MailingAddress",
      defaultValue: "MailingAddress",
      label: "Mailing Address",
    },
    {
      key: "MailingCity",
      defaultValue: "MailingCity",
      label: "Mailing City",
    },
    {
      key: "MailingState",
      defaultValue: "MailingState",
      label: "Mailing State",
    },
    {
      key: "MailingZip",
      defaultValue: "MailingZip",
      label: "Mailing Zip",
    },
    {
      key: "PropertyAddress",
      defaultValue: "PropertyAddress",
      label: "Property Address",
    },
    {
      key: "PropertyCity",
      defaultValue: "PropertyCity",
      label: "Property City",
    },
    {
      key: "PropertyState",
      defaultValue: "PropertyState",
      label: "Property State",
    },
    {
      key: "PropertyZip",
      defaultValue: "PropertyZip",
      label: "Property Zip",
    },

    {
      key: "Phone1",
      defaultValue: "Phone1",
      label: "Phone 1",
      required: true,
      individual: true,
      next: 2,
    },
    {
      key: "Phone2",
      defaultValue: "Phone2",
      label: "Phone 2",
      individual: true,
      next: 3,
      
    },
    {
      key: "Phone3",
      defaultValue: "Phone3",
      label: "Phone 3",
      individual: true,
      next: 4,
    },
    {
      key: "Phone4",
      defaultValue: "Phone4",
      label: "Phone 4",
      individual: true,
      next: 5,
    },
    {
      key: "Phone5",
      defaultValue: "Phone5",
      label: "Phone 5",
      individual: true,
      next: 6,
    },
    {
      key: "Phone6",
      defaultValue: "Phone6",
      label: "Phone 6",
      individual: true,
      next: 7,
    },
    {
      key: "Phone7",
      defaultValue: "Phone7",
      label: "Phone 7",
      individual: true,
      next: 8,
    },
    {
      key: "Phone8",
      defaultValue: "Phone8",
      label: "Phone 8",
      individual: true,
      next: 9,
    },
    {
      key: "Phone9",
      defaultValue: "Phone9",
      label: "Phone 9",
      individual: true,
      next: 10,
    },
    {
      key: "Phone10",
      defaultValue: "Phone10",
      label: "Phone 10",
      individual: true,
      next: 11,
    },
  ]

  const [isLoading, setIsLoading] = useState(false)
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())
  const [contactList, setContactList] = useState([])
  const [bulkList, setBulkList] = useState([])
  const [addContacts, setAddContacts] = useState({ isOpen: false })
  const [contactDelete, setContactDelete] = useState({ isOpen: false })
  const [bulkFileDelete, setBulkFileDelete] = useState({ isOpen: false })
  const [addSingleContact, setAddSingleContact] = useState({ isOpen: false })
  const [fileHeaders, setFileHeaders] = useState([])
  const [csvData, setCsvData] = useState({})
  const [formState, setFormState] = useState({})
  const [errors, setErrors] = useState({})
  const [isValidate, setIsValidate] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)
  const [singleFormState, setSingleFormState] = useState(
    createInitialFormState()
  )
  const [formReloadKey, setFormReloadKey] = useState(Math.random())
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedBulkContact, setSelectedBulkContact] = useState([])
  const [activeTab, setActiveTab] = useState("contact")
  const [formSubmitted, setFormSubmitted] = useState({
    isSubmitted: false,
    progressPer: 0,
  })
  const [finalData, setFinalData] = useState(null)
  const [confirmUploadSweetAlert, setConfirmUploadSweetAlert] = useState(false)
  const [count, setCount] = useState({
    inValidCount: 0,
    totalCount: 0,
    validCount: 0,
  })
  const [bulkContactFileTemplate, setBulkContactFileTemplate] = useState(
    bulkContactFileTemplateInitial
  )

 
  // socket

  useEffect(() => {
    let authUser = localStorage.getItem("authUser")
    let token = false
    let user
    if (authUser) {
      user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }

    const socket = io(process?.env.REACT_APP_PORT, {
      // process?.env.REACT_APP_PORT
      // "http://192.168.29.95:5001"
      query: {
        email: user?.user?.name,
      },
    })
    socket.on("verfiedContactFileProgressRes", (email, i, total) => {
      if (!(formSubmitted?.currentCount && i < formSubmitted?.currentCount)) {
        const tempObj = {
          isSubmitted: true,
          progressPer: (i / total) * 100,
          currentCount: i,
        }
        setFormSubmitted(tempObj)
      }
    })
  }, [])

  useEffect(() => {
    getContactList()
    getBulkList()
  }, [])

  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const getContactList = async () => {
    setIsLoading(true)
    try {
      const response = await Axios.get(
        process.env.REACT_APP_PORT + "/api/verifiedcontact/VerifiedContacts",
        config
      )
      if (response?.data) {
        setContactList(response.data.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const getBulkList = async () => {
    setIsLoading(true)
    try {
      const response = await Axios.get(
        process.env.REACT_APP_PORT +
          "/api/verifiedcontact/VerifiedContactsfile",
        config
      )
      if (response?.data) {
        setBulkList(response?.data?.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  // bulk upload tab

  const handleFileUpload = (data, fileInfo) => {
    let csvHeaders = data[0]
    let headerIndexData = {}
    csvHeaders.forEach((item, index) => {
      headerIndexData[item] = index
    })
    const csvDataWithoutHeaders = data.slice(1)

    if (
      csvDataWithoutHeaders?.every(value => {
        return (
          value === "" ||
          (Array.isArray(value) && value.length === 0) ||
          value.every(value => {
            return value === ""
          })
        )
      })
    ) {
      csvHeaders = [""]
    }
    setFileHeaders(csvHeaders)
   
    let header  = []
      for (let i = 0; i < csvHeaders.length; i++) {
        if (csvHeaders[i].toLowerCase().startsWith('phone')) {
         header = [...header , csvHeaders[i]]
        }
      }
    const newArray = bulkContactFileTemplate.filter(obj => header.includes(obj.key));
    if(newArray?.length)
    {
      let newFileTemplate  = []
      for (let i = 0; i < 10 ; i++) {
        newFileTemplate  = [...newFileTemplate , bulkContactFileTemplate[i]] 
      }

     newFileTemplate = [...newFileTemplate , ...newArray]
    
     setBulkContactFileTemplate(newFileTemplate)
      
    }
    else{
      let newFileTemplate  = []
      for (let i = 0; i < 11 ; i++) {
        newFileTemplate  = [...newFileTemplate , bulkContactFileTemplate[i]] 
      }
     
     setBulkContactFileTemplate(newFileTemplate)
    }
    // setBulkContactFileTemplate(newArray)
    setCsvData({
      headerIndexData: headerIndexData,
      data: csvDataWithoutHeaders,
      fileName: fileInfo?.name,
    })

    let formObj = {}
    bulkContactFileTemplate.forEach(item => {
      formObj[item.key] = getValidValue(item.defaultValue, csvHeaders)
    })

    setFormState({ ...formState, ...formObj })
  }

  const getValidValue = (val, arr = fileHeaders) => {
    const cleanedQuery = val?.replace(/[_-\s]/g, "")?.toLowerCase()
    for (let i = 0; i < arr.length; i++) {
      const cleanedItem = arr[i]?.replace(/[_-\s]/g, "")?.toLowerCase()
      if (cleanedQuery === cleanedItem) {
        return arr[i]
      }
    }
    return undefined
  }

  const convertToCSV = data => {
    const csv = data
    return `${csv}\n`
  }

  const downloadCSV = (data, filename) => {
    const csvData = convertToCSV(data)
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = filename
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  const handleDownloadCSV = () => {
    downloadCSV(downloadTemplate, "ContactTemplate.csv")
  }

  const deleteBulkContact = async id => {
    try {
      const response = await Axios.delete(
        process.env.REACT_APP_PORT +
          "/api/verifiedcontact/VerifiedContactsfile/" +
          id,
        config
      )
      if (response?.data) {
        getBulkList()
        notify("success", "Bulk Contact Deleted Successfully")
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
    }
  }

  const deleteSelectedBulkContact = async () => {
    setIsLoading(true)
    const data = {
      ids: selectedBulkContact,
    }

    try {
      const response = await Axios.post(
        process.env.REACT_APP_PORT +
          "/api/verifiedcontact/DeleteVerifiedContactsfile",
        data,
        config
      )
      if (response?.status == 200) {
        notify("success", "Contact's deleted successfully")
        getBulkList()

        setSelectedBulkContact([])
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }

  const prospectSubmitHandler = async (event, values) => {
    const data = []
    const validatedData = []
    const errorRows = []
    const compulsoryKeys = ["FirstName", "LastName", "Phone1"]

    csvData?.data?.forEach((item, index) => {
      const prospect = {}
      const keys = Object.keys(values)
      keys.forEach(key => {
        if (item[csvData.headerIndexData[values[key]]])
          prospect[key] = item[csvData.headerIndexData[values[key]]]
      })

      let isValid = true
      for (let i = 0; i < compulsoryKeys.length; i++) {
        const ck = compulsoryKeys[i]
        if (!prospect[ck]) {
          isValid = false
          break
        }
      }
      if (isValid) {
        data.push(prospect)
      } else {
        errorRows.push(index + 2)
      }
    })
    
   
    data.forEach((item, index) => {
      let newNumbers = {}
      for (let index = 1; index < Object.keys(item).length ; index++) {
        if (item["Phone" + index] && item["Phone" + index]?.length) {
          const validPhone = validatePhoneNumber(item["Phone" + index])
          if (validPhone) {
            newNumbers = {...newNumbers ,["Phone" + index]: validPhone }          
          } 
        } 
      }
      if(!Object.keys(newNumbers).length)
      {
        errorRows.push(index + 2)
      }
      else{
        const newObj = {};
        for (const key in item) {
          if (!key.startsWith("Phone")) {
            newObj[key] = item[key];
          }
        }
        validatedData.push({...newObj, ...newNumbers})
      } 
    })
    const payload = {
      data: validatedData,
      fileName: csvData.fileName,
    }

   
    

    if (errorRows.length) {
      setConfirmUploadSweetAlert(true)
      setCount({
        inValidCount: errorRows.length,
        totalCount: csvData?.data?.length,
        validCount: validatedData.length,
        inValidEntriesIndex: errorRows.sort((a, b) => a - b),
      })
      setFinalData(payload)
    } else {
      handleCreateBulkContact(payload)
      setCount({
        inValidCount: errorRows.length,
        totalCount: csvData?.data?.length,
        validCount: validatedData.length,
        inValidEntriesIndex: errorRows.sort((a, b) => a - b),
      })
    }
  }

  const handleCreateBulkContact = async payload => {
   
    let maxKeyCount = 0
    let maxKeyIndex = -1
    payload?.data.forEach((item, index) => {
  
      const count = Object.keys(item).length; 
      if (count > maxKeyCount) {
          maxKeyCount = count;
          maxKeyIndex = index;
      }
  });
    const csv = convertToCsv(payload.data, Object.keys(payload.data[maxKeyIndex]))
    const formData = new FormData()
    const csvBlob = new Blob([csv], { type: "text/csv" })
    formData.append("file", csvBlob, csvData.fileName)
    setIsLoading(true)
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    }
    setIsLoading(false)
    setFormSubmitted({
      isSubmitted: true,
      progressPer: 0,
    })

   
    setConfirmUploadSweetAlert(false)
    
    try {
      const response = await Axios.post(
        process.env.REACT_APP_PORT +
          "/api/verifiedcontact/VerifiedContactsfile",
        formData,
        config
      )
      if (response?.data) {
        notify("success", "Contacts are created successfully")
        setFileHeaders([])
        getBulkList()
        setBulkContactFileTemplate(bulkContactFileTemplateInitial)
        setIsLoading(false)
        setFormSubmitted({
          isSubmitted: false,
        })
        setConfirmUploadSweetAlert(false)
        setAddContacts({ isOpen: false })
        // window.location.reload();
      }
    } catch (err) {
      setIsLoading(false)
      setFormSubmitted({
        isSubmitted: false,
      })
      setAddContacts({ isOpen: false })
      setFileHeaders([])
      setConfirmUploadSweetAlert(false)

      notify("error", err?.response?.data?.error, err?.data?.message)
    }
  }

  const bulkListTableData = [
    {
      name: "Upload File name",
      selector: "uploadFileName",
      sortable: true,
      cell: row => row?.importfilename,
      minWidth: "350px",
    },

    {
      name: "Download File Name",
      selector: "downloadFileName",
      sortable: true,
      cell: row => row?.exportfile,
      minWidth: "250px",
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      cell: row =>
        moment.utc(row.createdAt).local().format("MM-DD-YYYY | hh:mm A"),
      minWidth: "200px",
    },

    {
      name: "Download ",
      selector: "download",
      sortable: true,
      cell: row => (
        <a
          target={"_blank"}
          download=""
          href={process.env.REACT_APP_PORT + "/uploads/" + row?.exportfile}
        >
          {/* <Tooltip
            placement="top"
            // isOpen={ttop}
            target="upload4"
            // toggle={() => {
            //   setttop(!ttop)
            // }}
          >
            Bulk Download
          </Tooltip> */}
          <Button
            className={"has-icon mx-1"}
            color={"outline-success"}
            id="upload4"
            // onClick={() => {
            //   setpopovertop(!popovertop)
            // }}
          >
            <i className="fas fa-download"></i>
          </Button>
        </a>
      ),
      minWidth: "80px",
      maxWidth: "110px",
    },

    {
      name: "Delete",
      selector: "delete",
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => setBulkFileDelete({ isOpen: true, data: row })}
            className={"has-icon mx-1"}
            color={"outline-danger"}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      ),
      minWidth: "70px",
      maxWidth: "70px",
    },
  ]

  // contact tab

  const contactListColumn = [
    {
      name: "Name",
      selector: "name",
      sortable: false,
      cell: row =>row?.FirstName &&  row?.FirstName + " " + row?.LastName,
    },
    {
      name: "Phone",
      selector: "phone",
      sortable: false,
      cell: row =>(row?.Phone ?   row?.Phone : '-'),
    },
    {
      name: "Phone Type",
      selector: "phone type",
      sortable: false,
      cell: row =>( row?.PhoneType ? row?.PhoneType : '-'),
    },
    {
      name: "Carrier Name ",
      selector: "carrier name",
      sortable: false,
      cell: row => (row?.CarrierName ? row?.CarrierName : "-"),
    },
    {
      name: "Processed Date",
      selector: "process date",
      sortable: false,
      cell: row =>row?.createdAt && 
        moment.utc(row?.createdAt).local().format("MM-DD-YYYY | hh:mm A"),
    },
    {
      name: "Action",
      selector: "action",
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => setContactDelete({ isOpen: true, data: row })}
            className={"has-icon mx-1"}
            color={"outline-danger"}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      ),
      minWidth: "80px",
      maxWidth: "80px",
    },
  ]

  const renderContactInput = item => {
    switch (item.type) {
      case "text": {
        return (
          <input
            type={item.type}
            id="name"
            name="name"
            value={singleFormState[item.key] && singleFormState[item.key]}
            onChange={e => {
              handleContactChange(item.key, e.target.value)
            }}
            className={
              errors[item.key] ? "form-control err-inp" : "form-control"
            }
          />
        )
      }

      default:
        break
    }
  }

  const validatePhoneNumber = Phone => {
    const cleanPhone = Phone?.replace(/\D/g, "")

    if (cleanPhone?.length === 11 && cleanPhone?.startsWith("1")) {
      return `+${cleanPhone}`
    } else if (cleanPhone?.length > 10 || cleanPhone?.length < 10) {
      return false
    } else if (cleanPhone?.length === 10) {
      return `+1${cleanPhone}`
    }
  }

  const handleContactChange = (name, value) => {
    let number = []
    let obj = {}
    obj = { ...singleFormState, [name]: value }
    if (isValidate) {
      const { error } = validateContactForm(obj)
      if (error) {
        setIsDisabled(true)
        const validationErrors = {}
        error.details.forEach(detail => {
          validationErrors[detail.path[0]] = detail.message.replaceAll('"', "")
        })
        setErrors({ ...validationErrors })
      } else {
        setIsDisabled(false)
        setErrors({})
      }
    }
    setSingleFormState(obj)
  }

  const validateContactForm = (state = singleFormState) => {
    let temObj = {}
    contactFormRowData.map(item => {
      temObj[item.key] = item.validation
    })
    const schema = Joi.object(temObj)

    return schema.validate(state, { abortEarly: false })
  }

  const handleAddContact = async event => {
    event.preventDefault()
    setIsValidate(true)
    const { error } = validateContactForm()
    if (error) {
      setIsDisabled(true)
      const validationErrors = {}
      error.details.forEach(detail => {
        validationErrors[detail.path[0]] = detail.message.replaceAll('"', "")
      })
      setErrors(validationErrors)
    } else {
      // Valid form submission
      

    //   let finalData = {} 
    //   for (const key in singleFormState) {
    //     let data = {}
    //     if (singleFormState.hasOwnProperty(key) && singleFormState[key] !== "") {
    //         data  = {[key] : singleFormState[key]} ; 

    //         finalData = {...finalData , ...data}
    //     }
      
    // }
    const payload = {
      ...singleFormState,
    }

      try {
        setIsLoading(true)
        const response = await Axios.post(
          process.env.REACT_APP_PORT +
            "/api/verifiedcontact/addVerifiedContacts",
          payload,
          config
        )

        if (response?.data) {
          notify("success", "Contact created successfully!")
          setIsLoading(false)
          getContactList()
          setAddSingleContact({ isOpen: false })
          setSingleFormState(createInitialFormState())
          setIsValidate(false)
          
        }
      } catch (error) {
        notify("error", error?.error)
        setIsLoading(false)
      }
    }
  }

  // download csv file of the contact

  const downloadContactCsv = data => {
    // Define the keys to include in the CSV
    const keys = [
      "FirstName",
      "LastName",
      "MailingAddress",
      "MailingCity",
      "MailingState",
      "MailingZip",
      "PropertyAddress",
      "PropertyCity",
      "PropertyState",
      "PropertyZip",
      "Phone",
      "PhoneType",
      "CarrierName",
    ]
    // Convert array of objects data to CSV format
    const csv = convertToCsv(data, keys)
    // Create a blob object with the CSV data
    const blob = new Blob([csv], { type: "text/csv" })
    // Create a temporary <a> tag with the download attribute
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.setAttribute("download", "data.csv")
    // Append the <a> tag to the body and click it
    document.body.appendChild(link)
    link.click()
    // Remove the <a> tag from the body
    document.body.removeChild(link)
  }

  // multi delete contact
  const deleteSelectedContact = async () => {
    setIsLoading(true)
    const data = {
      ids: selectedRows,
    }

    try {
      const response = await Axios.post(
        process.env.REACT_APP_PORT +
          "/api/verifiedcontact/DeleteVerifiedContactsids",
        data,
        config
      )
      if (response?.status == 200) {
        notify("success", "Contact's deleted successfully")
        getContactList()

        setSelectedRows([])
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }

  // single contact delete
  const deleteContact = async id => {
    try {
      const response = await Axios.delete(
        process.env.REACT_APP_PORT +
          "/api/verifiedcontact/VerifiedContacts/" +
          id,
        config
      )
      if (response?.data) {
        getContactList()
        notify("success", "Contact Deleted Successfully")
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
    }
  }

  // common in contact and bulk upload

  const convertToCsv = (data, keys) => {
    // Define the headers for the CSV file
    const headers = keys.join(",")
    // Map over each object in the data array and convert it to a CSV row
    const rows = data.map(obj => {
      // Extract the values for the desired keys
      const values = keys.map(key => obj[key])
      // Convert the values to a CSV row
      return values.join(",")
    })

    return `${headers}\n${rows.join("\n")}`
  }

  const handleAddField = next => {
    const newPhoneNumber = {
      key: `Phone${next}`,
      label: `Phone ${next}`,
      defaultValue: `Phone${next}`, 
      individual: true,
      next: next + 1,
    }
    setBulkContactFileTemplate([...bulkContactFileTemplate, newPhoneNumber])
  }

  const handleDeleteField = index => {
    const newFields = bulkContactFileTemplate.filter((_, i) => i !== index)
    setBulkContactFileTemplate(newFields)
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>ContactCart | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center">
            <Col sm={6} md={6} xl={7}>
              <div className="page-title-box">
                <h4 className="font-size-18">Contact</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/#">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Contact</BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col sm={6} md={6} xl={5}>
              {activeTab === "contact" ? (
                <div className="d-flex justify-content-end align-items-center gap-2">
                  {selectedRows && selectedRows.length ? (
                    <button
                      onClick={() => deleteSelectedContact()}
                      disabled={isLoading}
                      className="main-btn error-btn"
                      role="button"
                    >
                      <i className="fas fa-trash-alt text-white"></i>

                      <span>Delete ({selectedRows.length})</span>
                    </button>
                  ) : null}
                  <button
                    className="main-btn success-btn"
                    type="button"
                    onClick={() => {
                      if (contactList?.length) {
                        downloadContactCsv(contactList)
                      }
                    }}
                    role="button"
                  >
                    <i className="fas fa-download text-white"></i>

                    <span>Download </span>
                  </button>
                  <button
                    className="main-btn"
                    type="button"
                    onClick={() => {
                      setAddSingleContact({ isOpen: true })
                      setFormReloadKey(Math.random())
                    }}
                    role="button"
                  >
                    <img src={plus} alt="" />
                    <span>Add Contact </span>
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-end align-items-center gap-2">
                  {selectedBulkContact && selectedBulkContact.length ? (
                    <button
                      onClick={() => deleteSelectedBulkContact()}
                      disabled={isLoading}
                      className="main-btn error-btn"
                      role="button"
                    >
                      <i className="fas fa-trash-alt text-white"></i>

                      <span>Delete ({selectedBulkContact?.length})</span>
                    </button>
                  ) : null}
                  <button
                    className="main-btn"
                    type="button"
                    onClick={() => setAddContacts({ isOpen: true })}
                    role="button"
                  >
                    <i class="fas fa-upload text-white"></i>
                    <span>Upload File</span>
                  </button>
                  <button
                    className="main-btn border-btn"
                    onClick={() => {
                      handleDownloadCSV()
                    }}
                    type="button"
                  >
                    <i className="fas fa-download text-indigo"></i>
                    <span>Default Template</span>
                  </button>
                </div>
              )}
            </Col>
            <Col md={12} className={"pb-2"}>
              <span
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                }
              ></span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xl={12} md={12} sm={12}>
              <div className="d-flex gap-3 px-3">
                <div
                  xl={3}
                  md={3}
                  sm={3}
                  className="d-flex justify-content-center"
                >
                  <button
                    className={
                      activeTab === "contact"
                        ? "active-tab cursor-pointer"
                        : "tabs cursor-pointer"
                    }
                    onClick={() => {
                      setActiveTab("contact")
                      setTableReloadKey(Math.random)
                      getContactList()
                    }}
                  >
                    Contact
                  </button>
                </div>
                <div
                  xl={3}
                  md={3}
                  sm={3}
                  className="d-flex justify-content-center"
                >
                  <button
                    className={
                      activeTab === "bulkUpload"
                        ? "active-tab cursor-pointer"
                        : "tabs cursor-pointer"
                    }
                    onClick={() => {
                      setActiveTab("bulkUpload")
                      setTableReloadKey(Math.random)
                      getBulkList()
                    }}
                  >
                    Bulk Upload
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          {isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}
          {activeTab === "contact" ? (
            <div className="c-card">
              <DataTable
                key={tableReloadKey}
                columns={contactListColumn}
                data={contactList}
                fixedHeader
                highlightOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 15, 25, 50]}
                paginationTotalRows={contactList?.length}
                selectableRows
                onSelectedRowsChange={state => {
                  const selectedIds = state.selectedRows?.map(row => row?._id)
                  setSelectedRows(selectedIds)
                }}
                selectedRows={selectedRows}
              />
            </div>
          ) : activeTab === "bulkUpload" ? (
            <div className="c-card">
              <DataTable
                key={tableReloadKey}
                columns={bulkListTableData}
                data={bulkList}
                fixedHeader
                highlightOnHover
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 15, 25, 50]}
                paginationTotalRows={bulkList?.length}
                selectableRows
                onSelectedRowsChange={state => {
                  const selectedIds = state.selectedRows?.map(row => row?._id)
                  setSelectedBulkContact(selectedIds)
                }}
                selectedRows={selectedBulkContact}
              />
            </div>
          ) : null}

          {/* upload file / multiple add contact modal  */}

          {confirmUploadSweetAlert ? (
            <SweetAlert
              title="Are you sure?"
              info
              showCancel
              confirmBtnText={`Continue with ${
                count.validCount > 1
                  ? count.validCount + " Entries"
                  : count.validCount + " Entry"
              } `}
              confirmBtnDisabled={!count.validCount}
              cancelBtnText="Back"
              confirmBtnBsStyle={!count.validCount ? "secondary" : "primary"}
              cancelBtnBsStyle="dark"
              // cancelBtnCssClass={`main-btn secondary-btn`}
              // confirmBtnCssClass={`main-btn ${!count.validCount ? "opacity-50" : ""}`}
              onConfirm={() => {
                if (count.validCount) {
                  handleCreateBulkContact(finalData)
                }
              }}
              onCancel={() => {
                setConfirmUploadSweetAlert(false)
              }}
            >
              <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
                <div>
                  <p className="mb-1 ">
                    Total Prospect:
                    <strong> {count.totalCount} </strong> || Valid Entry:
                    <strong> {count.validCount} </strong> || Invalid Entry:
                    <strong> {count.inValidCount}</strong>
                  </p>
                  <p className="mb-1 ">
                    Invalid Rows Index :{" "}
                    <strong>{count?.inValidEntriesIndex?.join(" , ")}</strong>
                  </p>
                </div>
              </div>
              Do you want to continue with Valid Entries ? You aren't able to
              Upload the Invalid Entries
            </SweetAlert>
          ) : null}

          <Modal
            isOpen={addContacts?.isOpen}
            toggle={() => {
              setAddContacts({ isOpen: false })
              setFileHeaders([])
              setBulkContactFileTemplate(bulkContactFileTemplateInitial)
            }}
            className="c-modal modal-lg"
            centered={true}
          >
            <div className="modal-body">
              <h5 className="modal-title mt-0 mb-4 ">
                Upload Verified Contact
              </h5>
              <button
                type="button"
                onClick={() => {
                  setAddContacts({ isOpen: !addContacts.isOpen })
                  setFileHeaders([])
                  setBulkContactFileTemplate(bulkContactFileTemplateInitial)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={close} alt="" />
              </button>

              <CardBody>
                {formSubmitted?.isSubmitted ? (
                  <Row className="gy-3">
                    {
                      <>
                        <Col md={4}>
                          <div className="c-card ic-card p-3">
                            <p className="mb-0">
                              Total Prospect:
                              <strong> {count?.totalCount} </strong>
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="c-card ic-card p-3">
                            <p className="mb-0">
                              Valid Prospect:
                              <strong> {count?.validCount} </strong>
                            </p>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="c-card ic-card p-3">
                            <p className="mb-0">
                              Invalid Prospect:
                              <strong> {count?.inValidCount}</strong>
                            </p>
                          </div>
                        </Col>
                      </>
                    }
                    <Col md={12} className="mt-3">
                      Status:{" "}
                      <strong>
                        {(formSubmitted?.progressPer).toFixed(2)}%
                      </strong>
                    </Col>
                    <Col md={12}>
                      {
                        <Progress
                          style={{
                            height: "16px",
                            borderRadius: "20px",
                            marginBottom: "10px",
                          }}
                          color="success"
                          value={
                            isNaN(formSubmitted?.progressPer)
                              ? 0
                              : (formSubmitted?.progressPer).toFixed(2)
                          }
                        ></Progress>
                      }
                    </Col>
                  </Row>
                ) : (
                  <>
                    <AvForm className="needs-validation">
                      <Row className={"px-2"}>
                        <Col md={12}>
                          <div className="d-flex justify-content-start align-items-center gap-2  ">
                            <Label htmlFor="fileUploader" className="mb-0">
                              Upload file{" "}
                            </Label>
                            <CSVReader
                              onFileLoaded={handleFileUpload}
                              id="fileUploader"
                            />
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {fileHeaders && fileHeaders.length > 1 ? (
                      <AvForm
                        onValidSubmit={(e, v) => prospectSubmitHandler(e, v)}
                        className="needs-validation"
                        key={formReloadKey}
                      >
                        <div>
                          <Row className="py-1 px-1">
                            {bulkContactFileTemplate.map((item, index) =>
                              item.individual !== true ? (
                                <Col key={index} md={6} xl={6} sm={12} xs={12}>
                                  <Label>{item.label}</Label>
                                  <AvField
                                    type="select"
                                    onChange={e => {
                                      setFormState(prevState => ({
                                        ...prevState,
                                        [item.key]: getValidValue(
                                          e.target.value
                                        ),
                                      }))
                                    }}
                                    validate={{
                                      ...(item.required
                                        ? {
                                            required: {
                                              value: true,
                                              errorMessage: `Please select ${item.label}`,
                                              pattern: {
                                                value: /^[^\s]+$/,
                                                errorMessage:
                                                  "Value cannot be only whitespace",
                                              },
                                            },
                                          }
                                        : {}),
                                    }}
                                    name={item.key}
                                    value={getValidValue(item.defaultValue)}
                                  >
                                    <option value={null} key={index}>
                                      Select {item.label}
                                    </option>
                                    {fileHeaders.map((item, index) => (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    ))}
                                  </AvField>
                                </Col>
                              ) : (
                                <Col key={index} md={6} xl={6} sm={12} xs={12}>
                                  <div
                                    className="d-flex flex-column justify-content-start align-items-start"
                                    style={{ width: "100%" }}
                                  >
                                    <Label>{item.label}</Label>
                                    <div
                                      className="d-flex justify-content-start align-items-start"
                                      style={{ width: "100%" }}
                                    >
                                      <div
                                        className="d-flex"
                                        style={{ flex: "90%" }}
                                      >
                                        <AvField
                                          style={{ width: "100%" }}
                                          type="select"
                                          onChange={e => {
                                            setFormState(prevState => ({
                                              ...prevState,
                                              [item.key]: getValidValue(
                                                e.target.value
                                              ),
                                            }))
                                          }}
                                          validate={{
                                            ...(item.required
                                              ? {
                                                  required: {
                                                    value: true,
                                                    errorMessage: `Please select ${item.label}`,
                                                    pattern: {
                                                      value: /^[^\s]+$/,
                                                      errorMessage:
                                                        "Value cannot be only whitespace",
                                                    },
                                                  },
                                                }
                                              : {}),
                                          }}
                                          name={item.key}
                                          value={getValidValue(
                                            item?.defaultValue
                                          )}
                                        >
                                          <option value={null} key={index}>
                                            Select {item.label}
                                          </option>
                                          {fileHeaders.map((item, index) => (
                                            <option key={index} value={item}>
                                              {item}
                                            </option>
                                          ))}
                                        </AvField>
                                      </div>
                                      <div
                                        className="d-flex flex-column gap-2 align-items-center p-1"
                                        style={{ flex: "10%" }}
                                      >
                                        <Button
                                          onClick={() => {
                                            handleDeleteField(index)
                                          }}
                                          className="has-icon"
                                          color="outline-danger"
                                          disabled={
                                            item?.label !== "Phone 1"
                                              ? false
                                              : true
                                          }
                                        >
                                          <i className="fas fa-trash-alt"></i>
                                        </Button>

                                        {bulkContactFileTemplate.length - 1 ===
                                        index && item.next!==11 ? (
                                          <Button
                                            onClick={() => {
                                              handleAddField(item?.next)
                                            }}
                                            className="has-icon"
                                            color="outline-primary"
                                          >
                                            <i className="fas fa-plus"></i>
                                          </Button>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )
                            )}
                            <Col md={12}>
                              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                                <Button
                                  className="main-btn secondary-btn"
                                  onClick={() => {
                                    setAddContacts({
                                      isOpen: !addContacts.isOpen,
                                    })
                                    setBulkContactFileTemplate(
                                      bulkContactFileTemplateInitial
                                    )
                                    setFileHeaders([])
                                  }}
                                  role="button"
                                >
                                  <span>Cancel</span>
                                </Button>
                                <Button
                                  type="submit"
                                  color="primary"
                                  className="main-btn"
                                  disabled={
                                    !(
                                      formState.FirstName &&
                                      formState.LastName &&
                                      formState.Phone1
                                    )
                                  }
                                >
                                  <span>Submit</span>
                                </Button>{" "}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </AvForm>
                    ) : (
                      <div className="chat-illus illus-empty-state">
                        {fileHeaders.length == 1 ? (
                          <>
                            <img src={illus} alt="" />
                            <h3 className="text-danger">
                              Please Upload Valid File
                            </h3>
                          </>
                        ) : (
                          <>
                            {" "}
                            <img src={illus} alt="" />
                            <h3>Please Upload File</h3>
                            <Link
                              to="#"
                              className={
                                "text-indigo text-decoration-underline"
                              }
                              onClick={() => {
                                handleDownloadCSV()
                              }}
                            >
                              Download Default File
                            </Link>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
              </CardBody>
            </div>
          </Modal>

          {bulkFileDelete.isOpen ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText="Yes, remove it"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="dark"
              onConfirm={() => {
                setBulkFileDelete({ isOpen: false })

                deleteBulkContact(bulkFileDelete?.data?._id)
              }}
              onCancel={() => {
                setBulkFileDelete({ isOpen: false })
              }}
            >
              You want to delete{" "}
              <strong>{bulkFileDelete?.data?.importfilename}</strong>! You won't
              be able to revert{" "}
            </SweetAlert>
          ) : null}

          {/*  single add contact modal  */}
          <Modal
            isOpen={addSingleContact?.isOpen}
            toggle={() => {
              setAddSingleContact({ isOpen: false })
              setSingleFormState(createInitialFormState())
              setErrors({})
              setIsValidate(false)
              setIsDisabled(false)
            }}
            className="c-modal modal-lg"
            centered={true}
          >
            <div className="modal-body">
              <h5 className="modal-title mt-0 mb-4 ">Add Verified Contact</h5>
              <button
                type="button"
                onClick={() => {
                  setAddSingleContact({ isOpen: !addSingleContact.isOpen })
                  setSingleFormState(createInitialFormState())
                  setErrors({})
                  setIsValidate(false)
                  setIsDisabled(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={close} alt="" />
              </button>

              <CardBody>
                <form onSubmit={handleAddContact} key={formReloadKey}>
                  <div className="row g-3 p-1">
                    {contactFormRowData.map((item, index) => {
                      return !item.individual ? (
                        <div
                          className={
                            item.classes || "col-lg-6 col-md-12 col-12"
                          }
                          key={index}
                        >
                          <div className="form-group">
                            <div className="inp-grp">
                              <label htmlFor={item.key} className="form-label">
                                {item.title}
                              </label>

                              {renderContactInput(item)}
                              {errors[item.key] && (
                                <span className="text-danger position-absolute">
                                  {errors[item.key]}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null
                    })}

                    <div className="d-flex flex-row flex-wrap">
                      <label className="form-label">Phone</label>
                      {singleFormState?.PhoneNumbers?.map((item, index) => {
                        return (
                          <div className="d-flex form-group col-12  ">
                            <input
                              className="form-control"
                              type="text"
                              id="name"
                              name="name"
                              placeholder={`Phone ${index}`}
                              value={singleFormState.PhoneNumbers[index]}
                              onChange={e => {
                                const mssgSamples = singleFormState.PhoneNumbers
                                mssgSamples[index] = e.target.value
                                handleContactChange("PhoneNumbers", mssgSamples)
                              }}
                            />

                            <div className="d-flex flex-column gap-2 align-items-center p-1 justify-content-start w-20">
                              <Button
                                onClick={() => {
                                  const mssgSamples =
                                    singleFormState.PhoneNumbers
                                  mssgSamples.splice(index, 1)
                                  handleContactChange(
                                    "PhoneNumbers",
                                    mssgSamples
                                  )
                                }}
                                className="has-icon"
                                color="outline-danger"
                                disabled={
                                  singleFormState?.PhoneNumbers?.length < 2
                                }
                              >
                                <i className="fas fa-trash-alt"></i>
                              </Button>
                              {singleFormState?.PhoneNumbers?.length - 1 ===
                                index && (
                                <Button
                                  onClick={() => {
                                    const mssgSamples =
                                      singleFormState.PhoneNumbers
                                    mssgSamples.push("")
                                    handleContactChange(
                                      "PhoneNumbers",
                                      mssgSamples
                                    )
                                  }}
                                  className="has-icon"
                                  color="outline-primary"
                                >
                                  <i className="fas fa-plus"></i>
                                </Button>
                              )}
                            </div>
                            {errors[item.key] && (
                              <span className="text-danger position-absolute">
                                {errors[item.key]}
                              </span>
                            )}
                          </div>
                        )
                      })}
                      {errors["PhoneNumbers"] && (
                        <span className="text-danger ">
                          {errors["PhoneNumbers"]}
                        </span>
                      )}
                    </div>

                    <div className="col-12">
                      <div className="d-flex justify-content-end gap-2">
                        {/* className="main-btn secondary-btn" */}
                        <button
                          className={`main-btn secondary-btn`}
                          type="reset"
                          onClick={() => {
                            setAddSingleContact({ isOpen: false })
                            setSingleFormState(createInitialFormState())
                            setErrors({})
                            setIsValidate(false)
                            setIsDisabled(false)
                          }}
                        >
                          <span>Cancel</span>
                        </button>
                        <button
                          className={`main-btn ${
                            isDisabled ? "opacity-50" : ""
                          }`}
                          type="submit"
                          disabled={isDisabled}
                        >
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </CardBody>
            </div>
          </Modal>

          {contactDelete.isOpen ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText="Yes, remove it"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="dark"
              onConfirm={() => {
                setContactDelete({ isOpen: false })
                deleteContact(contactDelete?.data?._id)
              }}
              onCancel={() => {
                setContactDelete({ isOpen: false })
              }}
            >
              You want to delete{" "}
              <strong>
                {contactDelete?.data?.FirstName +
                  " " +
                  contactDelete?.data?.LastName}
              </strong>
              ! You won't be able to revert{" "}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ContactCart
