import React, { useState } from "react"
import _debounce from "lodash.debounce"
import MetaTags from "react-meta-tags"
import {
  Alert,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Label,
  Input,
  FormGroup,
  Row,
} from "reactstrap"
import { Link, Redirect } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import toast, { Toaster } from "react-hot-toast"
import "./DncListing.scss"
import axios from "axios"
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { set } from "lodash"
import { is } from "immutable"
const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      if (typeof text !== "string") {
        if (text?.message && typeof text?.message === "string") {
          toast.error(text?.message)
        } else {
          toast.error("An unexpected error occured")
        }
      } else {
        toast.error(text)
      }
      break

    default:
      break
  }
}

const AddDncManually = props => {
  // const navigate = useHistory()
  let authUser = localStorage?.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }

  ///states
  const [formData, setFormData] = useState({})
  // const [first_name, setFirstName] = useState("")
  // const [last_name, setLastName] = useState("")
  const [mobile, setMobile] = useState("")
  const [res, setRes] = useState("")
  const [loading, setLoading] = useState(false)
  const [phone_error, setPhoneError] = useState(false)
  const [dnc ,setDnc] = useState(true)
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })
  //handle submit
  const handleSubmit = async () => {
    if (!mobile) {
      setPhoneError("Phone number is required")
      return
    }
    if (mobile.length < 9) {
      setPhoneError("Phone number must be 9 or 10 digits")
      return
    }
    if (isNaN(+mobile)) {
      setPhoneError("Phone number must be numeric")
      return
    }
    let data = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      phone: mobile,
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    setLoading(true)
    if (dnc) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_PORT + "/api/prospect/DNCaddmanually",
          data,
          config
        )
        if (response.status === 200) {
          notify('success',"DNC added successfully ")
          setTimeout(() => {
            setShouldRedirect({
              state: true,
              path: `/restrictedContact/dnc-list`,
            })
            setLoading(false)
          }, 1000)
        } 
      } catch (error) {
        notify("error",error)
        
      }
    } else {
      
      try {
        const response = await axios.post(
          process.env.REACT_APP_PORT + "/api/wrongNumbers/createWrongNumber",
          data,
          config
        )
 
        if (response.status === 200) {
          notify("success", "Wrong Number added successfully")
          // setRes("Wrong Number added successfully ")
          setTimeout(() => {
            // setRes("")
            // navigate.push("/dnc-list")
            setShouldRedirect({
              state: true,
              path: `/restrictedContact/wrongNumber`,
            })
            setLoading(false)
          }, 1000)
        } 
      } catch (error) {
        notify('error',error)
       
      }
    }
  }

  return (
    <React.Fragment>
      <Toaster/>
      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Add Contact | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="page-title-box">
                <h4 className="font-size-18">Add Restricted Contact</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Restricted Contact</BreadcrumbItem>
                  {/* <BreadcrumbItem>
                    <Link to="/dnc-list">Restricted Contact</Link>
                  </BreadcrumbItem> */}
                  <BreadcrumbItem active>Add Restricted Contact</BreadcrumbItem>
                </ol>
              </div>
            </Col>
          </Row>
          <Row className={"justify-content-center pt-4"}>
            <Col xl="6">
              <Card>
                <CardBody>
                  {/* {res && (
                    <Alert
                      color={
                        res == "Successfully submitted" ? "success" : "danger"
                      }
                    >
                      {res}
                    </Alert>
                  )} */}
                  <AvForm className="needs-validation">
                    <Row>
                    <Label
                          className="form-check-label mb-2"
                          
                        >
                          Select Contact Type
                        </Label>

                      <Col md={12}>
                        <div className="d-flex gap-5 mb-2">
                        <div className="d-flex gap-2">
                        <Input
                          type="radio"
                          id="customRadioInline1"
                          name="contactType"
                          className="form-check-input"
                          defaultChecked={dnc}
                          onChange={()=> setDnc(true)}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="customRadioInline1"
                        >
                          DNC 
                        </Label>
                         </div>
                        <div className="d-flex gap-2">
                        <Input
                          type="radio"
                          label="wrongNumber  "
                          id="forWrongNumber"
                          className="form-check-input"
                          name="contactType"
                          
                          onChange={()=> setDnc(false)  }
                        />
                         <Label
                          className="form-check-label"
                          htmlFor="forWrongNumber"
                        >
                          Wrong Number
                        </Label>
                       

                        </div>
                        </div>

                       
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <AvField
                            value={formData.first_name}
                            onChange={e =>
                              // setFirstName(e.target.value.trimStart())
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              })
                            }
                            type="text"
                            placeholder="Enter first name"
                            name="first_name"
                            label="First Name"
                          ></AvField>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          <AvField
                            value={formData.last_name}
                            onChange={e =>
                              // setLastName(e.target.value.trimStart())
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              })
                            }
                            type="text"
                            placeholder="Enter last name"
                            name="last_name"
                            label="Last Name"
                          ></AvField>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-3">
                          {/* <AvField
                            required
                            value={mobile}
                            onChange={e =>{
                              if(isNaN(+e.target.value)) return
                              setMobile(e.target.value.trimStart())
                            }}
                            type="text"
                            placeholder="Enter phone number"
                            name="phone number"
                            label="Phone Number"
                          ></AvField> */}
                          <label htmlFor="phone_nu">{"Phone Number"}</label>
                          <input
                            id="phone_nu"
                            required
                            minLength={9}
                            maxLength={10}
                            value={formData.mobile}
                            onChange={e => {
                              if (isNaN(+e.target.value)) return
                              setPhoneError(false)
                              if (e.target.value.length > 10) {
                                setPhoneError(
                                  "Phone number must be 9 or 10 digits"
                                )
                                return
                              }
                              setMobile(e.target.value.trimStart())
                            }}
                            type="text"
                            placeholder="Enter phone number"
                            name="phone number"
                            className={
                              phone_error
                                ? "form-control-number error"
                                : "form-control-number"
                            }
                          ></input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className={"text-end"}>
                        <Link
                          to="/restrictedContact/dnc-list"
                          className="btn btn-dark me-2"
                          role="button"
                        >
                          Cancel
                        </Link>
                        <Button
                          type="submit"
                          color="primary"
                          className="h-35"
                          onClick={handleSubmit}
                        >
                          Submit
                        </Button>{" "}
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddDncManually
