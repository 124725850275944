import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"
// import "./WrongNumbers.scss"
import classnames from "classnames"
import axios from "axios"
// import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { useSelector } from "react-redux"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import dripIconBorder from "../../assets/images/drip-icon-border.svg"
import dripIconFilled from "../../assets/images/drip-icon-filled.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import debounce from "lodash.debounce"
import incomingMessage from "../../assets/images/incomingMessage.svg"
import outgoingMessage from "../../assets/images/outgoingMessage.svg"
import { activateNumber } from "helpers/fakebackend_helper"

const PhoneNumbers = ({ props }) => {
  useEffect(() => {
    getProspects()
    getDripTemplates()
    getFilter()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occured")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false)
  const [numberDeactivate, setNumberDeactivate] = useState()
  const [response, setResponse] = useState("") // for success or error response
  const [page, setPage] = useState(1)
  const [searchBy, setSearchBy] = useState("prospect")
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [selectedRows, setSelectedRows] = useState([])
  const [totalPhoneNumbers, setTotalPhoneNumbers] = useState()
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [dripTemplates, setDripTemplates] = useState([])
  const [dripModal, setDripModal] = useState(false)
  const [dripProspectId, setDripProspectId] = useState()
  const [dripReloadKey, setDripReloadKey] = useState(() => Math.random())
  const [filter, setFilter] = useState(null)
  const [filterData, setFilterData] = useState({ searchBy: "prospect" })
  const [filterReloadKey, setFilterReloadKey] = useState(Math.random())
  const [resetFilter, setResetFilter] = useState({})
  const [deletePhoneNumber, setDeletePhoneNumber] = useState({ isOpen: false })
  const [addPhoneNumber, setAddPhoneNumber] = useState({ isOpen: false })
  const [dripTemplateDetail, setDripTemplateDetail] = useState({
    isOpen: false,
    id: null,
    data: {},
  })
  const [dripRemoveConfirm, setDripRemoveConfirm] = useState({
    isOpen: false,
  })

  const getFilter = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/prospect/getFilters?",
        config
      )

      if (response?.data) {
        setFilter(response.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const isFilterSet = () => {
    let flag = false
    for (const key in filterData) {
      if (key !== "searchBy" && !!filterData[key]) {
        flag = true
        break
      }
    }
    return flag
  }
  const getProspects = async (
    crrPage = page,
    limit = rowsPerPage,
    data = filterData
  ) => {
    const queryParams = new URLSearchParams({
      page: crrPage,
      limit,
      ...data,
    })
    setIsLoading(true)
    try {
      // https://www.textlaunchpad.com/api/prospect/filter
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/numbers?" + queryParams,
        config
      )

      if (response?.data) {
        setPhoneNumbers(response?.data?.data?.phoneNumbers)
        setTotalPhoneNumbers(response?.data?.data?.phoneNumberCount)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const getDripTemplates = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplates",
        config
      )

      if (response.data) {
        setDripTemplates(response.data.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }
  const getDripTemplateDetail = async id => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplate/" + id,
        config
      )

      if (response.data) {
        setDripTemplateDetail({
          isOpen: true,
          id: id,
          data: response.data.data,
        })
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const tog_drip = () => {
    setDripModal(!dripModal)
  }

  const addDrip = async (dripTemplateId = dripTemplateDetail.id) => {
    setIsLoading(true)

    const body = {
      prospectId: dripProspectId,
      dripTemplateId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/addDrip",
        body,
        config
      )
      if (response.data) {
        notify("success", "Drip Added Successfully")
        setIsLoading(false)
        tog_drip()
        setDripTemplateDetail({ isOpen: false, id: null, data: {} })
        setDripProspectId(null)
        getProspects()
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }

  const removeDrip = async prospectId => {
    setIsLoading(true)
    if (!prospectId) {
      prospectId = dripProspectId
    }

    const body = {
      prospectId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/removeDrip",
        body,
        config
      )
      if (response.data) {
        setDripRemoveConfirm({ isOpen: false })
        setDripProspectId(null)
        notify("success", "Drip Removed Successfully")
        getProspects()
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      setIsLoading(false)
    }
  }

  const setDNC = async (id, dnc) => {
    setIsLoading(true)

    const body = {
      dnc: dnc,
      id: id,
      list: true,
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/prospect/prospectDNC/${id}`,
        body,
        config
      )
      if (response.data) {
        if (dnc) {
          notify("success", "Prospect Updated Successfully")
        } else {
          notify("success", "Prospect Updated Successfully")
        }

        getProspects()
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }
  const verifiedNumber = async (id, verified) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (verified) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/verifiednumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT +
            `/api/prospect/removeverifiednumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
      }
    }
  }
  const wrongNumber = async (id, wrongNumber) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (wrongNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/wrongnumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/removewrongnumber/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }
  const qualifiedNumber = async (id, qualifiedNumber) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (qualifiedNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/isqualified/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/wasqualified/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }
  const priorityNumber = async (id, priorityNumber) => {
    setIsLoading(true)

    const body = {
      id: id,
      list: true,
    }

    if (priorityNumber) {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/isPriority/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notify("error", error?.response?.data?.message)
      }
    } else {
      try {
        const response = await axios.put(
          process.env.REACT_APP_PORT + `/api/prospect/wasPriority/${id}`,
          body,
          config
        )
        if (response.data) {
          notify("success", "Prospect Updated Successfully")
          getProspects()
          setIsLoading(false)
        }
      } catch (error) {
        notify("error", error?.response?.data?.message)
        setIsLoading(false)
      }
    }
  }

  const handleClearSelection = () => {
    const clearData = {
      campaign: "",
      market: "",
      lead: "",
      action: "",
      search: "",
      searchBy: "prospect",
    }
    setResetFilter({ ...clearData })
    handleFilter(clearData)
    setFilterReloadKey(Math.random())
    setTableReloadKey(Math.random())
  }

  const handleNumberDeactivate = async (id, status) => {
    setIsLoading(true)
    const data = {}

    try {
      if (status == "Active") {
        const response = await axios.post(
          process.env.REACT_APP_PORT + `/api/deactivateNumber/${id}`,
          data,
          config
        )
        if (response) notify("success", "Phone Number Deactivated Successfully")
        getProspects()
        setIsLoading(false)
      } else {
        const response = await axios.post(
          process.env.REACT_APP_PORT + `/api/activateNumber/${id}`,
          data,
          config
        )
        if (response) notify("success", "Phone Number Activated Successfully")
        getProspects()
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }
  const handleDeletePhoneNumber = async id => {
    setIsLoading(true)
    const data = {}
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/releaseNumber/${id}`,
        data,
        config
      )
      if (response) notify("success", "Phone Number Deleted Successfully")
      getProspects()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }

  const handleAddPhoneNumber = async (e, value) => {
    setIsLoading(true)
    const data = {
      market: value.market,
      quantity: parseInt(value.quantity),
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/addNumbers/",
        data,
        config
      )
      if (response) notify("success", "Phone Number Added Successfully")
      setAddPhoneNumber({ isOpen: false })
      getProspects()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.message)
    }
  }

  //delete contact by id

  //delete selected contact
  const DeleteSelectedProspect = async () => {
    setIsLoading(true)
    const data = {
      id: selectedRows,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/prospect/deleteprospects",
        data,
        config
      )
      if (response?.status == 200) {
        notify("success", "Phone Numbers deleted successfully")
        getProspects()

        setSelectedRows([])
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }
  // Data table columns and data

  //   const phoneNumberData = []
  //   const phoneNumberData =
  //     phoneNumbers && phoneNumbers?.length
  //       ? phoneNumbers?.map((item, index) => {
  //           let health = "-"
  //           if (item.sent > 0) {
  //             const unDelRate = (item.carrierBlocked / item.sent) * 100
  //             if (unDelRate >= 0 && unDelRate <= 10) {
  //               health = "Good"
  //             } else if (unDelRate > 10 && unDelRate <= 20) {
  //               health = "Moderate"
  //             } else {
  //               health = "Poor"
  //             }
  //           }
  //           return {
  //             id: item?._id,
  //             number: item?.number,
  //             market: item?.market?.market || "-",
  //             status: item?.status,
  //             health: <span>{item.health}</span>,
  //             optOutPercentage: item.optOutPercentage,
  //             sent: item.sent,
  //             received: item.receivedCount,
  //             delivered: item.delivered,
  //             undelivered: item.undelivered,
  //             carrierBlocked: item.carrierBlocked,
  //             lastSent: item.lastSent,
  //             lastReceived: item.lastReceived,
  //           }
  //         })
  //       : []

  const phoneNumberColumns = [
    {
      name: "#",
      selector: "id",
      cell: (row, index) => {
        return index + 1
      },
      minWidth: "50px",
      maxWidth: "50px",
    },
    {
      name: "Number",
      selector: "number",
      sortable: true,
      cell: row => row?.number,
      minWidth: "140px",
    },
    {
      name: "Market",
      selector: "marketName", // Assuming you want to rename it to "marketName"
      sortable: true,
      cell: row => row?.market?.name,
      minWidth: "140px",
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: row => row?.status,
    },
    {
      name: "Health",
      selector: "health",
      sortable: true,
      cell: row => {
        if (row.sent > 0) {
          const unDelRate = (row.carrierBlocked / row.sent) * 100
          if (unDelRate >= 0 && unDelRate <= 10) {
            return (
              <div className="d-flex gap-1 align-items-center">✅ Good</div>
            )
          } else if (unDelRate > 10 && unDelRate <= 20) {
            return (
              <div className="d-flex gap-1 align-items-center">⚠️ Moderate</div>
            )
          } else {
            return (
              <div className="d-flex gap-1 align-items-center">❌ Poor</div>
            )
          }
        } else {
          return "-"
        }
      },
      minWidth: "100px",
      maxWidth: "170px",
    },
    {
      name: "Opt Out %",
      selector: "optOutPercentage",
      sortable: true,
      cell: row => row?.optOutPercentage,
      minWidth: "110px",
    },
    {
      name: "Sent",
      selector: "sent",
      sortable: true,
      cell: row => row?.sent,
    },
    {
      name: "Received",
      selector: "received",
      sortable: true,
      cell: row => (!isNaN(row?.received) ? row?.received : "-"),
      minWidth: "110px",
    },
    {
      name: "Delivered",
      selector: "delivered",
      sortable: true,
      cell: row => row?.delivered,
      minWidth: "110px",
    },
    {
      name: "Undelivered",
      selector: "undelivered",
      sortable: true,
      cell: row => row?.undelivered,
      minWidth: "120px",
    },
    {
      name: "Carrier Blocked",
      selector: "carrierBlocked",
      sortable: true,
      cell: row => row?.carrierBlocked,
      minWidth: "140px",
    },
    {
      name: "Last Sent",
      selector: "lastSent",
      sortable: true,
      cell: row => {
        if (row.lastSent) {
          return moment.utc(row.lastSent).local().format("MM-DD-YYYY | hh:mm A")
        } else {
          return "-"
        }
      },
      minWidth: "200px",
    },
    {
      name: "Last Received",
      selector: "lastReceived",
      sortable: true,
      cell: row => {
        if (row.lastReceived) {
          return moment
            .utc(row.lastReceived)
            .local()
            .format("MM-DD-YYYY | hh:mm A")
        } else {
          return "-"
        }
      },
      minWidth: "200px",
    },
    {
      name: "Actions",
      selector: "_id",
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            title="Release"
            onClick={() => {
              setDeletePhoneNumber({
                isOpen: true,
                number: row?.number,
                id: row._id,
              })
            }}
            className="has-icon"
            color="outline-danger"
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
          <Button
            title="Deactivate"
            onClick={() => {
              setNumberDeactivate({
                isOpen: true,
                number: row?.number,
                id: row?._id,
                status: row?.status,
              })
            }}
            className="has-icon"
            color={
              row.status === "Active" ? "outline-warning" : "outline-success"
            }
          >
            {row.status === "Active" ? (
              <i className="fas fa-ban"></i>
            ) : (
              <i className="fas fa-check"></i>
            )}
          </Button>
        </div>
      ),
      minWidth: "125px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%", // Set the overall table width to 100% to cover the screen
    },
  }

  const dropDownCustomStyle = {
    control: (base, state) => ({
      ...base,
      zIndex: 999, // Adjust this value as needed
    }),
  }

  const actionOptions = [
    { label: "Activated", value: "Active" },
    { label: "Deactivated", value: "Deactivated" },
  ]

  const handleFilter = (data = {}) => {
    const allKey = ["status", "market"]

    const newObj = {}

    for (const key of allKey) {
      if (key in data && data[key]) {
        newObj[key] = data[key]
      }
    }

    setFilterData({ ...newObj })
    getProspects(1, rowsPerPage, newObj)
    setTableReloadKey(Math.random())
  }

  const handleSelectionChange = (data, name) => {
    const selected = data
    const selectedId = selected?.map(item => {
      return item?.value
    })
    const formattedIds = selectedId?.join(",")
    setFilterData(prevData => ({
      ...prevData,
      [name]: formattedIds,
    }))
    const finalData = { ...filterData, [name]: formattedIds }
    handleFilter(finalData)
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Phone Numbers | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2" key={filterReloadKey}>
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {totalPhoneNumbers ? `${totalPhoneNumbers}` : null} Phone
                    Numbers
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Phone Numbers</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="float-end">
                  <div className={"button-items d-flex st flex-wrap gap-2"}>
                    {selectedRows && selectedRows.length ? (
                      <button
                        onClick={() => DeleteSelectedProspect()}
                        disabled={isLoading}
                        className="main-btn error-btn"
                        role="button"
                      >
                        <i className="fas fa-trash-alt text-white"></i>

                        <span>Delete ({selectedRows.length})</span>
                      </button>
                    ) : null}

                    {isFilterSet() ? (
                      <button
                        className="main-btn  border-btn"
                        role="button"
                        onClick={handleClearSelection}
                      >
                        <i className="fas fa-times color-primary"></i>

                        <span>Clear filters</span>
                      </button>
                    ) : null}

                    <button
                      className="main-btn"
                      // to="/add-campaign"
                      onClick={() => {
                        setAddPhoneNumber({ isOpen: true })
                      }}
                      type="button"
                    >
                      <img src={plus} alt="" />
                      <span> Add Phone Number</span>
                    </button>

                    {/* <Link className={"main-btn"}>
                      <img src={plus} alt="" />
                      <span>Add New Prospect</span>
                    </Link> */}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mb-4 g-3">
              <Col xs={12} sm={6} md={6} lg={6}>
                <Select
                  // value={filterData?.action}
                  onChange={e => {
                    handleSelectionChange(e, "status")
                  }}
                  options={actionOptions}
                  isMulti
                  placeholder="Actions"
                  menuPortalTarget={document.body}
                  styles={{
                    control: base => ({
                      ...base,
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      border: "1.5px solid #5932EA",
                      color: "#5932EA",
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <Select
                  // value={filterData?.market}
                  onChange={e => {
                    handleSelectionChange(e, "market")
                    // setResetFilter(prevState => ({
                    //   ...prevState, // Spread the previous state to keep its values
                    //   market: e, // Update the 'campaign' key with the value of 'e'
                    // }))
                  }}
                  options={filter?.markets?.map(item => ({
                    label: item?.name,
                    value: item?._id,
                  }))}
                  isMulti
                  placeholder="Select Market"
                  menuPortalTarget={document.body}
                  styles={{
                    control: base => ({
                      ...base,
                      borderRadius: "10px",
                      paddingLeft: "10px",
                      border: "1.5px solid #5932EA",
                      color: "#5932EA",
                    }),
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </Col>
            </Row>
          </div>
          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {response && (
              <Alert
                color={
                  response == "Something went wrong" ? "danger" : "success"
                }
              >
                {response}
              </Alert>
            )}

            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <DataTable
              // title="Campaign Metrics"
              key={tableReloadKey}
              columns={phoneNumberColumns}
              data={phoneNumbers}
              fixedHeader
              className="c-table"
              pagination
              paginationServer
              paginationTotalRows={totalPhoneNumbers}
              paginationPerPage={rowsPerPage}
              onChangeRowsPerPage={rows => {
                setRowsPerPage(rows)
                getProspects(1, rows)
                setPage(1)
                setTableReloadKey(Math.random())
              }}
              onChangePage={page => {
                setPage(page)
                getProspects(page)
              }}
              keyField={"id"}
              // selectableRows
              onSelectedRowsChange={state => {
                const selectedIds = state.selectedRows?.map(row => row?.id)
                setSelectedRows(selectedIds)
              }}
              selectedRows={selectedRows}
              customStyles={customStyles}
            />
          </div>
        </Container>
      </div>
      {dripRemoveConfirm?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          info
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            removeDrip(dripRemoveConfirm?.dripProspectId)
          }}
          onCancel={() => {
            setDripRemoveConfirm({ isOpen: false })
          }}
        >
          <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
            <div>
              <strong>Drip Start Date: </strong>{" "}
              {moment
                .utc(dripRemoveConfirm.dripData.dripStartDate)
                .local()
                .format("YYYY-MM-DD hh:mm:ss A")}
            </div>
          </div>
          You want to remove drip? You won't be able to revert this!
        </SweetAlert>
      ) : null}
      <Modal
        isOpen={dripModal}
        toggle={() => {
          tog_drip()
        }}
        backdrop="static"
        centered={true}
        className="drip-select-modal"
        key={dripReloadKey}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Select A Drip Automations</h5>
          <button
            type="button"
            onClick={() => {
              tog_drip()
              setDripTemplateDetail({ isOpen: false, id: null, data: {} })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="drip-select-container">
            <div key={dripReloadKey}>
              {!dripTemplateDetail?.isOpen ? (
                <div className="drip-aut-list">
                  <table className="c-table table">
                    <thead>
                      <tr>
                        <th>Drip Name</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {dripTemplates &&
                        dripTemplates?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.name}</td>
                              <td>
                                <button
                                  className="main-btn sm secondary-btn min-btn"
                                  onClick={() => {
                                    getDripTemplateDetail(item?._id)
                                  }}
                                >
                                  <span>View Messages</span>
                                </button>
                              </td>
                              <td>
                                <button
                                  className="main-btn sm white-icon min-btn"
                                  onClick={() => {
                                    addDrip(item?._id)
                                  }}
                                >
                                  <img src={dripIconBorder} alt="" />
                                  <span>Select</span>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="drip-messages-container">
                  <h3 className="head">{dripTemplateDetail?.data?.name}</h3>
                  <div className="drip-message-main-container">
                    {dripTemplateDetail?.data?.data?.map((item, index) => {
                      return (
                        <div className="drip-message-wrap" key={index}>
                          <div className="top">
                            <h3 className="head">Message {index + 1}</h3>
                            <span>Day {item?.day}</span>
                          </div>
                          <p className="para">{item?.message}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
            {/* drip automations END*** */}

            {/* drip automation messages */}

            {/* drip automation messages END*** */}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              if (dripTemplateDetail?.isOpen) {
                setDripTemplateDetail({
                  isOpen: false,
                  id: null,
                  data: {},
                })
              } else {
                setDripTemplateDetail({
                  isOpen: false,
                  id: null,
                  data: {},
                })
                setDripModal(false)
              }
            }}
          >
            {dripTemplateDetail?.isOpen ? "Back" : "Cancel"}
          </button>
          {dripTemplateDetail?.isOpen ? (
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                addDrip()
              }}
            >
              Select
            </button>
          ) : null}
        </div>
      </Modal>

      {/* Add phone number modal  */}
      <Modal
        isOpen={addPhoneNumber?.isOpen}
        toggle={() => {
          setAddPhoneNumber({ isOpen: !addPhoneNumber?.isOpen })
        }}
        className="c-modal"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">Add Phone Number</h5>
          <button
            type="button"
            onClick={() => {
              setAddPhoneNumber({ isOpen: !addPhoneNumber?.isOpen })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleAddPhoneNumber(e, v)}
              className="needs-validation"
            >
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <AvField
                      type="select"
                      name="market"
                      errorMessage="Select Market"
                      validate={{
                        required: { value: true },
                      }}
                      value=""
                    >
                      <option value="">Select Market</option>
                      {filter?.markets.length > 0 &&
                        filter?.markets.map(item => (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        ))}
                    </AvField>
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <AvField
                      name="quantity"
                      placeholder="Quantity"
                      type="number"
                      errorMessage="Enter Quantity"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[A-Za-z0-9 ]+$",
                          errorMessage: "Enter valid quantity",
                        },
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setAddPhoneNumber({ isOpen: false })
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Submit</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>

      {/* Delete phone number sweetAlert  */}

      {deletePhoneNumber?.isOpen ? (
        <SweetAlert
          title="Do you want to release"
          warning
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            // this.setState({ confirm_alert: false })
            // this.props.deleteNumber(this.state.number_id)
            handleDeletePhoneNumber(deletePhoneNumber?.id)
            setDeletePhoneNumber({ isOpen: false })
          }}
          onCancel={() => {
            setDeletePhoneNumber({ isOpen: false })
          }}
        >
          You won't be able to revert this! {deletePhoneNumber?.number}
        </SweetAlert>
      ) : null}

      {numberDeactivate?.isOpen ? (
        <SweetAlert
          title="Are you sure"
          warning
          showCancel
          confirmBtnText={
            numberDeactivate.status == "Active"
              ? "Yes, Deactivate it!"
              : "Yes, Activate it!"
          }
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            // this.setState({ confirm_alert: false })
            // this.props.deleteNumber(this.state.number_id)
            handleNumberDeactivate(
              numberDeactivate?.id,
              numberDeactivate.status
            )
            setNumberDeactivate({ isOpen: false })
          }}
          onCancel={() => {
            setNumberDeactivate({ isOpen: false })
          }}
        >
          You want to{" "}
          {numberDeactivate.status == "Active"
            ? "Deactivate the number"
            : "Activate the number"}{" "}
          {numberDeactivate?.number}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default PhoneNumbers
