import React, { useEffect, useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Input,
  Label,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
  Card,
  CardBody,
  FormGroup,
  Tooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  Spinner,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import { Link } from "react-router-dom"

import axios from "axios"
import DataTable from "react-data-table-component"
import toast, { Toaster } from "react-hot-toast"
import plus from "../../assets/images/white-plus.svg"
import SweetAlert from "react-bootstrap-sweetalert"

import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"

const Markets = () => {
  useEffect(() => {
    getMarkets()
  }, [])

  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occured")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  //config header for axios
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const [isLoading, setIsLoading] = useState(false)
  const [editData, setEditData] = useState()
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())
  const [markets, setMarkets] = useState([])
  const [availableNumbers, setAvailableNumbers] = useState([])
  const [selectedAreaCode, setSelectedAreaCode] = useState(null)
  const [selectedState, setSelectedState] = useState(null)
  const [deleteMarket, setDeleteMarket] = useState({ isOpen: false })
  const [editMarketModal, setEditMarketModal] = useState({ isOpen: false })
  const [addMarket, setAddMarket] = useState({
    isOpen: false,
  })

  const getAvailableNumebrs = async areaCode => {
    setIsLoading(true)
    try {
      const query = new URLSearchParams({ code: areaCode })
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/search?" + query,
        config
      )

      if (response?.data) {
        setAvailableNumbers(response?.data.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }
  const getMarkets = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/getMarkets",
        config
      )

      if (response?.data) {
        setMarkets(response?.data?.data)
        setIsLoading(false)
      }
    } catch (error) {
      notify("error", error?.data?.message)
      setIsLoading(false)
    }
  }

  const handleEditMarket = async (e, data) => {
    setIsLoading(true)
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/updateMarket/${editData._id}`,
        data,
        config
      )

      if (response.status) {
        notify("success", "Market Updated Successfully")
        setIsLoading(false)
        getMarkets()
        setEditMarketModal({
          isOpen: false,
        })
      }
    } catch (err) {
      setIsLoading(false)
      notify("error", err?.response?.data?.message)
    }
  }

  const handleDeleteMarket = async id => {
    setIsLoading(true)

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/deleteMarket/${id}`,
        {},
        config
      )
      if (response) notify("success", "Market Deleted Successfully")
      getMarkets()
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.error)
    }
  }

  const handleAddMarket = async (e, value) => {
    setIsLoading(true)
    const data = {
      callForward: value.callForward,
      market_name: value.market_name,
      limit: +value.quantity,
      code: selectedAreaCode.value,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/market",
        data,
        config
      )
      if (response) {
        notify("success", "Market Added Successfully")
        setIsLoading(false)
        setAddMarket({ isOpen: false })
        setSelectedAreaCode(null)
        setSelectedState(null)
        setAvailableNumbers([])
        getMarkets()
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error.response?.data?.error)
    }
  }

  const marketColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      cell: row => row?.name,
      minWidth: "170px",
    },
    {
      name: "Area Code",
      selector: "code",
      sortable: true,
      cell: row => row?.code,
      minWidth: "170px",
    },
    {
      name: "	Call Forward",
      selector: "callForward",
      sortable: true,
      cell: row => row?.callForward,
      minWidth: "170px",
    },

    {
      name: "Edit",
      selector: "edit",
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            color={"outline-primary"}
            className={"has-icon me-2"}
            onClick={() => {
              setEditMarketModal({ isOpen: true })
              setEditData(row)
            }}
          >
            <i className="fas fa-edit"></i>
          </Button>
        </div>
      ),
      minWidth: "170px",
    },
    {
      name: "Delete",
      selector: "_id",
      sortable: false,
      cell: row => (
        <div className="d-flex gap-2">
          <Button
            onClick={() => {
              setDeleteMarket({
                isOpen: true,
                id: row._id,
                data: row,
              })
            }}
            className="has-icon"
            color="outline-danger"
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
        </div>
      ),
      minWidth: "170px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%", // Set the overall table width to 100% to cover the screen
    },
  }

  const areaCodeCustomStyle = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #ccc",
      padding: 10,
      // You can add other styles here
    }),
    // You can define other custom styles for the select components here
  }

  const stateAreaCodes = {
    Alabama: ["205", "251", "256", "334", "938"],
    Alaska: ["907"],
    Arizona: ["480", "520", "602", "623", "928"],
    Arkansas: ["479", "501", "870"],
    California: [
      "209",
      "213",
      "310",
      "323",
      "408",
      "415",
      "510",
      "530",
      "559",
      "562",
      "619",
      "626",
      "650",
      "661",
      "707",
      "714",
      "760",
      "805",
      "818",
      "831",
      "858",
      "909",
      "916",
      "925",
      "949",
      "951",
    ],
    Colorado: ["303", "719", "720", "970"],
    Connecticut: ["203", "475", "860", "959"],
    Delaware: ["302"],
    Florida: [
      "239",
      "305",
      "321",
      "352",
      "386",
      "407",
      "561",
      "727",
      "754",
      "772",
      "786",
      "813",
      "850",
      "863",
      "904",
      "941",
      "954",
    ],
    Georgia: ["229", "404", "470", "478", "678", "706", "762", "770", "912"],
    Hawaii: ["808"],
    Idaho: ["208"],
    Illinois: [
      "217",
      "224",
      "309",
      "312",
      "331",
      "618",
      "630",
      "708",
      "773",
      "779",
      "815",
      "847",
      "872",
    ],
    Indiana: ["219", "260", "317", "463", "574", "765", "812", "930"],
    Iowa: ["319", "515", "563", "641", "712"],
    Kansas: ["316", "620", "785", "913"],
    Kentucky: ["270", "364", "502", "606", "859"],
    Louisiana: ["225", "318", "337", "504", "985"],
    Maine: ["207"],
    Maryland: ["240", "301", "410", "443", "667"],
    Massachusetts: [
      "339",
      "351",
      "413",
      "508",
      "617",
      "774",
      "781",
      "857",
      "978",
    ],
    Michigan: [
      "231",
      "248",
      "269",
      "313",
      "517",
      "586",
      "616",
      "734",
      "810",
      "906",
      "947",
      "989",
    ],
    Minnesota: ["218", "320", "507", "612", "651", "763", "952"],
    Mississippi: ["228", "601", "662", "769"],
    Missouri: ["314", "417", "573", "636", "660", "816"],
    Montana: ["406"],
    Nebraska: ["308", "402", "531"],
    Nevada: ["702", "725", "775"],
    NewHampshire: ["603"],
    NewJersey: ["201", "551", "609", "732", "848", "856", "862", "908", "973"],
    NewMexico: ["505", "575"],
    NewYork: [
      "212",
      "315",
      "347",
      "516",
      "518",
      "585",
      "607",
      "631",
      "646",
      "716",
      "718",
      "845",
      "914",
      "917",
      "929",
    ],
    NorthCarolina: [
      "252",
      "336",
      "704",
      "743",
      "828",
      "910",
      "919",
      "980",
      "984",
    ],
    NorthDakota: ["701"],
    Ohio: [
      "216",
      "220",
      "234",
      "330",
      "380",
      "419",
      "440",
      "513",
      "567",
      "614",
      "740",
      "937",
    ],
    Oklahoma: ["405", "539", "580", "918"],
    Oregon: ["458", "503", "541", "971"],
    Pennsylvania: [
      "215",
      "267",
      "272",
      "412",
      "484",
      "570",
      "610",
      "717",
      "724",
      "814",
      "878",
    ],
    RhodeIsland: ["401"],
    SouthCarolina: ["803", "843", "854", "864"],
    SouthDakota: ["605"],
    Tennessee: ["423", "615", "629", "731", "865", "901", "931"],
    Texas: [
      "210",
      "214",
      "254",
      "281",
      "325",
      "346",
      "361",
      "409",
      "430",
      "432",
      "469",
      "512",
      "682",
      "713",
      "726",
      "737",
      "806",
      "817",
      "830",
      "832",
      "903",
      "915",
      "936",
      "940",
      "956",
      "972",
      "979",
    ],
    Utah: ["385", "435", "801"],
    Vermont: ["802"],
    Virginia: ["276", "434", "540", "571", "703", "757", "804"],
    Washington: ["206", "253", "360", "425", "509"],
    WestVirginia: ["304", "681"],
    Wisconsin: ["262", "414", "534", "608", "715", "920"],
    Wyoming: ["307"],
  }

  const states = Object.keys(stateAreaCodes)

  function getAreaCodesOptions() {
    if (selectedState && selectedState.value) {
      const options = [{ label: "Please select", value: null }]
      stateAreaCodes[selectedState.value]?.map(item => {
        options.push({ label: item, value: item })
      })
      return options
    } else {
      let options = [{ label: "Please select", value: null }]
      for (const key in stateAreaCodes) {
        const arr = stateAreaCodes[key].map(item => {
          return { label: item, value: item }
        })
        options = [...options, ...arr]
      }
      return options
    }
  }
  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Markets | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <div className="mb-2">
            <Row className="align-items-center justify-content-between mb-2">
              <Col lg={6} md={6} sm={12}>
                <div className="page-title-box">
                  <h4 className="font-size-18" style={{ display: "inline" }}>
                    {/* {totalLeadStage ? `${totalLeadStage}` : null}  */}
                    Markets
                  </h4>

                  <ol className="breadcrumb mb-0">
                    <BreadcrumbItem>
                      <Link to="/dashboard2">TextLaunchPad</Link>
                    </BreadcrumbItem>
                    <BreadcrumbItem active>Markets</BreadcrumbItem>
                  </ol>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="float-end">
                  <div className={"button-items d-flex st flex-wrap gap-2"}>
                    <button
                      className="main-btn"
                      onClick={() => {
                        setAddMarket({ isOpen: true })
                      }}
                      type="button"
                    >
                      <img src={plus} alt="" />
                      <span> Add Market</span>
                    </button>
                  </div>
                </div>
              </Col>
              <Col md={12} className={"pb-2"}>
                <span
                  className={
                    "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                  }
                ></span>
              </Col>
            </Row>
          </div>

          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
            {isLoading ? (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            ) : null}

            <DataTable
              // title="Markets"
              key={tableReloadKey}
              columns={marketColumns}
              data={markets}
              fixedHeader
              className="c-table"
              pagination
              paginationPerPage={10}
              paginationRowsPerPageOptions={[10, 15, 25, 50]}
              paginationTotalRows={markets?.length}
              keyField={"id"}
              customStyles={customStyles}
            />
          </div>
        </Container>
      </div>

      {/* Add Market modal  */}
      <Modal
        isOpen={addMarket?.isOpen}
        toggle={() => {
          setAddMarket({ isOpen: !addMarket?.isOpen })
        }}
        className="c-modal modal-lg"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">Add Market</h5>
          <button
            type="button"
            onClick={() => {
              setAddMarket({ isOpen: !addMarket?.isOpen })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleAddMarket(e, v)}
              className="needs-validation"
            >
              <Row>
                <Col xl={6} md={6}>
                  <FormGroup className="mb-3">
                    <AvField
                      key={"02"}
                      name="market_name"
                      placeholder="Title"
                      type="text"
                      errorMessage="Enter Title"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[A-Za-z0-9 ]+$",
                          errorMessage: "Enter valid Market Name",
                        },
                      }}
                      id="validationCustom01"
                    />
                  </FormGroup>
                </Col>
                <Col xl={6} md={6}>
                  <AvField
                    key={"02"}
                    name="callForward"
                    placeholder="+18889997777"
                    type="text"
                    errorMessage="Enter Call Forward"
                    className="form-control"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9+]+$",
                        errorMessage: "Enter valid call forward",
                      },
                      minLength: {
                        value: 12,
                        errorMessage: "Enter valid call forward",
                      },
                      maxLength: {
                        value: 12,
                        errorMessage: "Enter valid call forward",
                      },
                    }}
                    id="validationCustom01"
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6}>
                  <FormGroup className="mb-3">
                    <Select
                      validate={{
                        required: true,
                      }}
                      value={selectedState}
                      placeholder={"Select State"}
                      onChange={selected => {
                        setSelectedState(selected)
                        setSelectedAreaCode(null)
                      }}
                      name="state"
                      // styles={{ ...areaCodeCustomStyle }}
                      options={[
                        { label: "Please Select", value: null },
                        ...states.map(item => {
                          return { label: item, value: item }
                        }),
                      ]}
                      className={"from-control"}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
                <Col xl={6} md={6}>
                  <FormGroup className="mb-3">
                    <Select
                      validate={{
                        required: true,
                      }}
                      placeholder={"Select Area Code"}
                      value={selectedAreaCode}
                      onChange={selected => {
                        setSelectedAreaCode(selected)
                        if (selected && selected.value) {
                          if (!(selectedState && selectedState.value)) {
                            const state = Object.keys(stateAreaCodes).find(
                              state =>
                                stateAreaCodes[state].includes(selected.value)
                            )
                            setSelectedState({ label: state, value: state })
                          }
                          getAvailableNumebrs(selected.value)
                        } else {
                          setAvailableNumbers([])
                        }
                      }}
                      name="areaCode"
                      // styles={{ ...areaCodeCustomStyle }}
                      options={getAreaCodesOptions()}
                      className={"from-control"}
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl={6} md={6}>
                  {selectedAreaCode?.value ? (
                    <Label>
                      Phone Numbers Available :{" "}
                      {availableNumbers?.length &&
                      availableNumbers?.length >= 30 ? (
                        <span className="text-success">
                          {availableNumbers?.length}+
                        </span>
                      ) : availableNumbers?.length &&
                        availableNumbers?.length < 30 ? (
                        <span className="text-success">
                          {availableNumbers?.length}
                        </span>
                      ) : (
                        <span className="text-danger">
                          {availableNumbers?.length}
                        </span>
                      )}
                    </Label>
                  ) : (
                    <Label>
                      <span className="text-danger">
                        Select area code to see available numbers!
                      </span>
                    </Label>
                  )}
                  <AvField
                    key={"02"}
                    name="quantity"
                    placeholder="Quantity"
                    type="number"
                    errorMessage="Enter Quantity"
                    className="form-control"
                    disabled={!availableNumbers?.length}
                    validate={{
                      required: { value: true },
                      pattern: {
                        value: "^[0-9+]+$",
                        errorMessage: "Enter valid Quantity",
                      },
                      min: {
                        value: 2,
                        errorMessage: `Minimum quantity is 2`,
                      },
                      max: {
                        value: availableNumbers?.length || 0,
                        errorMessage: `Quanity should be less then available numbers(${availableNumbers?.length})`,
                      },
                    }}
                    id="validationCustom01"
                    min={2}
                    max={availableNumbers?.length || 0}
                  />
                </Col>
                <Col
                  md={6}
                  xl={6}
                  className="align-items-end d-flex justify-content-end"
                >
                  <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                    <Button
                      className="main-btn secondary-btn"
                      onClick={() => {
                        setAddMarket({ isOpen: false })
                        setSelectedAreaCode(null)
                        setSelectedState(null)
                        setAvailableNumbers([])
                      }}
                      role="button"
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      className="main-btn"
                      disabled={!selectedAreaCode?.value}
                    >
                      <span>Submit</span>
                    </Button>{" "}
                  </div>
                </Col>
              </Row>
            </AvForm>
          }
        </div>
      </Modal>

      {/* edit market modal  */}

      <Modal
        isOpen={editMarketModal?.isOpen}
        toggle={() => {
          setEditMarketModal({
            isOpen: !editMarketModal?.isOpen,
          })
        }}
        className="c-modal"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3"> Edit Market</h5>
          <button
            type="button"
            onClick={() => {
              setEditMarketModal({
                isOpen: !editMarketModal?.isOpen,
              })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleEditMarket(e, v)}
              className="needs-validation"
            >
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="market_name"
                      placeholder="Market Name"
                      type="text"
                      errorMessage="Enter Market Name"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[A-Za-z0-9 ]+$",
                          errorMessage: "Enter valid Market Name",
                        },
                      }}
                      id="validationCustom01"
                      value={editData?.name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="callForward"
                      placeholder="+18889997777"
                      type="text"
                      errorMessage="Enter Call Forward"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: "^[0-9+]+$",
                          errorMessage: "Enter valid call forward",
                        },
                        minLength: {
                          value: 12,
                          errorMessage: "Enter valid call forward",
                        },
                        maxLength: {
                          value: 12,
                          errorMessage: "Enter valid call forward",
                        },
                      }}
                      id="validationCustom01"
                      value={editData?.callForward}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Button
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setEditMarketModal({ isOpen: false })
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Button>
                <Button type="submit" color="primary" className="main-btn">
                  <span>Update</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>

      {/* Delete market sweetAlert  */}

      {deleteMarket?.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            handleDeleteMarket(deleteMarket?.id)
            setDeleteMarket({ isOpen: false })
          }}
          onCancel={() => {
            setDeleteMarket({ isOpen: false })
          }}
        >
          You won't be able to revert{" "}
          <strong>{deleteMarket?.data?.name}</strong>
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}

export default Markets
