import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import "./style.css"
import io from "socket.io-client"
import Select from "react-select"
import {
  updateProspectDNS,
  updateProspectVerified,
  removeProspectVerified,
  updateProspectWrongNumber,
  removeProspectWrongNumber,
  updateProspectQualified,
  removeProspectQualified,
  updateProspectPriority,
  removeProspectPriority,
  sendConversation,
  sendConversationSuccess,
  getQuickReplies,
  getLeadStages, //for getting lead list
} from "store/prospects/actions.js"
import {
  getCampaign,
  exportCampProsData,
  existsProspect,
} from "../../store/campaigns/actions"
import {
  Alert,
  Badge,
  BreadcrumbItem,
  Col,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Row,
  Spinner,
} from "reactstrap"
import { get, post } from "../../helpers/api_helper"
import moment from "moment"

import Conversations from "../Prospects/Conversations"
import { useDispatch, useSelector } from "react-redux"
import { getCampaigns } from "store/actions"
let socket
let authUser = localStorage.getItem("authUser")
if (authUser) {
  let user = JSON.parse(authUser)
  socket = io(process.env.REACT_APP_PORT, {
    query: {
      email: user.user.name,
    },
  })
}

const MessageUnread = () => {
  ///chages = =>

  //  let authUser = localStorage.getItem("authUser")
  //  const [socket, setSocket] = useState(null);
  //  useEffect(()=>{
  //   if (authUser) {
  //   const newSocket = io(process.env.REACT_APP_PORT, {
  //     query: {
  //       email: user.user.name,
  //     },
  //   });
  //   setSocket(newSocket);
  // }
  //  },[setSocket])

  ///=============----------------------------------==================

  const {
    successMsg,
    apiError: apiErrorFromStore,
    quickReplies,
  } = useSelector(state => state.Prospects)
  const { campaigns } = useSelector(state => state.Campaign)

  const [params, setParams] = useState({
    page: 1,
    limit: 20,
    currentstatus: "Unread",
  })
  const dispatch = useDispatch()
  const [dataUnreadMessages, setDataUnreadMessages] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [message, setMessage] = useState(null)
  const [prospect, setProspect] = useState(null)
  const [apiError, setApiError] = useState(apiErrorFromStore || null)
  const [loading, setLoading] = useState(false)
  const [addDncData, setAddDncData] = useState({ id: null, dnc: false })

  async function ongGetListUnreadMessages() {
    setLoading(true)
    try {
      const resp = await get(
        process.env.REACT_APP_PORT + "/api/notification/list",
        { params }
      )

      if (resp && Array.isArray(resp?.data?.docs)) {
        setDataUnreadMessages(resp?.data?.docs)
        setTotalPage(resp?.data?.totalPages)

        setApiError(null)
      }
    } catch (error) {
      setApiError(error)
    } finally {
      setLoading(false)
    }
  }

  const setDNC = (id, dnc) => {
    if (!id) return
    let stDNC = dnc ? true : false
    const data = {
      dnc: stDNC,
      id: id,
      list: true,
    }
    dispatch(updateProspectDNS(data))
    ongGetListUnreadMessages()
  }

  const onSendConversation = data => {
    dispatch(sendConversation(data))
  }
  const verifiedNumber = id => {
    if (!id) return
    dispatch(
      updateProspectVerified({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  const rverifiedNumber = id => {
    if (!id) return
    dispatch(
      removeProspectVerified({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  const wrongNumber = id => {
    if (!id) return
    dispatch(
      updateProspectWrongNumber({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  const rwrongNumber = id => {
    if (!id) return
    dispatch(
      removeProspectWrongNumber({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  const qualifiedNumber = id => {
    if (!id) return
    dispatch(
      updateProspectQualified({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  const rqualifiedNumber = id => {
    if (!id) return
    dispatch(
      removeProspectQualified({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  const priorityNumber = id => {
    if (!id) return
    dispatch(
      updateProspectPriority({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  const rpriorityNumber = id => {
    if (!id) return
    dispatch(
      removeProspectPriority({
        id: id,
        list: true,
      })
    )
    ongGetListUnreadMessages()
  }

  // innit socket

  useEffect(() => {
    let authUser = localStorage.getItem("authUser")
    let user = JSON.parse(authUser)

    socket.emit("getmessagebypros", user.user.name, prospect?._id)
  }, [prospect])

  useEffect(() => {
    ongGetListUnreadMessages()
  }, [params])

  useEffect(() => {
    let authUser = localStorage.getItem("authUser")
    dispatch(getCampaigns())

    if (authUser) {
      let user = JSON.parse(authUser)

      socket.emit("getmessagebypros", user.user.name, prospect?._id)
      socket.on("message", msg => {
        if (!msg?.prospect) return setMessage(null)

        setMessage(msg)
        if (msg?.prospect) {
          // set data to change dnc status in functional component send props
          setAddDncData({ id: msg?.prospect?._id, dnc: !msg?.prospect?.dnc })
        }
      })
    }
  }, [])

  const onPostSenderPhone = phone => {
    post(process.env.REACT_APP_PORT + "/api/notification/read-phone", {
      phone,
    })
  }

  const callExistsProspect = (id, value) => {
    dispatch(existsProspect({ _id: id, value: value }))
  }

  useEffect(() => {
    getssss()
  }, [])
  const getssss = async () => {
    await dispatch(getLeadStages())
  }

  let leads = useSelector(state => state?.LeadStage?.leadStages)
  let message_popup = useSelector(state => state.Campaign)
  let [success, setSuccess] = useState("")

  useEffect(() => {
    setSuccess(message_popup?.successMsg)
    // successMsg && setSuccess(successMsg)
    setTimeout(() => {
      setSuccess("")
    }, 3000)
  }, [message_popup?.successMsg])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Unread | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Row className="align-items-center breadcrumb-box">
            <Col xs={7}>
              <div className="page-title-box">
                <h4 className="font-size-18">All Unread </h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>All Unread </BreadcrumbItem>
                </ol>
              </div>
            </Col>

            <Col md={12} className={"pb-2"}>
              <span
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                }
              ></span>
            </Col>
          </Row>
          <Row>
            {/* <Col md={4}>
              <Select
                onChange={({ value }) => {
                  setParams({ ...params, page: 1, currentstatus: value })
                }}
                options={[
                  { label: "All", value: null },
                  { label: "Unread", value: "Unread" },
                  { label: "Active", value: "Active" },
                ]}
                placeholder="Select status"
                classNamePrefix="select2-selection"
              />
            </Col> */}
            <Col md={4}>
              <Select
                onChange={({ value }) => {
                  setParams({ ...params, page: 1, campaignId: value })
                }}
                options={[{ name: "All", _id: null }, ...campaigns].map(i => ({
                  label: i.name,
                  value: i._id,
                }))}
                placeholder="Select Campaign"
                classNamePrefix="select2-selection"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              {apiError && typeof apiError === "string" ? (
                <Alert color="danger">{apiError}</Alert>
              ) : null}

              {apiError && typeof apiError === "object" ? (
                apiError.response ? (
                  <Alert color="danger">
                    {"Internal Communication Occured"}
                  </Alert>
                ) : (
                  <Alert color="danger">
                    {"Internal Communication Occured"}
                  </Alert>
                )
              ) : null}

              {/* {successMsg ? <Alert color="success">{successMsg}</Alert> : null} */}
              {success ? <Alert color="success">{success}</Alert> : null}

              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <Table id="tech-companies-1" className="table veltrix-table">
                    {/* <Thead>
                      <Tr>
                        <Th>Full Name</Th>
                        <Th>Phone</Th>
                        <Th>Content</Th>
                        <Th>Action</Th>
                        <Th>Status</Th> 
                      </Tr>
                    </Thead> */}
                    <Tbody>
                      {loading ? (
                        <Tr>
                          <Td colSpan={6} className={"text-center"}>
                            <Spinner className="ms-2" color="primary" />
                          </Td>
                        </Tr>
                      ) : null}
                      {loading === false && dataUnreadMessages.length <= 0 ? (
                        <Tr>
                          <Td valign={"middle"} colSpan={6}>
                            No message found.
                          </Td>
                        </Tr>
                      ) : null}

                      {dataUnreadMessages
                        ? dataUnreadMessages.map((read, index) => (
                            <Tr
                              onClick={() => {
                                onPostSenderPhone(read?.from)
                                setProspect(read.prospect)
                              }}
                              className="tr-hover"
                              key={read?._id}
                            >
                              <Td align={"start"}>
                                <p className="table-first-last-name">
                                  {read?.prospect?.first_name || ""}{" "}
                                  {read?.prospect?.last_name || ""}
                                  <span className="truncated-text">
                                    {" "}
                                    - {read?.body}
                                  </span>
                                </p>
                                {/* <small>Respons Recieved</small> */}
                              </Td>
                              {/* <Td align={"middle"}>
                                {read?.prospect?.phone || ""}
                              </Td>
                              <Td
                                style={{
                                  maxWidth: 200,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                align={"middle"}
                              >
                                {" "}
                                {read?.body || ""}
                              </Td> */}
                              <Td
                                style={{ minWidth: 200 }}
                                valign={"start"}
                                className="align-right"
                              >
                                <div>
                                  {read?.prospect.dnc ? (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"danger"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setDNC(read?.prospect?._id, false)
                                      }}
                                      title="Remove From DNC"
                                    >
                                      <i className="fas fa-times" />
                                    </Button>
                                  ) : (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"outline-secondary"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setDNC(read?.prospect?._id, true)
                                      }}
                                      title="Add To DNC"
                                    >
                                      <i className="fas fa-times" />
                                    </Button>
                                  )}{" "}
                                  {read?.prospect.verified === false ? (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"outline-secondary"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        verifiedNumber(read?.prospect?._id)
                                      }}
                                      title="Add To Verified"
                                    >
                                      <i className="fas fa-check" />
                                    </Button>
                                  ) : (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"success"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        rverifiedNumber(read?.prospect?._id)
                                      }}
                                      title="Remove From Verified"
                                    >
                                      <i className="fas fa-check" />
                                    </Button>
                                  )}{" "}
                                  {read?.prospect.Wrong_Number === false ? (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"outline-secondary"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        wrongNumber(read?.prospect._id)
                                      }}
                                      title="Add To Wrong Number"
                                    >
                                      <i className="fas fa-phone-slash" />
                                    </Button>
                                  ) : (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"primary"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        rwrongNumber(read?.prospect?._id)
                                      }}
                                      title="Remove From Wrong Number"
                                    >
                                      <i className="fas fa-phone-slash" />
                                    </Button>
                                  )}{" "}
                                  {read?.prospect.isLead === false ? (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"outline-secondary"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        qualifiedNumber(read?.prospect?._id)
                                      }}
                                      title="Add to Qualified"
                                    >
                                      <i className="fas fa-star" />
                                    </Button>
                                  ) : (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"info"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        rqualifiedNumber(read?.prospect?._id)
                                      }}
                                      title="Remove From Qualified"
                                    >
                                      <i className="fas fa-star" />
                                    </Button>
                                  )}{" "}
                                  {read?.prospect.isPriority === false ? (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"outline-secondary"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        priorityNumber(read?.prospect._id)
                                      }}
                                      title="Add To Priority"
                                    >
                                      <i className="fas fa-bolt" />
                                    </Button>
                                  ) : (
                                    <Button
                                      className={"has-icon small me-1 mb-1"}
                                      color={"warning"}
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        rpriorityNumber(read?.prospect?._id)
                                      }}
                                      title="Remove From Priority"
                                    >
                                      <i className="fas fa-bolt" />
                                    </Button>
                                  )}{" "}
                                </div>
                                <span className="date-span">
                                  {moment
                                    .utc(read?.prospect?.lastsmssend)
                                    .local()
                                    .format("MM-DD-YYYY | hh-mm A")}
                                </span>
                              </Td>

                              {/* <Td valign={"middle"}>
                                {read?.currentstatus === "Active" ? (
                                  <Badge className="btn p-1 btn-primary waves-effect waves-light">
                                    Active
                                  </Badge>
                                ) : (
                                  <Badge className="btn p-1 btn-danger waves-effect waves-light">
                                    Unread
                                  </Badge>
                                )}
                              </Td> */}
                            </Tr>
                          ))
                        : null}
                    </Tbody>
                  </Table>
                  {totalPage > 1 && dataUnreadMessages?.length > 0 && (
                    <Pagination
                      aria-label="Page navigation example"
                      listClassName="justify-content-center"
                    >
                      {params.page > 1 ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: 1 })
                            }}
                            href="#"
                            tabIndex="-1"
                          >
                            First
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#" tabIndex="-1">
                            First
                          </PaginationLink>
                        </PaginationItem>
                      )}
                      {params.page > 1 ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: params.page - 1 })
                            }}
                            href="#"
                            tabIndex="-1"
                          >
                            Previous
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#" tabIndex="-1">
                            Previous
                          </PaginationLink>
                        </PaginationItem>
                      )}
                      <PaginationItem active>
                        <PaginationLink href="#">
                          {params.page}
                          <span className="sr-only">(current)</span>
                        </PaginationLink>
                      </PaginationItem>
                      {params.page < totalPage ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={() =>
                              setParams({ ...params, page: params.page + 1 })
                            }
                            href="#"
                          >
                            {params.page + 1}
                            <span className="sr-only">(current)</span>
                          </PaginationLink>
                        </PaginationItem>
                      ) : null}
                      {params.page < totalPage ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: params.page + 1 })
                            }}
                            href="#"
                          >
                            Next
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#">Next</PaginationLink>
                        </PaginationItem>
                      )}
                      {params.page < totalPage ? (
                        <PaginationItem>
                          <PaginationLink
                            onClick={e => {
                              setParams({ ...params, page: totalPage })
                            }}
                            href="#"
                          >
                            Last
                          </PaginationLink>
                        </PaginationItem>
                      ) : (
                        <PaginationItem disabled>
                          <PaginationLink href="#">Last</PaginationLink>
                        </PaginationItem>
                      )}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 16,
                          gap: 10,
                        }}
                      >
                        <span>Go to</span>{" "}
                        <Input
                          type="number"
                          onKeyUp={e => {
                            if (e.key === "Enter") {
                              setParams({
                                ...params,
                                page:
                                  Number(e.target.value) <= totalPage &&
                                  Number(e.target.value) >= 1
                                    ? Number(e.target.value)
                                    : 1,
                              })
                            }
                          }}
                          onBlur={e =>
                            setParams({
                              ...params,
                              page:
                                Number(e.target.value) <= totalPage &&
                                Number(e.target.value) >= 1
                                  ? Number(e.target.value)
                                  : 1,
                            })
                          }
                          style={{ width: 40 }}
                        />
                        <span>Page</span>
                      </div>
                    </Pagination>
                  )}
                </div>
              </div>
            </Col>
            <Col md={6}>
              <h3>Message </h3>

              {(() => {
                if (!prospect) {
                  return <h5>No user select</h5>
                } else {
                  if (!message) return <h4>No message found</h4>
                  return (
                    <Conversations
                      hide={false}
                      id={prospect}
                      data={message}
                      leads={leads}
                      callExistsProspect={callExistsProspect}
                      showLeads={true}
                      getQuickReplies={() => dispatch(getQuickReplies())}
                      sendConversation={onSendConversation}
                      quickReplies={quickReplies}
                      updateProspectDNS={() =>
                        dispatch(updateProspectDNS(addDncData))
                      }
                      updateProspectVerified={() =>
                        dispatch(updateProspectVerified({ id: prospect._id }))
                      }
                      removeProspectVerified={() =>
                        dispatch(removeProspectVerified({ id: prospect._id }))
                      }
                      updateProspectWrongNumber={() =>
                        dispatch(
                          updateProspectWrongNumber({ id: prospect._id })
                        )
                      }
                      removeProspectWrongNumber={() =>
                        dispatch(
                          removeProspectWrongNumber({ id: prospect._id })
                        )
                      }
                      updateProspectQualified={() =>
                        dispatch(updateProspectQualified({ id: prospect._id }))
                      }
                      removeProspectQualified={() =>
                        dispatch(removeProspectQualified({ id: prospect._id }))
                      }
                      updateProspectPriority={() =>
                        dispatch(updateProspectPriority({ id: prospect._id }))
                      }
                      removeProspectPriority={() =>
                        dispatch(removeProspectPriority({ id: prospect._id }))
                      }
                      socket={socket}
                      ongGetListUnreadMessages={ongGetListUnreadMessages}
                    />
                  )
                }
              })()}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MessageUnread
