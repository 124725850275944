import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"

// Custom Scrollbar
import SimpleBar from "simplebar-react"

// import images
import servicesIcon1 from "../assets/images/services-icon/01.png"
import servicesIcon2 from "../assets/images/services-icon/02.png"
import servicesIcon3 from "../assets/images/services-icon/03.png"
import servicesIcon4 from "../assets/images/services-icon/04.png"
import user2 from "../assets/images/users/user-2.jpg"
import user3 from "../assets/images/users/user-3.jpg"
import user4 from "../assets/images/users/user-4.jpg"
import user5 from "../assets/images/users/user-5.jpg"
import user6 from "../assets/images/users/user-6.jpg"
import smimg1 from "../assets/images/small/img-1.jpg"
import smimg2 from "../assets/images/small/img-2.jpg"
import axios from "axios"

// Charts
import LineAreaChart from "./AllCharts/apex/lineareachart"
import RadialChart from "./AllCharts/apex/apexdonut"
import Apexdonut from "./AllCharts/apex/apexdonut1"
import SparkLine from "./AllCharts/sparkline/sparkline"
import SparkLine1 from "./AllCharts/sparkline/sparkline1"
import Salesdonut from "./AllCharts/apex/salesdonut"

import ic1 from "../assets/images/m1.svg"
import ic2 from "../assets/images/m2.svg"
import ic3 from "../assets/images/m3.svg"
import ic4 from "../assets/images/m4.svg"

import up from "../assets/images/up.svg"
import down from "../assets/images/down.svg"

import drop from "../assets/images/drop.svg"

import ar from "../assets/images/p-arrow.svg"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      if (typeof text !== "string") {
        if (text?.message && typeof text?.message === "string") {
          toast.error(text?.message)
        } else {
          toast.error("An unexpected error occured")
        }
      } else {
        toast.error(text)
      }
      break

    default:
      break
  }
}

const DashboardMain = props => {
  const [menu, setMenu] = useState(false)
  const toggle = () => {
    setMenu(!menu)
  }

  const [dashboardData, setDashboardData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const getDashboardData = async () => {
    setIsLoading(true)
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/dashboard/getStats",
        config
      )

      if (response?.data) {
        setDashboardData(response?.data?.data)
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getDashboardData()
  }, [])
  return (
    <React.Fragment>
      {isLoading && (
        <div className="spinner-wr">
          <Spinner style={{ color: "#5932ea" }} />
        </div>
      )}
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                {/* <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to TextLaunchPad Dashboard</li>
                </ol> */}
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Dropdown isOpen={menu} toggle={toggle}>
                    <DropdownToggle color="primary" className="main-btn">
                      <i className="mdi mdi-cog me-2"></i> Settings
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag="a" href="#">
                        Action
                      </DropdownItem>
                      <DropdownItem tag="a" href="#">
                        Another action
                      </DropdownItem>
                      <DropdownItem tag="a" href="#">
                        Something else here
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag="a" href="#">
                        Separated link
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </div>
          <div className="row" style={{ "row-gap": "20px" }}>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="c-card ic-card">
                <img src={ic1} alt="" />
                <div className="det">
                  <h3>
                    {isNaN(dashboardData?.unreadCount)
                      ? "-"
                      : dashboardData?.unreadCount}
                  </h3>
                  <p>Unread Messages</p>
                  <Link to="/prospects?unread=true" className="link-btn">
                    <span>View All</span>
                    <img src={ar} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 col-12">
              <div className="c-card ic-card">
                <img src={ic2} alt="" />
                <div className="det">
                  <h3>48</h3>
                  <p>Unanswered</p>
                  <Link to="" className="link-btn">
                    <span>View All</span>
                    <img src={ar} alt="" />
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="c-card ic-card">
                <img src={ic3} alt="" />
                <div className="det">
                  <h3>
                    {isNaN(dashboardData?.remindersCount)
                      ? "-"
                      : dashboardData?.remindersCount}
                  </h3>
                  <p>Reminders</p>
                  <Link to="/reminders" className="link-btn">
                    <span>View All</span>
                    <img src={ar} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="c-card ic-card">
                <img src={ic4} alt="" />
                <div className="det">
                  <h3> 145</h3>
                  <p>Other Stats</p>
                  <Link to="" className="link-btn">
                    <span>View All</span>
                    <img src={ar} alt="" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <div className="c-card ic-card">
                <img src={ic2} alt="" />
                <div className="det">
                  <h3>48</h3>
                  <p>Other Stats</p>
                  <Link to="" className="link-btn">
                    <span>View All</span>
                    <img src={ar} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-7 col-12">
              <div className="c-card">
                <div className="d-flex justify-content-between mb-5">
                  <h3>Reports</h3>
                </div>
                <LineAreaChart />
              </div>
            </div>
            <div className="col-lg-5 col-12">
              <div className="c-card mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h3 className="head">
                    Active
                    <br className="d-none d-sm-block" /> Conversations
                  </h3>
                  <div className="l-select-wrap">
                    <select name="" id="">
                      <option value="">This Week</option>
                      <option value="">This Month</option>
                    </select>
                    <img src={drop} alt="" />
                  </div>
                </div>
                <h3>55</h3>
                <div className="d-flex align-items-center g-suc gap-2">
                  <img src={up} alt="" />
                  <p>
                    <span>15</span> Top From Last Week
                  </p>
                </div>
              </div>
              <div className="c-card mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h3 className="head">
                    Active
                    <br className="d-none d-sm-block" /> Conversations
                  </h3>
                  <div className="l-select-wrap">
                    <select name="" id="">
                      <option value="">This Week</option>
                      <option value="">This Month</option>
                    </select>
                    <img src={drop} alt="" />
                  </div>
                </div>
                <h3>
                  189 <span>min</span>
                </h3>
                <div className="d-flex align-items-center g-suc gap-2 er">
                  <img src={down} alt="" />
                  <p>
                    <span>120</span> Top From Last Week
                  </p>
                </div>
              </div>
              <div className="c-card">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h3 className="head">
                    Active
                    <br className="d-none d-sm-block" /> Conversations
                  </h3>
                  <div className="l-select-wrap">
                    <select name="" id="">
                      <option value="">This Week</option>
                      <option value="">This Month</option>
                    </select>
                    <img src={drop} alt="" />
                  </div>
                </div>
                <h3>55</h3>
                <div className="d-flex align-items-center g-suc gap-2">
                  <img src={up} alt="" />
                  <p>
                    <span>15</span> Top From Last Week
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="c-card">
                <div className="d-flex align-items-center flex-wrap gap-3">
                  <h3>Top Campaigns </h3>
                </div>
                <div className="res-table mt-4">
                  <table className="table c-table">
                    <thead>
                      <tr>
                        <th>Tracking no</th>
                        <th>Campaign Name</th>
                        <th>Start Date</th>
                        <th>Total Order</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#876364</td>
                        <td>Camera Lens</td>
                        <td>24 Jun 2023</td>
                        <td>
                          <div className="st-tag pending">Pending</div>
                        </td>
                        <td>$1,46,660</td>
                      </tr>
                      <tr>
                        <td>#876364</td>
                        <td>Camera Lens</td>
                        <td>24 Jun 2023</td>
                        <td>
                          <div className="st-tag active">Active</div>
                        </td>
                        <td>$1,46,660</td>
                      </tr>
                      <tr>
                        <td>#876364</td>
                        <td>Camera Lens</td>
                        <td>24 Jun 2023</td>
                        <td>
                          <div className="st-tag removed">Removed</div>
                        </td>
                        <td>$1,46,660</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(DashboardMain)
