import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Modal, FormGroup, Button, Spinner,BreadcrumbItem } from "reactstrap"
import MetaTags from "react-meta-tags"
import axios from "axios"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import { Link, withRouter } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"
import DataTable from "react-data-table-component"
import "chartist/dist/scss/chartist.scss"
import moment from "moment"
//i18n
import { withTranslation } from "react-i18next"

const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      if (typeof text !== "string") {
        if (text?.message && typeof text?.message === "string") {
          toast.error(text?.message)
        } else {
          toast.error("An unexpected error occured")
        }
      } else {
        toast.error(text)
      }
      break

    default:
      break
  }
}

const Reminders = props => {
  const [reminders, setReminders] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [totalReminder, setTotalReminder] = useState()

  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const getReminders = async () => {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/reminders/pullReminders",
        config
      )

      if (response?.data) {
        setReminders(response?.data?.data?.reminders)
        setIsLoading(false)
        setTotalReminder(response?.data?.data?.totalCount)
      }
    } catch (err) {
      notify("error", err)
      setIsLoading(false)
    }
  }

  const handleDeleteReminder = async id => {
    setIsLoading(true)
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT + `/api/reminders/deleteReminder/${id}`,
        config
      )

      if (response?.data) {
        setIsLoading(false)
        getReminders()
        notify("success", "Reminder marked as completed successfully")
      }
    } catch (err) {
      setIsLoading(false)
      notify("error", err)
    }
  }

  const remindersData =
    reminders && reminders.length
      ? reminders?.map((item, index) => ({
          prospect: (
            <Link
              to={`/prospect/${item?.prospect._id}`}
              color={"outline-danger"}
              className={"has-icon"}
            >
              {item?.prospect?.first_name + " " + item?.prospect?.last_name}
            </Link>
          ),
          type: item.type,
          note: <span className="reminder-table-note">{item.note}</span>,
          date: (
            <span>{moment.utc(item.date).local().format("MM-DD-YYYY")}</span>
          ),

          markAsComplete: (
            <Button
              key={index}
              onClick={() => {
                handleDeleteReminder(item._id)
              }}
              className={"has-icon me-2"}
              color={"outline-primary"}
            >
              <i className="fas fa-check"></i>
            </Button>
          ),
          // viewProspect: (

          //     <Button
          //     key={index}
          //     onClick={() => {
          //       handleDeleteReminder(item._id)
          //     }}
          //     className={"has-icon me-2"}
          //     color={"outline-primary"}
          //   >
          //     <i className="fas fa-eye"></i>
          //   </Button>

          // ),
        }))
      : []

  const remindersColumns = [
    {
      name: "Name",
      selector: "prospect",
      minWidth: "200px",
      maxWidth: "200px",
      
    },
    {
      name: "Set For",
      selector: "date",
      minWidth: "170px",
      maxWidth: "170px",
    },
    {
      name: "Note",
      selector: "note",
      // minWidth: "400px",
      // maxWidth: "800px",
      grow: 2,
    },

    {
      name: "Action",
      selector: "markAsComplete",
      maxWidth: "100px",
    },
  ]

  const customStyles = {
    table: {
      width: "100%", // Set the overall table width to 100% to cover the screen
    },
  }

  useEffect(() => {
    getReminders()
  }, [])
  return (
    <React.Fragment>
      {isLoading && (
        <div className="spinner-wr">
          <Spinner style={{ color: "#5932ea" }} />
        </div>
      )}
      <Toaster />

      <div className="page-content">
        <MetaTags>
          <title>Reminders | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Toaster />

          <Row className="align-items-center breadcrumb-box mb-5">  
            <div className="col-md-8 col-12">
              <div className="page-title-box">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                {totalReminder ? ` ${totalReminder} ` : null}
                {" "}
                  Reminders
                  
                </h4>
                {/* <span style={{ display: "inline", color: "#8a42e2" }}>
                   {totalReminder ? `( ${totalReminder} )` : null}
                </span> */}
                {/* <span>
                    {totalReminder ? `( ${totalReminder} )` : null}

                  </span> */}
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  {/* <BreadcrumbItem>Notifications</BreadcrumbItem> */}
                  <BreadcrumbItem active>Reminders</BreadcrumbItem>
                </ol>
              </div>
            </div>
          </Row>
          <DataTable
            // title="Campaign Metrics"
            columns={remindersColumns}
            data={remindersData}
            fixedHeader
            pagination
            className="c-table"
            customStyles={customStyles}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
export default withTranslation()(Reminders)
