import PropTypes, { func } from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  Modal,
  DropdownItem,
  DropdownMenu,
  Alert,
  Spinner,
  BreadcrumbItem,
} from "reactstrap"
import { Link, Redirect } from "react-router-dom"
import axios from "axios"

import applyIcon from "../../assets/images/apply-icon.svg"
import trashIcon from "../../assets/images/trash-icon.svg"
import purplePlus from "../../assets/images/purple-plus.svg"
import whitePlus from "../../assets/images/white-plus.svg"

import greyCheck from "../../assets/images/grey-check.svg"
import greenCheck from "../../assets/images/green-check.svg"
import toast, { Toaster } from "react-hot-toast"

import warningError from "../../assets/images/warning-error.svg"

import warningIcon from "../../assets/images/warning.svg"
import successIcon from "../../assets/images/success.svg"
import errorIcon from "../../assets/images/error.svg"
import Select from "react-select"
import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"

const AddTemplate = props => {
  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  const defaultObjSchema = {
    day: 1,
    message: "",
    showTextSpinner: false,
    messageCursorPosition: 0,
    isOptOutOpen: false,
  }

  const tags = [
    "[@firstName]",
    "[@lastName]",
    "[@street]",
    "[@city]",
    "[@state]",
    "[@zip]",
    "[@custom]",
    "[@companyName]",
    "[@repNameAlias]",
  ]
  const [isLoading, setIsLoading] = useState(false)

  const [menu, setMenu] = useState(false)
  const [reloadKey, setReloadKey] = useState(Math.random())
  const [dataArr, setDataArr] = useState([defaultObjSchema])
  const [negativeKeywords, setNegativeKeywords] = useState([])
  const [optOuts, setOptOuts] = useState([])
  const [templateName, setTemplateName] = useState("")
  const [activeIndex, setActiveIndex] = useState(0)
  const [categories, setCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [validation, setValidation] = useState(null)
  const messageTextAreaRef = useRef(null)
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })

  const submitTemplate = async () => {
    setIsLoading(true)
    const payload = {
      name: templateName,
      category: selectedCategory._id,
      message: dataArr[0]?.message,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/template/createTemplate",
        payload,
        config
      )
      if (response.data) {
        setIsLoading(false)
        notify("success", "Template created successfully!")
        setTimeout(() => {
          setShouldRedirect({
            state: true,
            path: `/templates`,
          })
        }, 1500)
      }
    } catch (error) {
      setIsLoading(false)
      notify("error", error?.response?.data?.message)
    }
  }

  const toggle = () => {
    setMenu(!menu)
  }
  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occured")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }
  const getNegativeKeywords = async () => {
    setIsLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/listNegativeKeywords/",
      config
    )
    if (response.data) {
      setNegativeKeywords(response.data.data)
      setIsLoading(false)
    }
  }

  const getOptOuts = async () => {
    setIsLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullOptOut",
      config
    )
    if (response.data) {
      setOptOuts(response.data.data)
      setIsLoading(false)
    }
  }

  const getCategories = async () => {
    setIsLoading(true)
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/template/pullCategories",
      config
    )
    if (response) {
      setCategories(response.data.data)
      setIsLoading(false)
    }
  }

  const addTextSpinnerHandler = index => {
    const tempArr = [...dataArr]
    tempArr[index].showTextSpinner = true
    setDataArr([...tempArr])
    setReloadKey(Math.random())
  }

  function addSpinnerTextHandler(arr, index) {
    let tempArr = [...dataArr]
    let obj = tempArr[index]
    let str = `[${arr.join("/")}]`
    str =
      obj.message.slice(0, obj.messageCursorPosition) +
      str +
      obj.message.slice(obj.messageCursorPosition)

    // str = obj.message + str

    tempArr[index].message = str
    tempArr[index].showTextSpinner = false
    tempArr[index].messageCursorPosition = str.length
    setDataArr([...tempArr])
    setReloadKey(Math.random())
    // this.setState({
    //   message: str,
    //   textSpinnerCount: this.getTextSpinnerCount(str),
    //   showSpinner: false,
    //   validationReloadKey: Math.random(),
    // })
  }

  function closeSpinnerTextHandler(index) {
    let tempArr = [...dataArr]
    tempArr[index].showTextSpinner = false
    setDataArr([...tempArr])
    setReloadKey(Math.random())
  }

  function handleDataChange(index, key, val) {
    let tempArr = [...dataArr]
    tempArr[index][key] = val
    setDataArr([...tempArr])
    // setReloadKey()
  }

  function updateMessageCursorPosition(index, position) {
    let tempArr = [...dataArr]
    tempArr[index].messageCursorPosition = position
    setDataArr([...tempArr])
    // setReloadKey(Math.random())
  }

  function addTag(tag, index) {
    let tempArr = [...dataArr]
    const crrTextareaRef = messageTextAreaRef.current
    if (
      crrTextareaRef.selectionStart !== undefined &&
      crrTextareaRef.selectionEnd !== undefined
    ) {
      const start = crrTextareaRef.selectionStart
      const end = crrTextareaRef.selectionEnd
      tempArr[index].message =
        tempArr[index].message.substring(0, start) +
        tag +
        tempArr[index].message.substring(end)
    } else {
      tempArr[index].message =
        tempArr[index].message?.slice(0, tempArr[index].messageCursorPosition) +
        tag +
        tempArr[index].message?.slice(tempArr[index].messageCursorPosition)
    }

    setDataArr([...tempArr])
    setReloadKey(Math.random())
    updateMessageCursorPosition(index, tempArr[index].message.length)

    // this.setState({
    //   message:
    //     this.state.message?.slice(0, this.state.messageCursorPosition) +
  }

  function getMessageCharCount(mssg = "") {
    const textSpinners = []
    const mssgWithoutTextSpinner = mssg.replace(
      /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g,
      match => {
        textSpinners.push(match)
        return ""
      }
    )
    const textSpinnerCharCounts = sumArray(
      textSpinners.map(item => {
        let arr = item.replace("[", "").replace("]", "").split("/")
        let maxLength = 0
        for (let i = 0; i < arr.length; i++) {
          const str = arr[i]
          const length = str.length
          if (length > maxLength) {
            maxLength = length
          }
        }
        return maxLength
      })
    )
    return mssgWithoutTextSpinner?.length + textSpinnerCharCounts
  }

  function sumArray(arr) {
    let sum = 0
    for (let i = 0; i < arr.length; i++) {
      const num = arr[i]
      if (typeof num === "number") {
        sum += num
      }
    }
    return sum
  }

  function getTextSpinnerCount(str = "") {
    const pattern = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    const matches = str.match(pattern)
    if (matches) {
      return matches?.length
    } else {
      return 0
    }
  }

  function isTextSpinnerHasMinElement(mssg = "", min = 2) {
    if (!mssg.length) {
      return false
    }
    const pattern = /\[[^\]]*\/[^\]]*(?:\/[^\]]*)*\]/g
    const matches = mssg.match(pattern)
    let flag = true
    if (matches) {
      matches.forEach(item => {
        if (item.split("/").length < min) flag = false
      })
    }
    return flag
  }

  function checkNegativeKeywords(val = "") {
    const found = []
    const valArr = val?.split(" ")?.map(item => item.toLowerCase())
    for (const word of negativeKeywords) {
      if (valArr.includes(word?.toLowerCase())) {
        found.push(word)
      }
    }
    if (found && found.length) {
      // this.setState({
      //   negativeKeywords: found,
      //   messageError: `Negative Kewords Found: ${found.join(", ")}`,
      // })
      return false
    } else {
      // this.setState({
      //   negativeKeywords: found,
      //   messageError: "",
      // })
      return true
    }
  }

  function getMergeFieldCount(str = "") {
    const pattern = /\[\@[A-Za-z0-9]+\]/g
    const matches = str.match(pattern)
    if (matches) {
      return matches?.length
    } else {
      return 0
    }
  }

  function isMergeFieldValid(mssg = "") {
    const pattern = /\[\@[A-Za-z0-9]+\]/g
    const matches = mssg.match(pattern)
    if (matches) {
      return matches.every(item => tags.includes(item))
    } else {
      return true
    }
  }

  function isMessageComplete(mssg = "") {
    if (
      validation?.messageCharCount &&
      getMessageCharCount(mssg) < validation?.messageCharCount
    )
      return false
    if (
      validation?.minTextSpinner &&
      getTextSpinnerCount(mssg) < validation?.minTextSpinner
    )
      return false
    if (validation?.isNoNegativeKeywordRequired && !checkNegativeKeywords(mssg))
      return false
    if (validation?.isMergeFieldRequired && !getMergeFieldCount(mssg))
      return false
    if (validation?.isMergeFieldRequired && !getMergeFieldCount(mssg))
      return false
    if (validation?.validMergeFieldRequired && !isMergeFieldValid(mssg))
      return false
    return true
  }

  function checkValidSubmit() {
    let isValid = true
    if (!(templateName && templateName.trim().length) || !selectedCategory) {
      return false
    }
    dataArr.forEach(item => {
      if (!isMessageComplete(item.message)) {
        isValid = false
      }
    })
    return isValid
  }

  function checkDuplicateDays(arr) {
    let tempArr = arr.map(item => {
      return item.day
    })
    return !(tempArr.length !== new Set(tempArr).size)
  }

  function minimizeAll() {
    setActiveIndex(null)
  }
  function isPresent(word, string) {
    return string
      ?.split(" ")
      ?.map(item => item.toLowerCase())
      ?.includes(word)
  }
  function findVariations(str = "") {
    const regex = /\[([^\]]+)\]/g
    const matches = Array.from(str.matchAll(regex))
    let totalVariations = 1
    matches.forEach(match => {
      const options = match[1].split("/")
      totalVariations *= options?.length
    })
    return totalVariations
  }
  useEffect(() => {
    getNegativeKeywords()
    getOptOuts()
    getCategories()
  }, [])

  const [modal_center, set_modal_center] = useState(true)

  const tog_center = () => {
    set_modal_center(state => !state)
  }

  return (
    <React.Fragment>
      <Toaster />

      {/* succes alert popup */}
      {/* <Modal
        isOpen={modal_center}
        toggle={() => tog_center}
        backdrop="static"
        centered={true}
        className="alert-modal"
      >
          <div className="modal-body">
              <div className="alert-wrapper">
                <div className="alert-img">
                  <img src={warningIcon} alt="" />
                  <img src={errorIcon} alt="" />
                  <img src={successIcon} alt="" />
                </div>
                <div className="alert-con">
                  <h3>Heading Goes Here</h3>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                <div className="btn-flex">
                  <button className="main-btn" onClick={() => {set_modal_center(false);}}>
                    <span>Cancel</span>
                  </button>
                  <button className="main-btn secondary-btn">
                    <span>Ok</span>
                  </button>
                </div>
              </div>
          </div>
      </Modal> */}
      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Add Template | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          {isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}
          <Row className="align-items-center justify-content-between">
            <Col lg={6} md={6} sm={12}>
              <div className="page-title-box pb-0">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                  Add Template
                </h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Templates</BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col md={12} className={"pt-2"}>
              <span
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                }
              ></span>
            </Col>
          </Row>
          <div className="drip-aut-main">
            <div className="row">
              <div className="col-lg-8">
                <ul className="d-aut-wrapper">
                  {/* <li className="d-aut-el">
                    <div className="form-group style-2">
                      <div className="inp-grp">
                        <div className="row">
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <div className="inp-grp">
                                <input
                                  type="text"
                                  placeholder="Template Name"
                                  value={templateName}
                                  onChange={e =>
                                    setTemplateName(e.target.value)
                                  }
                                  className="primary-border bg-white"
                                  style={{
                                    border: "1.5px solid #5932EA !important",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-sm-12">
                            <Select
                              value={selectedCategory}
                              onChange={e => {
                                const category = categories.filter(
                                  item => item._id === e.value
                                )
                                const categoryOption =
                                  category && category.length
                                    ? {
                                        label: category[0]?.name,
                                        value: category[0]?._id,
                                        ...category[0],
                                      }
                                    : null
                                setSelectedCategory(categoryOption)
                                console.log(category[0].validations)
                                setValidation(category[0]?.validations)
                              }}
                              options={categories?.map(item => ({
                                label: item?.name,
                                value: item?._id,
                              }))}
                              placeholder="Select Category"
                              menuPortalTarget={document.body}
                              styles={{
                                control: base => ({
                                  ...base,
                                  borderRadius: "10px",
                                  paddingLeft: "10px",
                                  border: "1.5px solid #5932EA",
                                  color: "#5932EA",
                                  height: "52px",
                                }),
                                menuPortal: base => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </li> */}
                  <span key={reloadKey}>
                    {dataArr &&
                      dataArr.map((item, index) => {
                        return (
                          <span key={index}>
                            <li key={index}>
                              {activeIndex === index ? (
                                <div className="d-aut-main">
                                  <span className="d-num-in">
                                    Variations:{" "}
                                    <span className="color-primary">
                                      {findVariations(item.message)}
                                    </span>
                                  </span>
                                  <div className="d-aut-body">
                                    {item.showTextSpinner ? (
                                      <TextSpinner
                                        addSpinnerTextHandler={
                                          addSpinnerTextHandler
                                        }
                                        closeSpinnerTextHandler={
                                          closeSpinnerTextHandler
                                        }
                                        negativeKeywords={negativeKeywords}
                                        mssgIndex={index}
                                        validation={validation}
                                      />
                                    ) : (
                                      <>
                                        <div className="row">
                                          <div className="col-lg-6 col-sm-12">
                                            <div className="form-group">
                                              <div className="inp-grp">
                                                <input
                                                  type="text"
                                                  placeholder="Template Name"
                                                  value={templateName}
                                                  onChange={e =>
                                                    setTemplateName(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="primary-border bg-white"
                                                  style={{
                                                    border:
                                                      "1.5px solid #5932EA !important",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6 col-sm-12">
                                            <Select
                                              value={selectedCategory}
                                              onChange={e => {
                                                const category =
                                                  categories.filter(
                                                    item => item._id === e.value
                                                  )
                                                const categoryOption =
                                                  category && category.length
                                                    ? {
                                                        label:
                                                          category[0]?.name,
                                                        value: category[0]?._id,
                                                        ...category[0],
                                                      }
                                                    : null
                                                setSelectedCategory(
                                                  categoryOption
                                                )
                                                console.log(
                                                  category[0].validations
                                                )
                                                setValidation(
                                                  category[0]?.validations
                                                )
                                              }}
                                              options={categories?.map(
                                                item => ({
                                                  label: item?.name,
                                                  value: item?._id,
                                                })
                                              )}
                                              placeholder="Select Category"
                                              menuPortalTarget={document.body}
                                              styles={{
                                                control: base => ({
                                                  ...base,
                                                  borderRadius: "10px",
                                                  paddingLeft: "10px",
                                                  border: "1.5px solid #5932EA",
                                                  color: "#5932EA",
                                                  height: "50px",
                                                }),
                                                menuPortal: base => ({
                                                  ...base,
                                                  zIndex: 9999,
                                                }),
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <textarea
                                          ref={messageTextAreaRef}
                                          name=""
                                          id=""
                                          rows="5"
                                          className="mt-2"
                                          defaultValue={item.message}
                                          onChange={e => {
                                            handleDataChange(
                                              index,
                                              "message",
                                              e.target.value
                                            )
                                            updateMessageCursorPosition(
                                              index,
                                              e.target.selectionStart
                                            )
                                          }}
                                          onClick={e => {
                                            updateMessageCursorPosition(
                                              index,
                                              e.target.selectionStart
                                            )
                                          }}
                                        ></textarea>
                                        <span
                                          style={{
                                            float: "right",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {getMessageCharCount(item.message) <=
                                          160 ? (
                                            <i
                                              className="fas fa-check-circle fa-sm me-1"
                                              style={{ color: "Green" }}
                                            ></i>
                                          ) : null}
                                          {getMessageCharCount(item.message) <=
                                            320 &&
                                          getMessageCharCount(item.message) >
                                            160 ? (
                                            <i
                                              className="fas fa-exclamation-circle fa-sm me-1"
                                              style={{ color: "orange" }}
                                            ></i>
                                          ) : null}
                                          {getMessageCharCount(item.message) >
                                          320 ? (
                                            <i
                                              className="fas fa-times-circle fa-sm me-1"
                                              style={{ color: "red" }}
                                            ></i>
                                          ) : null}
                                          {getMessageCharCount(item.message)
                                            ? getMessageCharCount(item.message)
                                            : 0}
                                          /320
                                        </span>
                                        <div className="d-aut-ac-wraps ">
                                          <Dropdown
                                            isOpen={false}
                                            toggle={() => {
                                              handleDataChange(
                                                index,
                                                "isMergeFieldOpen",
                                                !item.isMergeFieldOpen
                                              )
                                            }}
                                          >
                                            <DropdownToggle
                                              className="btn "
                                              caret
                                              color={"outline-primary"}
                                            >
                                              Merge Fields
                                              <i className="mdi mdi-chevron-down" />
                                            </DropdownToggle>
                                            <DropdownMenu
                                              className={
                                                item.isMergeFieldOpen
                                                  ? "d-flex flex-column gap-1 p-2"
                                                  : ""
                                              }
                                            >
                                              {tags?.length > 0 &&
                                                tags.map((item, tagIndex) => (
                                                  <DropdownItem
                                                    key={tagIndex}
                                                    type={"button"}
                                                    className="drp-dwn-item"
                                                    onClick={() => {
                                                      addTag(item, index)
                                                    }}
                                                  >
                                                    {item}
                                                  </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                          </Dropdown>

                                          <Dropdown
                                            isOpen={item.isOptOutOpen}
                                            toggle={() => {
                                              handleDataChange(
                                                index,
                                                "isOptOutOpen",
                                                !item.isOptOutOpen
                                              )
                                            }}
                                          >
                                            <DropdownToggle
                                              className="btn "
                                              caret
                                              color={"outline-primary"}
                                            >
                                              OptOut Languages{" "}
                                              <i className="mdi mdi-chevron-down" />
                                            </DropdownToggle>
                                            <DropdownMenu
                                              className={
                                                item.isOptOutOpen
                                                  ? "d-flex flex-column gap-1 p-2"
                                                  : ""
                                              }
                                            >
                                              {optOuts?.length > 0 &&
                                                optOuts.map(item => (
                                                  <DropdownItem
                                                    type={"button"}
                                                    className="drp-dwn-item"
                                                    onClick={e => {
                                                      addTag(
                                                        "[@" + item.title + "]",
                                                        index
                                                      )
                                                    }}
                                                    key={item._id}
                                                  >
                                                    {item.title}
                                                  </DropdownItem>
                                                ))}
                                            </DropdownMenu>
                                          </Dropdown>

                                          <button
                                            onClick={() =>
                                              addTextSpinnerHandler(index)
                                            }
                                          >
                                            <img src={purplePlus} alt="" />
                                            <span>Add Text Spinner</span>
                                          </button>
                                          <button
                                            onClick={() =>
                                              handleDataChange(
                                                index,
                                                "showNegativeKeyword",
                                                !item.showNegativeKeyword
                                              )
                                            }
                                          >
                                            {item.showNegativeKeyword ? (
                                              <i className="fas fa-eye-slash color-primary"></i>
                                            ) : (
                                              <i className="fas fa-eye color-primary"></i>
                                            )}
                                            <span>
                                              {item.showNegativeKeyword
                                                ? "Hide"
                                                : "Show"}{" "}
                                              negative keywords
                                            </span>
                                          </button>
                                        </div>
                                        {item.showNegativeKeyword && (
                                          <table className="d-flex flex-wrap p-0 ">
                                            <tbody>
                                              <tr className="d-flex flex-wrap py-3 gap-2">
                                                <th>Negative Keywords:</th>
                                                {negativeKeywords.map(
                                                  (nk, nkIndex) => (
                                                    <td
                                                      className={`px-1 bg-light rounded-1 ${
                                                        isPresent(
                                                          nk,
                                                          item.message
                                                        )
                                                          ? "badge-soft-danger"
                                                          : "bg-light "
                                                      }`}
                                                      value={nkIndex}
                                                      key={nkIndex}
                                                    >
                                                      {nk}
                                                    </td>
                                                  )
                                                )}
                                              </tr>
                                            </tbody>
                                          </table>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`d-aut-main mb-4 ${
                                    !isMessageComplete(item.message) ||
                                    !(item.day > 0 && item.day < 366)
                                      ? "border-danger"
                                      : "border-info"
                                  }  border-start ps-2 border-2`}
                                  onClick={() => setActiveIndex(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="d-aut-head flex-column align-items-start gap-2">
                                    <span className="text-black small ">
                                      Message {index + 1}
                                    </span>
                                    {!isMessageComplete(item.message) ||
                                    !(item.day > 0 && item.day < 366) ? (
                                      <p className="text-danger d-flex gap-2 align-items-center">
                                        <img
                                          src={warningError}
                                          width={"20px"}
                                          alt=""
                                        />
                                        Message not completed
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                    <p style={{ wordWrap: "anywhere" }}>
                                      {item.message}
                                    </p>
                                    <p
                                      className={
                                        !isMessageComplete(item.message) ||
                                        !(item.day > 0 && item.day < 366)
                                          ? "text-danger"
                                          : "text-info"
                                      }
                                    >
                                      Day {item.day}
                                    </p>
                                  </div>
                                  {/* <div className="d-aut-body">
                                  
                                  </div> */}
                                </div>
                              )}
                            </li>
                          </span>
                        )
                      })}
                  </span>
                  {/* <li className="d-aut-el">
                    <button className="main-btn transparent">
                      <span className="text-black">
                        Message Count:
                        <strong className="text-info"> {dataArr.length}</strong>
                      </span>
                    </button>
                  </li> */}
                  {/* <li className="d-aut-el">
                    <div className="d-flex gap-3">
                      <button
                        className="main-btn"
                        onClick={() => {
                          addMessageHandler()
                        }}
                      >
                        <img src={whitePlus} alt="" />
                        <span>Add More</span>
                      </button>
                    </div>
                  </li> */}

                  <li className="position-static">
                    <div className="d-flex gap-3">
                      <button
                        className={`main-btn mt-3 ${
                          !checkValidSubmit() ? "opacity-50" : ""
                        }`}
                        onClick={() => {
                          submitTemplate()
                        }}
                        disabled={!checkValidSubmit()}
                      >
                        {/* <img src={whitePlus} alt="" /> */}
                        <span>Submit</span>
                      </button>
                      <Link
                        className="main-btn mt-3 secondary-btn"
                        to="/templates"
                      >
                        {/* <img src={whitePlus} alt="" /> */}
                        <span>Cancel</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-12">
                <div className="c-card t-sticky">
                  {!selectedCategory ? (
                    <div className="text-warning text-center">
                      <i
                        className="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{" "}
                      Select Category to see validations
                    </div>
                  ) : selectedCategory?.validations ? (
                    <>
                      {dataArr[activeIndex] ? (
                        <ul className="check-list">
                          {validation?.minChar && (
                            <li
                              className={
                                getMessageCharCount(
                                  dataArr[activeIndex]?.message
                                ) >= validation?.minChar
                                  ? "active"
                                  : ""
                              }
                            >
                              <span>
                                Minimum of {validation?.minChar} characters
                              </span>
                            </li>
                          )}
                          {validation.minTextSpinner && (
                            <li
                              className={
                                getTextSpinnerCount(
                                  dataArr[activeIndex]?.message
                                ) >= validation?.minTextSpinner
                                  ? "active"
                                  : ""
                              }
                            >
                              <span>
                                At least {validation?.minTextSpinner} text
                                spinners
                              </span>
                            </li>
                          )}
                          {validation.minElementInTextSpinner && (
                            <li
                              className={
                                isTextSpinnerHasMinElement(
                                  dataArr[activeIndex]?.message,
                                  validation?.minElementInTextSpinner
                                )
                                  ? "active"
                                  : ""
                              }
                            >
                              <span>
                                Each text spinner must have at least{" "}
                                {validation?.minElementInTextSpinner} elements
                              </span>
                            </li>
                          )}
                          {validation.isNoNegativeKeywordRequired && (
                            <li
                              className={
                                checkNegativeKeywords(
                                  dataArr[activeIndex]?.message
                                )
                                  ? "active"
                                  : false
                              }
                            >
                              <span>
                                Must have no negative/restricted keyword
                              </span>
                            </li>
                          )}
                          {validation?.isMergeFieldRequired && (
                            <li
                              className={
                                getMergeFieldCount(
                                  dataArr[activeIndex]?.message
                                )
                                  ? "active"
                                  : ""
                              }
                            >
                              <span> Must have Merge Field</span>
                            </li>
                          )}

                          {validation?.validMergeFieldRequired && (
                            <li
                              className={
                                isMergeFieldValid(dataArr[activeIndex]?.message)
                                  ? "active"
                                  : ""
                              }
                            >
                              <span> All Merge Fields must be valid</span>
                            </li>
                          )}
                        </ul>
                      ) : (
                        <>
                          <ul className="check-list">
                            <li
                              className={
                                checkDuplicateDays(dataArr) ? "active" : ""
                              }
                            >
                              <span>Days must not be similar</span>
                            </li>
                          </ul>
                          {/* Select Message to see more validations */}
                        </>
                      )}
                    </>
                  ) : (
                    <div className="d-flex text-danger align-items-center mt-2 flex-wrap gap-2">
                      <span className="d-flex gap-2 align-items-center">
                        <i
                          className="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>
                        <span>
                          To see validations, update validation of this category
                          first.
                        </span>
                      </span>
                      <Link
                        to={{
                          pathname: "/templates-setting",
                        }}
                      >
                        Update now{" "}
                        <i className="fa fa-link" aria-hidden="true"></i>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

const TextSpinner = ({
  negativeKeywords,
  addSpinnerTextHandler,
  closeSpinnerTextHandler,
  mssgIndex,
  validation,
}) => {
  const [inputs, setInputs] = useState(["", "", ""])
  const [error, setError] = useState("")

  const inputChangeHandler = (index, val) => {
    const inputArr = [...inputs]
    inputArr[index] = val
    setInputs(inputArr)
  }

  const toggleInput = (operation, index = null) => {
    switch (operation) {
      case "add": {
        setInputs([...inputs, ""])
        break
      }
      case "remove": {
        const inputArr = [...inputs]
        inputArr.splice(index, 1)
        setInputs(inputArr)
        break
      }
      case "delete": {
        setInputs([])
        closeSpinnerTextHandler(mssgIndex)
        break
      }
      case "save": {
        const inputArr = inputs.filter(i => i.trim().length > 0)
        if (
          validation?.minElementInTextSpinner &&
          inputArr.length <= validation?.minElementInTextSpinner
        ) {
          setError(
            `Must have at least ${validation?.minElementInTextSpinner} elements`
          )
        } else if (inputArr.length !== new Set(inputArr).size) {
          setError("Elements must be unique")
        } else {
          const val = inputArr.join(" ")
          const negativeKeywordsFound = negativeKeywords.filter(word =>
            val?.toLowerCase().includes(word?.toLowerCase())
          )
          if (negativeKeywordsFound.length) {
            setError(
              `Negative keywords found: ${negativeKeywordsFound.join(", ")}`
            )
          } else {
            setInputs([])
            addSpinnerTextHandler(inputArr, mssgIndex)
          }
        }
        // addSpinnerTextHandler(inputArr)

        break
      }
      default:
        break
    }
  }

  return (
    <div className="text-spinner-wr py-4 px-2">
      <div className="d-flex flex-wrap gap-2">
        {inputs.map((input, index) => (
          <div key={index} className="d-flex gap-1">
            <input
              value={input}
              placeholder="word or phrase"
              onChange={e => inputChangeHandler(index, e.target.value)}
              className="border-start-0 border-end-0 border-top-0 border-top-1 border me-2 small"
              onKeyDown={e => {
                if (e.keyCode === 13) e.preventDefault()
              }}
            />
            {index >= 3 ? (
              <Button
                className={"has-icon me-1 small text-danger bg-transparent"}
                color={"danger"}
                title="Remove"
                onClick={() => toggleInput("remove", index)}
              >
                <i className="fas fa-times"></i>
              </Button>
            ) : null}
            /
          </div>
        ))}
      </div>
      {inputs && inputs.length ? (
        <div className="d-flex gap-2 p-3">
          <Button
            className={"has-icon me-1 btn-theme-color"}
            color={"success"}
            title="Add"
            onClick={() => {
              toggleInput("add")
            }}
          >
            <i className="fas fa-plus"></i>
          </Button>

          <Button
            className={"has-icon me-1"}
            color={"danger"}
            title="Delete"
            onClick={() => {
              toggleInput("delete")
            }}
          >
            <i className="fas fa-trash-alt"></i>
          </Button>
          <Button
            className={"has-icon me-1 btn-theme-color"}
            color={"success"}
            title="Save"
            onClick={() => {
              toggleInput("save")
            }}
          >
            <i className="fas fa-save"></i>
          </Button>
        </div>
      ) : null}
      {inputs && inputs.length && error ? (
        <div className="text-danger">{error}</div>
      ) : null}
    </div>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(AddTemplate)
