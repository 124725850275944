import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoSm from "../../assets/images/logo-sm.png"
import loginImage from "../../assets/images/login-img.png"
import logoicon from '../../assets/images/logo-icon.svg'
import strechLogo from '../../assets/images/strech-logo.svg'
import strechLogoUpdate from '../../assets/images/logoMainStreched.png'


const Login = props => {
  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    props.loginUser(values, props.history)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | TextLaunchPad.com</title>
      </MetaTags>
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div> */}

      <div className="sec">
        <Row className="justify-content-center m-0 vh-100">
          <Col md={4} lg={4} xl={4} className="d-flex flex-column justify-content-between p-5">
            {/* <Row className="align-items-between"> */}
            {/* <div md={12}>
              <icon></icon>
              <h4 className="text-black">Text Launchpad</h4>
            </div> */}
            <div to="/" className="d-flex justify-content-center align-items-center ">
              <img src={strechLogoUpdate} alt="logo" className="login-logo" />
              {/* <span className="main-logo-text">TextLaunchpad</span> */}
            </div>
            <div md={12}>
              <img src={loginImage} className="img-fluid" />
            </div>
            <div md={12}>
              <h4>Welcome Back</h4>
              <p>Start interacting with your prospects now!</p>
            </div>
            {/* </Row> */}
            {/* <Row className="vh-20">
              <icon></icon>
              <h4 className="text-black">Text Launchpad</h4>
            </Row> */}
            {/* <Row className="vh-60">
              <img src={loginImage} />
            </Row> */}

          </Col>

          <Col md={8} lg={8} xl={8} className="d-flex flex-column justify-content-center bg-white p-5">
            <Row className="d-flex justify-content-center align-items-center flex-column">
              <Col xs={12} sm={10} md={8} lg={7} xl={6}>
                <div className="bg-white d-flex align-start">
                  <div className="text-primary text-center p-0">
                    <h5 className="text-black font-size-20 d-flex align-start">Welcome Back !</h5>
                    <p className="text-black-50">
                      Sign in to continue to TextLaunchPad.com.
                    </p>
                    {/* <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link> */}
                  </div>
                </div>
                <AvForm
                  className="form-horizontal mt-4"
                  onValidSubmit={(e, v) => {
                    handleValidSubmit(e, v)
                  }}
                >
                  {props.error && typeof props.error === "string" ? (
                    <Alert color="danger">{props.error}</Alert>
                  ) : null}

                  {props.error && typeof props.error === "object" ? (
                    <Alert color="danger">
                      {props.error.response.data.error}
                    </Alert>
                  ) : null}

                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      validate={{
                        email: {
                          value: true,
                          errorMessage: "Please enter valid email address",
                        },
                      }}
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Password"
                      type="password"
                      required
                      placeholder="Enter Password"
                    />
                  </div>

                  <Row className="mb-3 align-items-center">
                    <Col sm={6}>
                      <div className="form-check pt-2">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>
                    </Col>
                    <Col sm={6} className="text-end">
                      {/* <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                          >
                            Log In
                          </button> */}
                      <div className="">
                        <Link to="/forgot-password">
                          <i className="mdi mdi-lock"></i> Forgot your
                          password?
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-0 row">
                    <Col md={12}>
                      <button
                        className="main-btn block-btn text-white rounded-2"
                        type="submit"
                      >
                        Log In
                      </button>
                    </Col>
                    {/* <div className="col-12 mt-4">
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div> */}

                  </Row>
                </AvForm>
              </Col>

            </Row>
            {/* <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} TextLaunchPad. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by TechXperts
                </p>
              </div> */}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
