import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import axios from "axios"
import ReactPaginate from "react-paginate"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  CardHeader,
  BreadcrumbItem,
  Nav,
  NavItem,
  Button,
  Spinner,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import toast, { Toaster } from "react-hot-toast"

import DataTable from "react-data-table-component"
// import 'react-data-table-component/styles.css';

import {
  getCountStats,
  downloadStats,
  getCampaign,
  getLeadStages,
  getCampaignMetrics,
  campaignFilterProspects,
} from "../../store/campaigns/actions"

import threedotIcon from "../../assets/images/threedot.svg"

import ic1 from "../../assets/images/icc1.svg"
import ic2 from "../../assets/images/icc2.svg"
import ic3 from "../../assets/images/icc3.svg"
import ic4 from "../../assets/images/icc4.svg"
import ic5 from "../../assets/images/icc5.svg"
import ic6 from "../../assets/images/icc6.svg"
import wrongNumberIcon from "../../assets/images/wrongNumberIcon.svg"
import downloadIcon from "../../assets/images/download.svg"

import dripIcon from "../../assets/images/dripIcon.svg"
import warningIcon from "../../assets/images/warningIcon.svg"
import crossIcon from "../../assets/images/crossIcon.svg"

import RadialChart from "../AllCharts/apex/apexdonut1"

import redWarning from "../../assets/images/redWarning.svg"

import ApexChartSemiDonut from "../AllCharts/apex/apexsemidonut"

import moment from "moment"
import CampaignHeader from "./CampaignHeader"

import "chartist/dist/scss/chartist.scss"
import close from "../../assets/images/close.svg"
import rightArrow from "../../assets/images/right-arrow.svg"
import qualifiedLead from "../../assets/images/qualifiedLead.svg"

class Metrics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      campaign_id: null,
      id: this.props.match.params.id ? this.props.match.params.id : null,
      chartData: [],
      reloadKey: Math.random(),
      isLoading: false,
      selectCampaignData: {
        data: [],
        page: 1,
        limit: 10,
        totalCount: 0,
      },
    }
  }

  getData(id = this.props.match.params.id) {
    // let id = this.props.match.params.id
    if (id) {
      this.state.campaign_id = id
      this.props.getCampaign(id)
      this.props.getCountStats(id)
      this.props.getCampaignMetrics(id)
      // this.props.getCampaigns()
    }
  }

  async getCampaigns(
    page = this.state.selectCampaignData.page,
    limit = this.state.selectCampaignData.limit
  ) {
    this.setState({ isLoading: true })
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const queryParams = new URLSearchParams({ page, limit })
      const response = await axios.get(
        process.env.REACT_APP_PORT +
          "/api/campaign/getAllCampaigns?" +
          queryParams,
        config
      )
      this.setState(prevState => ({
        selectCampaignData: {
          ...prevState.selectCampaignData,
          data: response.data?.data?.campaigns,
          totalCount: response.data?.data?.totalCount,
        },
        isLoading: false,
      }))
    } catch (err) {
      this.setState({ isLoading: false })
      this.notify("error", err)
    }
  }

  calculatePercentage(nr, dr, dec = 0) {
    if (dr == 0 || !dr || !nr) {
      return 0
    }
    return +((+nr / +dr) * 100).toFixed(dec)
  }
  toggleCampaignList() {
    this.state.show = !this.state.show
  }

  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occured")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.countstatus && !this.state.chartData.length) {
      const chartArr = [
        this.calculatePercentage(
          this.props.countstatus.hotLeadsCount,
          this.props.countstatus.phonenumbers
        ),
        this.calculatePercentage(
          this.props.countstatus.responseReceivedCount,
          this.props.countstatus.phonenumbers
        ),
        this.calculatePercentage(
          this.props.countstatus.initialMessageSentCount,
          this.props.countstatus.phonenumbers
        ),
        this.calculatePercentage(
          this.props.countstatus.dripCount,
          this.props.countstatus.phonenumbers
        ),
      ]
      this.setState({ chartData: [...chartArr], isLoading: false })
    }

    if (!prevProps.successMsg && this.props.successMsg) {
      this.notify("success", this.props.successMsg)
    } else if (
      this.props.apiError &&
      typeof this.props.apiError === "string" &&
      !prevProps.apiError
    ) {
      this.notify("error", this.props.apiError)
    } else if (
      !prevProps.apiError &&
      this.props.apiError &&
      typeof this.props.apiError === "object"
    ) {
      if (this.props.apiError.response) {
        this.notify("error", this.props.apiError.response.data.error)
      } else {
        this.notify("error", this.props.apiError.message)
      }
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    this.getCampaigns()
    this.getData()
    this.props.getLeadStages()
  }

  render() {
    const batchesColumns = [
      {
        name: "Batch",
        selector: "batch",
      },
      {
        name: "Type",
        selector: "type",
      },
      {
        name: "Sent",
        selector: "sent",
      },
      {
        name: "Delivered",
        selector: "delivered",
      },
      {
        name: "Delivery Rate",
        selector: "deliveryRate",
      },
      {
        name: "Response",
        selector: "response",
      },
      {
        name: "Response Rate",
        selector: "responseRate",
      },
      {
        name: "Last Sent",
        selector: "lastSent",
        minWidth: "200px",
      },
      {
        name: "Last Received",
        selector: "lastReceived",
        minWidth: "200px",
      },
    ]

    const batchesData = []
    this.props.campaign_metrics.map((item, index) => {
      if (item.sent || item.is_active) {
        batchesData.push({
          batch: item.name,
          type: (
            <span style={{ textTransform: "capitalize" }}>{item.type}</span>
          ),
          sent: item.sent,
          delivered: item.delivered,
          deliveryRate: +item.sent
            ? ((+item.delivered / +item.sent) * 100).toFixed(1) + "%"
            : "-",
          response: `🔥 ${item.received}`,
          responseRate: (
            <div className="st-tag pending">
              <span>
                {+item.received
                  ? ((+item.received / +item.sent) * 100).toFixed(1) + "%"
                  : "-"}
              </span>
            </div>
          ),
          lastSent: moment
            .utc(item.createdAt)
            .local()
            .format("MM-DD-YYYY | hh:mm A"),
          lastReceived: moment
            .utc(item.date_end)
            .local()
            .format("MM-DD-YYYY | hh:mm A"),
        })
      }
    })

    const prospectsColumns = [
      {
        name: "Name",
        selector: "name",
      },
      {
        name: "Phone",
        selector: "phone",
      },
      {
        name: "Status",
        selector: "status",
      },
      {
        name: "Last Message",
        selector: "lastMessage",
      },
    ]

    const prospectsData =
      this.props?.campaign?.prospects &&
      this.props.campaign.prospects?.map((item, index) => ({
        name: item.first_name + " " + item.last_name,
        phone: item.phone,
        status: item.dnc ? (
          <div>
            <i className="fas fa-phone-slash text-danger" title="DNC" text />
            <span> DNC</span>
            {/* <img src={ic3} alt="" /> */}
          </div>
        ) : item.isDripAdded ? (
          <div>
            <img src={dripIcon} alt="" title="Drip" />
            <span> Drip</span>
          </div>
        ) : item.isPriority ? (
          <div>
            <i className="fas fa-bolt ps-2" title="Priority" />
            <span> Priority</span>
          </div>
        ) : (
          "No Status"
        ),
        lastMessage: moment
          .utc(item.lastsmssend)
          .local()
          .format("MM-DD-YYYY | hh:mm A"),
      }))

    return (
      <React.Fragment key={this.state.reloadKey}>
        {this.state.show && (
          <>
            <div className="side-overlay"></div>

            <aside className={`f-side-bar ${this.state.show ? "show" : ""}`}>
              <div className="f-sidebar-header">
                <h3>Select Campaign</h3>
                <button>
                  <img
                    src={close}
                    alt=""
                    onClick={() => {
                      this.setState({ show: false })
                    }}
                  />
                </button>
              </div>
              <div className="f-sidebar-body">
                {/* <div className="form-group">
                  <div className="inp-grp">
                    <input type="text" placeholder="Search Campaign" />
                  </div>
                </div> */}
                <div className="select-campaign-list">
                  <ul>
                    <li>
                      <span>Created</span>
                      <span>Title</span>
                    </li>
                    {this.state.selectCampaignData?.data &&
                      this.state.selectCampaignData?.data?.map(
                        (item, index) => {
                          return (
                            <Link
                              key={index}
                              onClick={() => {
                                this.getData(item._id)
                                this.props.getLeadStages()
                                this.setState({
                                  chartData: [],
                                  show: false,
                                  reloadKey: Math.random(),
                                  isLoading: true,
                                  id: item._id,
                                })
                              }}
                              to={"/campaign/" + item._id}
                            >
                              <li>
                                <span>
                                  {moment
                                    .utc(item.createdAt)
                                    .local()
                                    .format("MM-DD-YYYY")}
                                </span>

                                <span className="d-flex justify-content-between">
                                  {item.name}
                                  <img
                                    src={rightArrow}
                                    alt=""
                                    // className="right-arrow"
                                  />
                                </span>
                              </li>
                            </Link>
                          )
                        }
                      )}
                  </ul>
                </div>
              </div>
              {Math.ceil(
                this.state.selectCampaignData?.totalCount /
                  this.state.selectCampaignData?.limit
              ) > 1 && (
                <div className="pagination-bottom-bar">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={e => {
                      this.getCampaigns(e.selected + 1)
                    }}
                    pageRangeDisplayed={5}
                    pageCount={Math.ceil(
                      this.state.selectCampaignData?.totalCount /
                        this.state.selectCampaignData?.limit
                    )}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    className={"paginate"}
                  />
                </div>
              )}
            </aside>
          </>
        )}

        <div className="page-content">
          <MetaTags>
            <title>Campaign | TextLaunchPad.com </title>
          </MetaTags>
          <div className="container-fluid">
            {this.state.isLoading && (
              <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div>
            )}
            {this.props.leads.length ? (
              <CampaignHeader
                campaign={this.props.campaign}
                leadStages={this.props.leads}
                campaignFilterProspects={this.props.campaignFilterProspects}
              />
            ) : null}

            <Row className={"pt-2"}>
              <Col lg={12}>
                {/* {this.props.apiError &&
                typeof this.props.apiError === "string" ? (
                  <Alert color="danger">{this.props.apiError}</Alert>
                ) : null} */}

                {/* {this.props.apiError &&
                typeof this.props.apiError === "object" ? (
                  this.props.apiError.response ? (
                    <Alert color="danger">
                      {this.props.apiError.response.data.error}
                    </Alert>
                  ) : (
                    <Alert color="danger">{this.props.apiError.message}</Alert>
                  )
                ) : null} */}

                {/* {this.props.successMsg ? (
                  <Alert color="success">{this.props.successMsg}</Alert>
                ) : null} */}
              </Col>

              <Toaster />
            </Row>

            <div className="c-card s-nav mb-3">
              <Row>
                <Col lg={12} className="">
                  <Nav tabs className="nav-tabs-custom cc-tabs">
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link active"}
                        to={"/campaign/" + this.state.id}
                      >
                        <span className="d-sm-block">Metrics</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/send"}
                      >
                        <span className="d-sm-block">Send</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/upload_history"}
                      >
                        <span className="d-sm-block">Upload History</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={
                          "/campaign/" +
                          this.state.id +
                          "/prospects_messages_notes"
                        }
                      >
                        <span className="d-sm-block">Prospects & Messages</span>
                      </Link>
                    </NavItem>
                    <NavItem>
                      <Link
                        style={{ cursor: "pointer" }}
                        className={"nav-link "}
                        to={"/campaign/" + this.state.id + "/scheduler"}
                      >
                        <span className="d-sm-block">Scheduler</span>
                      </Link>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </div>

            <div className="c-card">
              <div className="row">
                <div className="col-12">
                  <h3>Campaign Stats</h3>
                  <div className="row">
                    <div className="col-lg-4 col-12">
                      <div className="form-group mt-3">
                        <label className="label-text">Select a campaign</label>
                        <button
                          className="campaign-select-btn"
                          onClick={() => {
                            this.setState({ show: true })
                          }}
                        >
                          <img src={threedotIcon} alt="" />
                          <span>{this.props?.campaign?.name}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-7 col-12">
                      <div className="row" style={{ rowGap: "25px" }}>
                        <div className="col-lg-4 col-12">
                          <div className="c-card ic-card style-2">
                            <img src={ic1} alt="" />
                            <div className="det">
                              <h3 className="purple">
                                {" "}
                                {this.props.countstatus
                                  ? this.props.countstatus.phonenumbers
                                  : 0}
                              </h3>
                              <p>Phone Numbers</p>
                              {this.props.countstatus ? (
                                <img
                                  src={downloadIcon}
                                  className="dwn-btn"
                                  alt=""
                                  onClick={() =>
                                    this.props.downloadStats(
                                      this.state.campaign_id,
                                      "phone"
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="c-card ic-card style-2">
                            <img src={ic2} alt="" />
                            <div className="det">
                              <h3 className="purple">
                                {" "}
                                {this.props.countstatus
                                  ? this.props.countstatus.litigator
                                  : 0}
                              </h3>
                              <p>Litigators</p>
                              {this.props.countstatus ? (
                                <img
                                  src={downloadIcon}
                                  className="dwn-btn"
                                  alt=""
                                  onClick={() =>
                                    this.props.downloadStats(
                                      this.state.campaign_id,
                                      "litigator"
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="c-card ic-card style-2">
                            <img src={ic3} alt="" />
                            <div className="det">
                              <h3 className="red">
                                {this.props.countstatus
                                  ? this.props.countstatus.dnc
                                  : 0}
                                <img src={redWarning} alt="" />
                              </h3>
                              <p>DNC</p>
                              {this.props.countstatus ? (
                                <img
                                  src={downloadIcon}
                                  className="dwn-btn"
                                  alt=""
                                  onClick={() =>
                                    this.props.downloadStats(
                                      this.state.campaign_id,
                                      "dnc"
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="c-card ic-card style-2">
                            <img src={wrongNumberIcon} alt="" />
                            <div className="det">
                              <h3 className="red">
                                {this.props.countstatus
                                  ? this.props.countstatus.wrongNumbers
                                  : 0}
                                <img src={redWarning} alt="" />
                              </h3>
                              <p>Wrong Numbers</p>
                              {this.props.countstatus ? (
                                <img
                                  src={downloadIcon}
                                  className="dwn-btn"
                                  alt=""
                                  onClick={() =>
                                    this.props.downloadStats(
                                      this.state.campaign_id,
                                      "wrongNumber"
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="c-card ic-card style-2">
                          <img src={ic5} alt="" />
                            
                            <div className="det">
                              <h3 className="red">
                                {this.props.countstatus
                                  ? this.props.countstatus.priority
                                  : 0}
                                <img src={redWarning} alt="" />
                              </h3>
                              <p>Priority Count</p>
                              {this.props.countstatus ? (
                                <img
                                  src={downloadIcon}
                                  className="dwn-btn"
                                  alt=""
                                  onClick={() =>
                                    this.props.downloadStats(
                                      this.state.campaign_id,
                                      "priority"
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="c-card ic-card style-2">
                          <img src={qualifiedLead} alt="" />
                            
                            <div className="det">
                              <h3 className="red">
                                {this.props.countstatus
                                  ? this.props.countstatus.qualified
                                  : 0}
                                <img src={redWarning} alt="" />
                              </h3>
                              <p>Qualified Leads</p>
                              {this.props.countstatus ? (
                                <img
                                  src={downloadIcon}
                                  className="dwn-btn"
                                  alt=""
                                  onClick={() =>
                                    this.props.downloadStats(
                                      this.state.campaign_id,
                                      "qualified"
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-12">
                          <div className="c-card ic-card style-2">
                            <img src={ic6} alt="" />
                            <div className="det">
                              <h3 className="">
                                {this.props.countstatus
                                  ? this.props.countstatus.dripCount
                                  : 0}
                                {/* <img src={redWarning} alt="" /> */}
                              </h3>
                              <p>Drip Count</p>
                              {this.props.countstatus ? (
                                <img
                                  src={downloadIcon}
                                  className="dwn-btn"
                                  alt=""
                                  onClick={() =>
                                    this.props.downloadStats(
                                      this.state.campaign_id,
                                      "dripCount"
                                    )
                                  }
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-12">
                      <div className="c-card ic-card style-2 semi-chart-wrap">
                        {this.state.chartData && this.state.chartData.length ? (
                          <ApexChartSemiDonut
                            data={this.state.chartData}
                            key={this.state.reloadKey}
                          />
                        ) : (
                          "There are no records to display"
                        )}
                        {/* <Link to="" className="dwn-btn">
                          <img src={downloadIcon} alt="" />
                        </Link> */}
                      </div>
                    </div>
                  </div>
                  <h3 className="mt-5 mb-3">Campaign Batches</h3>

                  <DataTable
                    // title="Campaign Metrics"
                    columns={batchesColumns}
                    data={batchesData}
                    fixedHeader
                    pagination
                    className="c-table"
                  />
                  {/* <Table
                    id="tech-companies-1"
                    className="table veltrix-table c-table mt-4"
                  >
                    <Thead>
                      <Tr>
                        <Th data-priority="1">Batch</Th>
                        <Th data-priority="2">Sent</Th>
                        <Th data-priority="2">Delivered</Th>
                        <Th data-priority="4">Delivery Rate</Th>
                        <Th data-priority="5">Response</Th>
                        <Th data-priority="6">Response Rate</Th>
                        <Th data-priority="7">Last Sent</Th>
                        <Th data-priority="8">Last Received</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {this.props.campaign_metrics.length > 0 &&
                        this.props.campaign_metrics?.map((item, index) => {
                          if (item.sent)
                            return (
                              <Tr key={index}>
                                <Td valign="middle">Batch #{index + 1}</Td>
                                <Td valign="middle">{item.sent}</Td>
                                <Td valign="middle">{item.delivered}</Td>
                                <Td valign="middle">
                                  {+item.sent
                                    ? (
                                        (+item.delivered / +item.sent) *
                                        100
                                      ).toFixed(1) + "%"
                                    : "-"}{" "}
                                </Td>
                                <Td valign="middle">🔥 {item.received}</Td>
                                <Td valign="middle">
                                  <div className="st-tag pending">
                                    <span>
                                      {+item.received
                                        ? (
                                            (+item.received / +item.sent) *
                                            100
                                          ).toFixed(1) + "%"
                                        : "-"}
                                    </span>
                                  </div>
                                </Td>
                                <Td valign="middle">
                                  {moment
                                    .utc(item.createdAt)
                                    .local()
                                    .format("MM-DD-YYYY | hh:mm A")}
                                </Td>
                                
                                <Td valign="middle">
                                  {moment
                                    .utc(item.date_end)
                                    .local()
                                    .format("MM-DD-YYYY | hh:mm A")}
                                </Td>
                              </Tr>
                            )
                        })}
                    </Tbody>
                  </Table> */}
                  <h3 className="mt-5">Campaign Prospects</h3>

                  <DataTable
                    // title="Campaign Metrics"
                    columns={prospectsColumns}
                    data={prospectsData}
                    pagination
                    fixedHeader
                    // fixedHeaderScrollHeight="500px"
                    className="c-table"
                  />

                  {/* <Table
                    id="tech-companies-1"
                    className="table veltrix-table c-table mt-4"
                  >
                    <Thead>
                      <Tr>
                        <Th data-priority="1">Name</Th>
                        <Th data-priority="2">Phone</Th>
                        <Th data-priority="2">Status</Th>
                        <Th data-priority="4">Last Message</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {this.props?.campaign?.prospects &&
                        this.props.campaign.prospects?.map((item, index) => {
                          return (
                            <Tr key={index}>
                              <Td valign="middle">
                                {item.first_name + " " + item.last_name}
                              </Td>
                              <Td valign="middle">{item.phone}</Td>
                              <Td valign="middle"> */}
                  {/* <div className="st-tag drip">
                                <img src={dripIcon} alt="" />
                                <span>Drip</span>

                              </div> */}
                  {/* {item.dnc ? (
                                  <div>
                                    <i
                                      className="fas fa-phone-slash text-danger"
                                      title="DNC"
                                      text
                                    />
                                    <span> DNC</span>
                                   
                                  </div>
                                ) : item.isDripAdded ? (
                                  <div>
                                    <img src={dripIcon} alt="" title="Drip" />
                                    <span> Drip</span>
                                  </div>
                                ) : item.isPriority ? (
                                  <div>
                                    <i
                                      className="fas fa-bolt ps-2"
                                      title="Priority"
                                    />
                                    <span> Priority</span>
                                  </div>
                                ) : (
                                  "? No Status"
                                )}
                              </Td>
                              <Td valign="middle">
                                {moment
                                  .utc(item.lastsmssend)
                                  .local()
                                  .format("MM-DD-YYYY | hh-mm A")}
                              </Td>
                            </Tr>
                          )
                        })} */}
                  {/* <Tr>
                        <Td valign="middle">Gary Much</Td>
                        <Td valign="middle">(703) 424-1411</Td>
                        <Td valign="middle">
                          <div className="st-tag removed">
                            <img src={crossIcon} alt="" />
                            <span>Drip</span>
                          </div>
                        </Td>
                        <Td valign="middle">02-11-2023 | 08:54 PM</Td>
                      </Tr>
                      <Tr>
                        <Td valign="middle">Gary Much</Td>
                        <Td valign="middle">(703) 424-1411</Td>
                        <Td valign="middle">
                          <div className="st-tag pending">
                            <img src={warningIcon} alt="" />
                            <span>Drip</span>
                          </div>
                        </Td>
                        <Td valign="middle">02-11-2023 | 08:54 PM</Td>
                      </Tr> */}
                  {/* </Tbody>
                  </Table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
Metrics.propTypes = {
  apiError: PropTypes.any,
  successMsg: PropTypes.any,
  campaign: PropTypes.object,
  campaigns: PropTypes.array,
  getCampaign: PropTypes.func,
  getCountStats: PropTypes.func,
  downloadStats: PropTypes.func,
  getCampaignMetrics: PropTypes.func,
  campaign_metrics: PropTypes.array,
  countstatus: PropTypes.object,
  leads: PropTypes.array,
  getLeadStages: PropTypes.func,
  campaignFilterProspects: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    apiError,
    campaign,
    campaigns,
    leads,
    getLeadStages,
    getCampaign,
    successMsg,
    campaign_metrics,
    countstatus,
    getCountStats,
    downloadStats,
    getCampaignMetrics,
    campaignFilterProspects,
  } = state.Campaign
  return {
    apiError,
    campaign,
    campaigns,
    leads,
    getLeadStages,
    getCampaign,
    successMsg,
    campaign_metrics,
    countstatus,
    getCountStats,
    downloadStats,
    getCampaignMetrics,
    campaignFilterProspects,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    getCountStats,
    downloadStats,
    getCampaignMetrics,
    getCampaign,
    getLeadStages,
    campaignFilterProspects,
  })(Metrics)
)
