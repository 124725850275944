import React from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import toast, { Toaster } from "react-hot-toast"
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Row,
  Popover,
  PopoverHeader,
  PopoverBody,
  PopoverProps,
  FormGroup,
  Modal,
  Spinner,
  Label,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import moment from "moment"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import { pushToWebhook } from "../../store/prospects/actions"
import threeDot from "../../assets/images/threedotverticle.svg"
import astrixIcon from "../../assets/images/astrix.svg"
import sendIcon from "../../assets/images/send.svg"

import sidebarCloseIcon from "../../assets/images/sidebar-close.svg"
import Exclamation from "../../assets/images/triangleExclamation.svg"
import detailsIcon from "../../assets/images/details.svg"
import notesIcon from "../../assets/images/notes.svg"
import activityIcon from "../../assets/images/activity.svg"

import printIcon from "../../assets/images/printIcon.svg"
import editIcon from "../../assets/images/editIcon.svg"
import plusIcon from "../../assets/images/plus-icon.svg"
import plusPurple from "../../assets/images/purple-plus.svg"
import calendar from "../../assets/images/calendar.svg"
import purpleCalendar from "../../assets/images/calendar-purple.svg"
import check from "../../assets/images/check-active.svg"
import duplicateIcon from "../../assets/images/duplicateIcon.svg"
import deleteIcon from "../../assets/images/deleteIcon.svg"
import linkIcon from "../../assets/images/link-icon.svg"
import illus from "../../assets/images/chat-illustration.svg"
import dropDownArrow from "../../assets/images/drop-down.svg"
import axios from "axios"
import dripIconBorder from "../../assets/images/drip-icon-border.svg"
import dripIconFilled from "../../assets/images/drip-icon-filled.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import DripVaccent from "../../assets/images/DripVaccent.svg"
import dripActivity from "../../assets/images/dripActivity.svg"
import dncActivity from "../../assets/images/dncActivity.svg"
import wrongNumberActivity from "../../assets/images/wrongNumber.svg"
import leadStageActivity from "../../assets/images/leadStageActitvity.svg"
import priorityActivity from "../../assets/images/priorityActivity.svg"
import followUpActivity from "../../assets/images/followUpActivity.svg"
import verifiedActivity from "../../assets/images/verifiedActivity.svg"
import qualifiedActivity from "../../assets/images/qualifiedActivity.svg"
import tagsActivity from "../../assets/images/tagsActivity.svg"
import close from "../../assets/images/close.svg"
import DataTable from "react-data-table-component"
import arrowRight from "../../assets/images/arrowRight.svg"
import arrowLeft from "../../assets/images/arrowLeft.svg"
import chatLoading from "../../assets/images/chatLoading.svg"
import Switch from "react-switch"
class Conversations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popoverOpen: false,
      message: null,
      send: this.props.sendText,
      textlength: "",
      selected_status: this.props.data.prospect.lead,
      selectedOption: null,
      tab: this.props.activeTab || 1,
      sidebar: !!this.props.activeTab,
      noteMsg: "",
      loading: false,
      count: 60,
      isLoading: false,
      selectedTags: [],
      isOpen: false,
      reloadKey: Math.random(),
      activeConvIndex: this.props.activeConvIndex,
      drip_modal: false,
      dripTemplates: null,
      dripReloadKey: Math.random(),
      dripTemplateDetail: {
        isOpen: false,
        id: null,
        data: {},
      },
      dripProspectId: null,
      dripRemoveConfirm: {
        isOpen: false,
      },
      checkedNumber: [],
      checkNumberLoading: false,
      anotherActiveNumber: null,
      createNewConv: false,

      // reminder : null,
    }
    this.convRef = React.createRef()
    this.sendTextInput = React.createRef()
  }
  getdata(isLeadChange = false, changedLead) {
    let authUser = localStorage.getItem("authUser")
    let user = JSON.parse(authUser)

    if (isLeadChange) {
      this.props.socket.emit(
        "getmessagebypros",
        user.user.name,
        this.props.data.prospect._id
      )
      this.props.socket.emit(
        "searchmessage",
        user.user.name,
        changedLead,
        this.props.id
      )
    } else {
      let id = this.props.match.params.id
      this.props.socket.emit("getmessages", user.user.name, id)
    }

    setTimeout(() => {
      this.props.resetMessage(this.props.data._id)
    }, 1000)
  }

  setTextBody(message) {
    let body = message
    body = body.replace(
      /\[@firstName+\]/g,
      this.props.data.prospect?.first_name
    )
    body = body.replace(/\[@email+\]/g, this.props.data.prospect.email)
    body = body.replace(/\[@lastName+\]/g, this.props.data.prospect?.last_name)
    body = body.replace(
      /\[@address+\]/g,
      this.props.data.prospect.property_address
    )
    body = body.replace(/\[@city+\]/g, this.props.data.prospect.property_city)
    body = body.replace(/\[@zip+\]/g, this.props.data.prospect.property_zip)
    body = body.replace(/\[@state+\]/g, this.props.data.prospect.property_state)
    this.setState({
      send: body,
    })
  }

  onChange(e, id) {
    // this.props.existsProspect({
    //   _id: id,
    //   value: e,
    // });
    this.props.leadChangeHandler({
      _id: id,
      value: e,
    })
    // this.getdata(true, e.target.value)
    this.props.toggleLoader()
    // this.props.refreshData(true, e, this.props.data.prospect._id)
  }

  async handleValidSubmit(event, values) {
    if (values.message.length > 320) {
      return
    }

    if (values.message.trimStart() === "") {
      this.setState({
        send: "",
        reloadKey: Math.random(),
      })
      return
    }
    this.setState({
      isLoading: true,
    })

    let data = {}

    if (this.state?.createNewConv) {
      data = {
        body: this.state.send.trim(),
        prospectId: this.state.message?.prospect._id,
        conversationId:
          this.state.message?.conversations[this.state.activeConvIndex]?._id,
      }
    } else {
      data = {
        from: this.state.message?.conversations[this.state.activeConvIndex]
          ?.systemNumber,
        to: this.state.message?.conversations[this.state.activeConvIndex]
          ?.prospectNumber,
        body: this.state.send.trim(),
        camp: this.props.data.prospect.campaign,
        prospectId: this.state.message?.prospect._id,
        conversationId:
          this.state.message?.conversations[this.state.activeConvIndex]?._id,
      }
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/sendconv",
        data,
        this.props.config
      )
      if (response.data) {
        this.setState({
          isLoading: false,
          reloadKey: Math.random(),
          send: "",
          createNewConv: false,
        })
        const currentConvIndex = this.state.message?.conversations.findIndex(
          item => item._id === response.data.convId
        )
        if (currentConvIndex !== -1) {
          const newMessages =
            this.state.message.conversations[currentConvIndex].messages
          newMessages.push(response.data)

          let prosData = { ...this.state.message }

          let newConv = prosData.conversations[currentConvIndex]
          newConv.unreadCount = newConv.unreadCount + 1
          newConv.messages = newMessages
          prosData.conversations[currentConvIndex] = newConv
          this.setState({
            message: prosData,
            isLoading: false,
          })
        }
      }
    } catch (error) {
      this.setState({ isLoading: false })
      this.notify("error", error?.response?.data?.message)
    }

    this.setState({
      send: "",
      reloadKey: Math.random(),
      // textlength:0
    })
  }

  async changesstatus() {
    this.setState({ isLoading: true })

    let authUser = localStorage.getItem("authUser")
    let user = JSON.parse(authUser)
    let token = user?.user?.token

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const updatedConversation = await axios.post(
        process.env.REACT_APP_PORT + "/api/conversation/markAsRead",
        {
          convId:
            this.state.message.conversations?.[this.state.activeConvIndex]?._id,
        },
        config
      )
      if (updatedConversation?.data) {
        const newConversations = [...this.state.message.conversations]
        newConversations[this.state.activeConvIndex].unreadCount = 0
        let newProspectData = { ...this.state.message }
        newProspectData["conversations"] = newConversations
        this.setState({ message: newProspectData, isLoading: false })
        // setProspectReloadKeyopoverOpen(Math.random())
        this.notify("success", "Conversation status updated successfully!")
      }
    } catch (error) {
      this.setState({ isLoading: false })
      this.notify(
        "error",
        error.response?.data?.error ||
          error.response?.data?.message ||
          error?.data?.message
      )
    }
  }

  async componentDidMount() {
    //  if(this.state.send && this.state.send.length){
    //   this.sendTextInput?.current?.inputRef?.focus()
    // }

    // this.convRef?.current?.scrollIntoView({ behavior: "smooth" })

    // console.log(
    //   this.convRef.current,
    //   "99999999999999",
    //   this.convRef.current.scrollHeight
    // )
    // if (this.convRef.current) {
    //   setTimeout(() => {
    //     this.convRef.current?.scrollIntoView({behavior: 'smooth'});
    //   }, 100)
    // }

    this.setState({
      message: this.props.data,
    })

    if (this.props?.data._id) {
      this.props.getProspectDataById(this.props?.data._id)
    }

    this.getDripTemplates()
    this.props.getQuickReplies()
    this.props.socket.on("message", msg => {
      if (msg != null && msg._id == this.props.data._id) {
        if (this.state.message.status != msg.status) {
          if (!this.props.data.prospect.isPriority) {
            this.props.toggleLoader()
            this.props.updateListByLead(
              this.props.data.prospect.lead,
              this.props.data.prospect._id,
              true
            )
          } else {
            this.props.updateListByLead(
              this.props.data.prospect.lead,
              this.props.data.prospect._id
            )
          }
        }

        this.setState({
          message: msg,
        })
      }
    })

    if (
      this.props.data?.conversations?.[this.state.activeConvIndex]
        ?.messages?.[0]?.systemNumber
    ) {
      const systemNumber = this.props.data?.conversations?.map(
        item => item.messages?.[0]?.systemNumber
      )

      // this.checkSystemNumber(
      //   this.props.data?.conversations?.[this.state.activeConvIndex]
      //     ?.messages?.[0]?.systemNumber
      // )
      this.checkSystemNumber(systemNumber)
    }

    const tempArr = []
    this.props.tagsData.tagsOptions.map(item => {
      const temp =
        this.props.data.prospect.tags?.filter(tag => tag === item.value) || []
      if (temp && temp.length) tempArr.push(item)
    })

    this.setState({
      selectedOption: tempArr,
    })
  }

  async handleCreateReminder(e, value) {
    const data = {
      date: value?.calendar,
      prospectId: this.props.data.prospect._id,
      note: value?.note,
    }
    this.props.createReminder(data)
    this.setState({
      isOpen: false,
    })
  }

  async updateTags(tags) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    try {
      const response = await axios.put(
        process.env.REACT_APP_PORT + "/api/prospect/updateProspectTags",
        { tags },
        config
      )
    } catch (err) {
      this.notify("error", err)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // if(this.state.send && this.state.send.length){

    // }
    // if (
    //   JSON.stringify(prevState.message) !== JSON.stringify(this.state.message)
    // ) {
    //   this.convRef.current?.scrollIntoView()
    // }

    if (prevState.message && prevState.message._id != this.props.data._id) {
      this.setState({
        message: this.props.data,
        selected_status: this.props.data.prospect.lead,
      })
    }
  }

  pushToPodio = async currentProspectId => {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }

    this.setState({ isLoading: true })
    const body = {
      id: currentProspectId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT +
          "/api/prospect/pushToWH/" +
          currentProspectId,
        body,
        config
      )
      if (response?.data) {
        this.notify("success", "Prospect Pushed to Podio Successfully")
        this.setState({ isLoading: false })
      }
    } catch (error) {
      notify("error", error?.response?.data?.message)
      this.setState({ isLoading: false })
    }
  }

  createNotesHandler = async () => {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }

    if (this.state.noteMsg && this.state.noteMsg.trim().length) {
      const data = {
        notes: this.state.noteMsg,
        id: this.props.data?.prospect._id,
      }

      this.setState({ isLoading: true })

      try {
        const response = await axios.post(
          process.env.REACT_APP_PORT + "/api/notes/createNote/",
          data,
          config
        )
        if (response) {
          this.setState({ noteMsg: "", isLoading: false, sidebar: false })
          this.notify("success", "Note Added Successfully")
          this.props.getProspectDataById(this.props.data._id)
        }
      } catch (error) {
        this.setState({ isLoading: false })
        this.notify("error", error.response?.data?.message)
      }
    }
  }

  pushToWHT() {
    this.setState({
      loading: true,
    })
    const intervel = setInterval(() => {
      let count
      if (this.state.count <= 0) {
        count = 0
      } else {
        count = this.state.count - 1
      }
      this.setState({
        count: count,
      })
    }, 1000)
    setTimeout(() => {
      // this.props.pushToWebhook({ id: this.props.data.prospect._id })
      this.pushToPodio(this.props.data.prospect._id)
      this.setState({
        loading: false,
        count: 60,
      })
      clearInterval(intervel)
    }, 60000)
  }

  isColorLight(hexColor) {
    // Remove the # symbol if it's present
    hexColor = hexColor.replace(/^#/, "")

    // Convert the hex color to RGB
    const r = parseInt(hexColor.slice(0, 2), 16)
    const g = parseInt(hexColor.slice(2, 4), 16)
    const b = parseInt(hexColor.slice(4, 6), 16)

    // Calculate relative luminance
    const relativeLuminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255

    // WCAG recommends a threshold of 0.5 to determine light or dark
    if (relativeLuminance > 0.5) {
      return true
    } else {
      return false
    }
  }
  notify(type, text) {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occured")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }

  tog_drip() {
    this.setState({
      drip_modal: !this.state.drip_modal,
    })
  }
  togRemoveDrip(dripProspectId, dripData, dripTemplateData) {
    this.setState({
      dripProspectId: dripProspectId,
      dripRemoveConfirm: {
        isOpen: true,
        dripData: dripData,
        dripTemplateData: dripTemplateData,
      },
    })
  }

  async getDripTemplates() {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplates",
      config
    )
    if (response.data) {
      this.setState({
        dripTemplates: response.data.data,
      })
    }
  }

  async getDripTemplateDetail(id) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const response = await axios.get(
      process.env.REACT_APP_PORT + "/api/dripTemplate/pullDripTemplate/" + id,
      config
    )
    if (response.data) {
      this.setState({
        // dripReloadKey: Math.random(),
        dripTemplateDetail: {
          isOpen: true,
          id: id,
          data: response.data.data,
        },
      })
    }
  }

  async addDrip(dripTemplateId = this.state.dripTemplateDetail.id) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const body = {
      prospectId: this.state.dripProspectId,
      dripTemplateId,
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/addDrip",
        body,
        config
      )
      if (response.data) {
        this.notify("success", "Drip added successfully")
        this.tog_drip()
        this.setState({
          dripTemplateDetail: {
            isOpen: false,
            id: null,
            data: {},
          },
          dripProspectId: null,
          reload: !this.state.reload,
        })
        // this.props.refreshData(true, e.target.value, this.props.data.prospect._id)
      }
    } catch (error) {
      this.notify("error", error.response.data.message)
    }
  }

  async removeDrip(prospectId) {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }

    if (!prospectId) {
      prospectId = this.state.dripProspectId
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    const body = {
      prospectId,
    }

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/dripTemplate/removeDrip",
        body,
        config
      )
      if (response.data) {
        this.notify("success", "Drip removed successfully")
        setTimeout(() => {
          this.setState({
            reload: !this.state.reload,
            dripProspectId: null,
            dripRemoveConfirm: {
              isOpen: false,
            },
          })
        }, 1000)
      }
    } catch (error) {
      this.notify("error", error.response.data.message)
    }
  }

  checkSystemNumber = async array => {
    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }

    const numbers = {
      numbers: array,
    }

    this.setState({ checkNumberLoading: true })
    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/conversation/checkNumber`,
        numbers,
        config
        // ${number}
      )
      if (response.data) {
        let index = this.props.data?.conversations.findIndex(obj =>
          response?.data.some(
            item => item.number == obj.systemNumber && item.status == "Active"
          )
        )

        if (index !== -1) {
          this.setState({
            anotherActiveNumber: index,
          })
        }

        this.setState({
          checkedNumber: response?.data,
          checkNumberLoading: false,
        })
      }
    } catch (error) {
      this.setState({
        checkNumberLoading: false,
        checkedNumber: [],
      })
      this.notify("error", error.response?.data?.error)
    }
  }

  validMessage = (message) => {
    let isValidMessage = true;

    if (message?.length) {
      const regExp = /\[@([^}]+)\]/g;
      const matches = message.match(regExp);

      if (matches?.length) {
        isValidMessage = false;
      }
    }

    return isValidMessage;
  };

  MapQuickReply = async (message, prospect) => {

    const authUser = localStorage.getItem("authUser")
    let token = false
    if (authUser) {
      let user = JSON.parse(authUser)
      token = user?.user?.token
    } else {
      token = false
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }

    this.setState({ isLoading: true });

    try {
      const response = await axios.post(
        process.env.REACT_APP_PORT + `/api/conversation/mapQuickReply`,
        { message: message, prospect: prospect },
        config
      );

      if (response?.data) {
        this.setState({ send: response.data.message, isLoading: false });

        if (response?.data?.invalid?.length) {
          this.notify('error', 'No data available for replacement with merge field');
        }
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.notify('error', error.response?.data?.error);
    }
  };

  render() {


    
    const activityIcons = {
      drip: dripActivity,
      wrongNumber: wrongNumberActivity,
      dnc: dncActivity,
      leadStage: leadStageActivity,
      verified: verifiedActivity,
      followUp: followUpActivity,
      priority: priorityActivity,
      qualified: qualifiedActivity,
      tag: tagsActivity,
    }

    const colourStyles = {
      control: styles => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        // return {
        //   ...styles,
        //   backgroundColor: isDisabled
        //     ? undefined
        //     : isSelected
        //     ? data.color
        //     : isFocused
        //     ? color.alpha(0.1).css()
        //     : undefined,
        //   color: isDisabled
        //     ? '#ccc'
        //     : isSelected
        //     ? chroma.contrast(color, 'white') > 2
        //       ? 'white'
        //       : 'black'
        //     : data.color,
        //   cursor: isDisabled ? 'not-allowed' : 'default',
        //   ':active': {
        //     ...styles[':active'],
        //     backgroundColor: !isDisabled
        //       ? isSelected
        //         ? data.color
        //         : color.alpha(0.3).css()
        //       : undefined,
        //   },
        // };
      },
      multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
          ...styles,
          backgroundColor: "#efefef",
        }
      },
      multiValueLabel: (styles, state) => {
        return {
          ...styles,
          backgroundColor: state?.data?.data?.color?.color + " !important",
        }
      },
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        // color: data.color,
        // ':hover': {
        //   backgroundColor: data.color,
        //   color: 'white',
        // },
      }),
    }
    const colorStyleForLead = {
      control: styles => ({ ...styles, backgroundColor: "white" }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {},
    }

    const phoneNumberColumns = [
      {
        name: "Title",
        selector: "title",
        sortable: true,
        cell: row => {
          return (
            <div
              className="border border-primary rounded w-100 p-2 m-2 cursor-pointer"
              onClick={() => {
                const regExp = /\[@([^}]+)\]/g
                const matches = row?.message?.match(regExp)

                if (matches?.length) {
                  this.MapQuickReply(row?.message, this.props?.prospectData?.prospect)
                }
                this.setTextBody(row.message)

                this.setState({
                  popoverOpen: !this.state.popoverOpen,
                })
              }}
            >
              <h6>{row.title}</h6>
              <p className="mb-0">{row.message}</p>
            </div>
          )
        },
      },
    ]
    return (
      <React.Fragment>
        <Toaster />
        {this.state.isLoading && (
          <div className="spinner-wr">
            <Spinner style={{ color: "#5932ea" }} />
          </div>
        )}
        <div className="chat-convo-main-wrapper">
          {/* bar */}

          <div
            className={`chat-sidebar-wrapper ${this.state.sidebar && "active"}`}
          >
            <button
              onClick={() => this.setState({ sidebar: false })}
              className="chat-sidebar-close"
            >
              <img src={sidebarCloseIcon} alt="" />
            </button>
            <div className="chat-sidebar-container">
              <div className="chat-sidebar-header">
                <ul>
                  <li>
                    <button
                      onClick={() => this.setState({ tab: 1 })}
                      className={`${this.state.tab == 1 ? "active" : ""}`}
                    >
                      <img src={detailsIcon} alt="" />
                      <span>Details</span>
                    </button>
                  </li>
                  {/* {this.props?.show && ( */}
                  <li>
                    <button
                      onClick={() => this.setState({ tab: 2 })}
                      className={`${this.state.tab == 2 ? "active" : ""}`}
                    >
                      <img src={notesIcon} alt="" />
                      <span>Notes</span>
                    </button>
                  </li>
                  {/* )} */}
                  <li>
                    <button
                      onClick={() => this.setState({ tab: 3 })}
                      className={`${this.state.tab == 3 ? "active" : ""}`}
                    >
                      <img src={activityIcon} alt="" />
                      <span>Activity</span>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="chat-sidebar-body">
                {this.state.tab == 1 && (
                  <div className="details-tab">
                    <ul className="actions">
                      <li>
                        <button
                          onClick={() => {
                            this.setState({ sidebar: false })
                            this.props.updateProspectHandler({
                              show: true,
                              prospectId: this.props.data?.prospect._id,
                              first_name: this.props.data?.prospect?.first_name,
                              last_name: this.props.data?.prospect?.last_name,
                            })
                          }}
                        >
                          <img src={editIcon} alt="" />
                          <span>Edit</span>
                        </button>
                      </li>
                      {/* <li>
                        <button>
                          <img src={duplicateIcon} alt="" />
                          <span>Duplicate</span>
                        </button>
                      </li> */}
                      <li>
                        <button
                          onClick={() => {
                            this.props.deleteProspectHandler({
                              show: true,
                              id: this.props.data?.prospect._id,
                              first_name: this.props.data?.prospect.first_name,
                              last_name: this.props.data?.prospect.last_name,
                            })
                          }}
                        >
                          <img src={deleteIcon} alt="" />
                          <span>Delete</span>
                        </button>
                      </li>
                    </ul>
                    <div className="chat-sidebar-top">
                      <div className="chat-sidebar-image">
                        <img
                          src={`https://maps.googleapis.com/maps/api/streetview?size=500x500&location=${encodeURIComponent(
                            this.props.data?.prospect?.property_address +
                              " " +
                              this.props.data?.prospect?.property_state +
                              "," +
                              this.props.data?.prospect?.property_zip
                          )}&fov=70&key=AIzaSyAF7bA-6V-gMdD-QFeIq8WYx_66RDMLK8Y`}
                          alt=""
                        />
                      </div>
                      <div className="chat-sidebar-det">
                        <h3>
                          {this.props.data?.prospect?.first_name +
                            " " +
                            this.props.data?.prospect?.last_name}
                        </h3>
                        <p>
                          {this.props.data &&
                            this.props.data.sender != undefined &&
                            this.props.data.sender}
                          <br />

                          {[
                            this.props.data.prospect.property_address &&
                              this.props.data.prospect.property_address + ", ",
                            this.props.data.prospect.property_city &&
                              this.props.data.prospect.property_city + ", ",
                            this.props.data.prospect.property_state &&
                              this.props.data.prospect.property_state + ", ",
                            this.props.data.prospect.property_zip &&
                              this.props.data.prospect.property_zip,
                          ]}
                        </p>

                        <a
                          href={
                            `http://www.zillow.com/homes/${
                              this.props.data?.prospect?.property_address +
                              " " +
                              this.props.data?.prospect?.property_city +
                              ", " +
                              this.props.data?.prospect?.property_state +
                              " " +
                              this.props.data?.prospect?.property_zip
                            }`
                              .toString()
                              .split(" ")
                              .join("-") + "_rb"
                          }
                          target="_blank"
                        >
                          View on Zillow
                        </a>
                        <p>
                          {" "}
                          <strong>
                            {" "}
                            {this.props?.campaignData?.name ? (
                              <i className="highlightedCampaignName">
                                {" "}
                                {this.props?.campaignData?.name}
                                {"  "}
                              </i>
                            ) : (
                              <span className="text-danger">
                                Campaign Not Exist
                              </span>
                            )}
                          </strong>{" "}
                        </p>
                        {/* <p>
                          {" "}
                          <strong>
                            {" "}
                            {this.props.data?.campaign?.name ? (
                              <i className="highlightedCampaignName">
                                {" "}
                                {this.props.data?.campaign?.name}{" "}
                              </i>
                            ) : (
                              <span className="text-danger">
                                Campaign Not Exist
                              </span>
                            )}
                          </strong>{" "}
                        </p> */}
                      </div>
                    </div>
                    <div>
                      {!this.props.hide && (
                        <div className="chat-tag-wrap chat-sidebar-tags">
                          {this.props.data.prospect.dnc ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"danger"}
                              onClick={e => {
                                this.props.setDNC(
                                  this.props.data.prospect._id,
                                  false
                                )
                              }}
                              title="Remove From DNC"
                            >
                              <i className="fas fa-phone-slash" />
                              <span>DNC</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={e => {
                                this.props.setDNC(
                                  this.props.data.prospect._id,
                                  true
                                )
                              }}
                              title="Add To DNC"
                            >
                              <i className="fas fa-phone-slash" />
                              <span>DNC</span>
                            </Button>
                          )}{" "}
                          {this.props.data.prospect.verified === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() =>
                                this.props.verifiedNumber(
                                  this.props.data.prospect._id,
                                  this.props.data?.prospect?.verified
                                )
                              }
                              title="Add to Verified"
                            >
                              <i className="fas fa-check" />
                              <span>Verified</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"success"}
                              onClick={() =>
                                this.props.verifiedNumber(
                                  this.props.data.prospect._id,
                                  this.props.data?.prospect?.verified
                                )
                              }
                              title="Remove From Verified"
                            >
                              <i className="fas fa-check" />
                              <span>Verified</span>
                            </Button>
                          )}{" "}
                          {this.props.data.prospect.Wrong_Number === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() =>
                                this.props.wrongNumber(
                                  this.props.data.prospect._id,
                                  this.props.data.prospect.Wrong_Number
                                )
                              }
                              title="Add To Wrong Number"
                            >
                              <i className="fas fa-times" />
                              <span>Wrong Number</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"primary"}
                              onClick={() =>
                                this.props.wrongNumber(
                                  this.props.data.prospect._id,
                                  this.props.data.prospect.Wrong_Number
                                )
                              }
                              title="Remove From Wrong Number"
                            >
                              <i className="fas fa-times" />
                              <span>Wrong Number</span>
                            </Button>
                          )}{" "}
                          {this.props.data.prospect.isLead === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() =>
                                this.props.qualifiedNumber(
                                  this.props.data.prospect._id,
                                  this.props.data.prospect.isLead
                                )
                              }
                              title="Add To Qualified"
                            >
                              <i className="fas fa-star" />
                              <span>Qualified</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"info"}
                              onClick={() =>
                                this.props.qualifiedNumber(
                                  this.props.data.prospect._id,
                                  this.props.data.prospect.isLead
                                )
                              }
                              title="Remove From Qualified"
                            >
                              <i className="fas fa-star" />
                              <span>Qualified</span>
                            </Button>
                          )}{" "}
                          {this.props.data.prospect.isPriority === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() =>
                                this.props.priorityNumber(
                                  this.props.data.prospect._id,
                                  this.props.data.prospect.isPriority
                                )
                              }
                              title="Add To Priority"
                            >
                              <i className="fas fa-bolt" />
                              <span>Priority</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"warning"}
                              onClick={() =>
                                this.props.priorityNumber(
                                  this.props.data.prospect._id,
                                  this.props.data.prospect.isPriority
                                )
                              }
                              title="Remove From Priority"
                            >
                              <i className="fas fa-bolt" />
                              <span>Priority</span>
                            </Button>
                          )}{" "}
                          {this.props.data.prospect.isDripAdded === false ? (
                            <Button
                              className={"botton-btn me-1 "}
                              color={"outline-secondary"}
                              onClick={() => {
                                this.setState({ sidebar: false })
                                // this.tog_drip()
                                this.props.tog_drip(
                                  this?.props?.data?.prospect._id
                                )
                                this.setState({
                                  dripProspectId:
                                    this?.props?.data?.prospect?._id,
                                })
                              }}
                              title="Add To Drip"
                            >
                              <img
                                src={DripVaccent}
                                alt=""
                                style={{ marginBottom: "2px" }}
                              />{" "}
                              <span>Drip</span>
                            </Button>
                          ) : (
                            <Button
                              className={"botton-btn me-1 dripColor"}
                              backgroundColor={"#5932ea"}
                              color={"primary"}
                              onClick={() => {
                                this.setState({ sidebar: false })
                                // this.removeDrip()
                                const dripTemplateData =
                                  this?.props?.data?.prospect?.dripTemplateData
                                const dripData =
                                  this?.props?.data?.prospect?.dripData
                                const dripProspectId =
                                  this?.props?.data?.prospect?._id
                                this.props.togRemoveDrip(
                                  dripProspectId,
                                  dripData,
                                  dripTemplateData
                                )

                                // this.setState({
                                //   dripProspectId:
                                //     this?.props?.data?.prospect?._id,
                                //   dripRemoveConfirm: {
                                //     isOpen: true,
                                //       dripTemplateData:
                                //         this?.props?.data?.prospect?.dripTemplateId,
                                //       dripData: this?.props?.data?.prospect?.dripData,
                                //   },
                                // })
                              }}
                              title="Remove From Drip"
                            >
                              <svg
                                marginBottom="2px"
                                width="14"
                                height="17"
                                viewBox="0 0 14 17"
                                fill="white"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.46846 0.998848C7.19846 0.787168 6.82348 0.787168 6.55348 0.998848C5.12848 2.09505 0.920949 5.67093 0.943449 9.83649C0.943449 13.2082 3.66598 15.9601 7.01848 15.9601C10.371 15.9601 13.0935 13.2158 13.0935 9.84405C13.101 5.73897 8.88596 2.10261 7.46846 0.998848Z"
                                  fill="white"
                                />
                                <path
                                  d="M7.00977 0.840088V15.9601"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.00977 13.6617L12.7848 10.8342"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.00977 9.88177L12.5373 7.17529"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.00977 6.10171L10.7823 4.24951"
                                  stroke="#5932ea"
                                  stroke-width="1.125"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              {/* <img
                                src={DripVaccent}
                                alt=""
                                style={{ marginBottom: "2px" }}
                              /> */}{" "}
                              <span>Drip</span>
                            </Button>
                          )}{" "}
                          {this.state.message?.conversations?.[
                            this.state.activeConvIndex
                          ]?.unreadCount ? (
                            <Button
                              // className={"mt-2"}
                              className={"botton-btn me-1 "}
                              onClick={() =>
                                this.changesstatus(this.state.message._id)
                              }
                              color="primary"
                            >
                              <i className={"fas fa-envelope-open pe-1"}></i>{" "}
                              Mark As Read
                            </Button>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="seperator mt-3"></div>
                    <div>
                      <h3 className="sm-head pt-3">CRM Option</h3>
                      {/* <button className="main-btn border-btn circle-btn block-btn"
                      color={"primary"}
                      onClick={() => {
                        this.pushToWHT()
                      }}>
                        <span>Push to podio</span>
                      </button> */}
                      {this.state.loading ? (
                        <div style={{ marginTop: "10px" }}>
                          <button
                            color={"primary"}
                            className="main-btn border-btn circle-btn block-btn"
                          >
                            <span>
                              Pushing to podio in {this.state.count}
                              sec
                            </span>
                          </button>
                        </div>
                      ) : (
                        <button
                          color={"primary"}
                          onClick={() => {
                            this.pushToWHT()
                          }}
                          className="main-btn border-btn circle-btn block-btn"
                        >
                          <span>Push to Podio</span>
                        </button>
                      )}
                    </div>
                    <div className="seperator mt-3 mb-3"></div>
                    <h3 className="sm-head">Tags</h3>
                    {/* <FormGroup
                      className={"mb-3 mt-2 form-group select-r"}
                    > */}

                    <Select
                      value={this.state.selectedOption}
                      onChange={e => {
                        this.props.updateProspectTags(
                          e,
                          this.props.data.prospect._id
                        )
                      }}
                      options={this.props.tagsData.tagsOptions}
                      isMulti
                      styles={colourStyles}
                    />
                    {/* </FormGroup> */}
                    <h3 className="sm-head mt-3">Lead Stage</h3>
                    <div className="mb-5">
                      {/* <img src={dropDownArrow} alt="" /> */}
                      {/* <Input
                        type="select"
                        className="bg-transparent px-3 mb-3"
                        value={this.state.selected_status}
                        onChange={e => {
                          this.onChange(e, this.props.data.prospect._id)
                          this.setState({ selected_status: e.target.value })
                        }}
                      >
                        {this.props.leads.length > 0 &&
                          this.props.leads.map((lead, index) => (
                            <option value={lead._id} key={index}>
                              {lead.lead_Stage}
                            </option>
                          ))}
                      </Input> */}

                      <Select
                        value={this.props.leads.map((lead, index) => {
                          if (lead._id === this.state.selected_status)
                            return {
                              label: lead.lead_Stage,
                              value: lead._id,
                            }
                        })}
                        onChange={e => {
                          this.onChange(e.value, this.props.data.prospect._id)
                          this.setState({ selected_status: e.value })
                        }}
                        options={this.props.leads.map(lead => {
                          return {
                            label: lead.lead_Stage,
                            value: lead._id,
                          }
                        })}
                        styles={colorStyleForLead}
                      />
                    </div>
                    {this.props?.reminder?._id ? (
                      <div className="pb-50">
                        <h3 className="sm-head">Reminder</h3>
                        <div className="p-3 rounded-3 reminder-box">
                          <Row>
                            <div className=" d-flex justify-content-flex-start gap-2 align-items-center  pl-1">
                              <img
                                src={purpleCalendar}
                                alt=""
                                style={{ display: "inline" }}
                              />
                              <span>
                                {moment
                                  .utc(this.props?.reminder?.date)
                                  .local()
                                  .format("MM-DD-YYYY")}
                              </span>
                            </div>
                          </Row>
                          <Row>
                            <Col>
                              <span>Note : </span>
                              <span className="reminder-note">
                                {this.props?.reminder?.note}
                              </span>
                            </Col>
                          </Row>
                          <div
                            className="text-end d-flex justify-content-end align-items-center"
                            style={{ margin: "0px" }}
                          >
                            <Button
                              type="submit"
                              color="primary"
                              className="main-btn pl-2 pr-2"
                              onClick={() => {
                                this.props.deleteReminder(
                                  this.props.reminder._id
                                )
                              }}
                            >
                              <span className="d-flex gap-2 align-items-center ">
                                {" "}
                                <i className="fas fa-check"></i> Mark as
                                Complete
                              </span>
                            </Button>{" "}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="pb-50">
                        <button
                          color={"primary"}
                          onClick={() => {
                            this.setState({ sidebar: false })
                            this.setState({
                              isOpen: true,
                            })
                          }}
                          className="main-btn border-btn circle-btn block-btn"
                        >
                          <img src={plusPurple} alt="" />
                          <span>Add Reminder</span>
                        </button>
                      </div>
                    )}

                    {/* <div>
                      <button
                        color={"primary"}
                        onClick={() => {
                          this.setState({
                            isOpen: true,
                          })
                        }}
                        className="main-btn border-btn circle-btn block-btn"
                      >
                        <img src={plusPurple} alt="" />
                        <span>Add Reminder</span>
                      </button>
                    </div> */}

                    <Modal
                      isOpen={this.state.popoverOpen}
                      toggle={() => {
                        this.setState({
                          popoverOpen: !this.state.popoverOpen,
                        })
                      }}
                      className="c-modal modal-lg"
                      // backdrop="static"
                      centered={true}
                    >
                      <div className="modal-body">
                        <h5 className="modal-title mt-0 mb-2 ">
                          Quick Replies
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              popoverOpen: !this.state.popoverOpen,
                            })
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <img src={close} alt="" />
                        </button>
                        <DataTable
                          // title=""
                          columns={phoneNumberColumns}
                          data={this.props.quickReplies}
                          pagination
                          paginationPerPage={5}
                          paginationRowsPerPageOptions={[5, 15, 25, 50]}
                          noHeader={true}
                          noTableHead={true}
                          className="c-table"
                        />
                      </div>
                    </Modal>

                    <Modal
                      isOpen={this.state.isOpen}
                      toggle={() => {
                        this.setState({
                          isOpen: false,
                        })
                      }}
                      className="c-modal"
                      // backdrop="static"
                      centered={true}
                    >
                      <div className="modal-body ">
                        <h5 className="modal-title mt-0 mb-3">Add Reminder</h5>
                        <button
                          type="button"
                          onClick={() => {
                            this.setState({
                              isOpen: false,
                            })
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <img src={close} alt="" />
                        </button>

                        {
                          <AvForm
                            onValidSubmit={(e, v) =>
                              this.handleCreateReminder(e, v)
                            }
                            // onSubmit={(e, v) => handleModalSubmit(e, v, modal?.title)}
                            className="needs-validation"
                          >
                            <Row>
                              <Col xl="12">
                                <FormGroup className="mb-3">
                                  <AvField
                                    name="note"
                                    placeholder="Enter Note"
                                    type="textarea"
                                    rows={5}
                                    errorMessage="Enter Note"
                                    className="form-control"
                                    validate={{
                                      required: { value: true },
                                      maxLength: { value: 320 },
                                    }}
                                    id="validationCustom01"

                                    //   onChange={e => {
                                    //     setModal({...modal,
                                    //       alternatemessage: e.target.value,
                                    //     })
                                    //   }
                                    // }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="2">
                                <Label
                                  htmlFor="calendar"
                                  className="main-btn color text-white rounded-circle display-1 d-flex justify-content-center"
                                  style={{ width: "50px", height: "50px" }}
                                >
                                  <img src={calendar} alt="" />
                                </Label>
                              </Col>
                              <Col xl="7" className="d-flex">
                                <FormGroup className="mb-3 ">
                                  <AvField
                                    id="calendar"
                                    name="calendar"
                                    placeholder="Enter Note"
                                    type="date"
                                    rows={5}
                                    errorMessage="Enter Note"
                                    className="form-control border-bottom"
                                    validate={{
                                      required: { value: true },
                                      maxLength: { value: 320 },
                                    }}
                                    min={new Date().toISOString().split("T")[0]}

                                    //   onChange={e => {
                                    //     setModal({...modal,
                                    //       alternatemessage: e.target.value,
                                    //     })
                                    //   }
                                    // }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                              <Button
                                className="main-btn secondary-btn"
                                onClick={() => {
                                  this.setState({
                                    isOpen: false,
                                  })
                                }}
                                role="button"
                              >
                                <span>Cancel</span>
                              </Button>
                              <Button
                                type="submit"
                                color="primary"
                                className="main-btn pl-2 pr-2"
                                // onClick={()=>{
                                //   this.setState({
                                //     reminder : true,
                                //     isOpen:false,
                                //   })
                                // }}
                                // disabled={!selectedColor && !modal.colorId}
                              >
                                <span>Add</span>
                              </Button>{" "}
                            </div>
                          </AvForm>
                        }
                      </div>
                    </Modal>
                  </div>
                )}

                {this.state.tab == 2 && (
                  <div className="notes-tab">
                    <div className="notes-container">
                      <ul className="notes-list">
                        {this.props?.prospectData?.notes &&
                        this.props?.prospectData?.notes.length &&
                        !this.props?.prospectDataIsLoading ? (
                          this.props?.prospectData?.notes.map((item, index) => {
                            return (
                              <li key={index}>
                                <div>{item?.notes}</div>
                                <p>
                                  {moment
                                    .utc(item.createdAt)
                                    .local()
                                    .format("MM-DD-YYYY | hh-mm A")}
                                </p>
                              </li>
                            )
                          })
                        ) : this.props?.prospectDataIsLoading ? (
                          <div className="spinner-wr">
                            <Spinner style={{ color: "#5932ea" }} />
                          </div>
                        ) : (
                          <div className="chat-illus illus-empty-state">
                            <img src={illus} alt="" />
                            <h3>No Notes Added</h3>
                            {/* <p>Select prospect for detail view!</p> */}
                          </div>
                        )}
                      </ul>
                      <div>
                        <div className="seperator"></div>
                        <div className="notes-add-input-wrap">
                          <div className="form-group">
                            <div className="inp-grp">
                              <textarea
                                placeholder="Type message"
                                onChange={e => {
                                  this.setState({
                                    noteMsg: e.target.value.trim(),
                                  })
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <button
                            className={`main-btn success-btn ${
                              this.state.isLoading ||
                              !this.state.noteMsg?.trim()?.length
                                ? "disabled"
                                : null
                            }`}
                            onClick={() => {
                              this.createNotesHandler()
                            }}
                            disabled={
                              this.state.isLoading ||
                              !this.state.noteMsg?.trim()?.length
                            }
                          >
                            <img src={plusIcon} alt="" />
                            <span>Add</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.tab == 3 && (
                  <div className="activity-tab">
                    <div className="activity-container">
                      <ul className="activity-list">
                        {this.props?.prospectData?.activity &&
                        this.props?.prospectData?.activity?.length &&
                        !this.props?.prospectDataIsLoading ? (
                          this.props?.prospectData?.activity.map(
                            (item, index) => {
                              return (
                                <li key={index}>
                                  <div className="activity-icon">
                                    <img
                                      src={activityIcons[item.event]}
                                      alt=""
                                      className="img-fluid"
                                    />
                                  </div>

                                  <div className="body">
                                    <span></span>
                                    <span className="date-time">
                                      {moment
                                        .utc(item?.createdAt)
                                        .local()
                                        .format("MM-DD-YYYY | hh:mm A")}
                                    </span>
                                    <p className="comment">{item?.comment}</p>
                                  </div>
                                </li>
                              )
                            }
                          )
                        ) : this.props.prospectDataIsLoading ? (
                          <div className="spinner-wr">
                            <Spinner style={{ color: "#5932ea" }} />
                          </div>
                        ) : (
                          <div className="chat-illus illus-empty-state">
                            <img src={illus} alt="" />
                            <h3>No Activity Found</h3>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*               
              <label className="card-title pe-3">
                From:{" "}
                {this.props.data &&
                  this.props.data.number != undefined &&
                  this.props.data.number}
                <br />
              </label>
              <label className="card-title">
                To:{" "}
                {this.props.data &&
                  this.props.data.sender != undefined &&
                  this.props.data.sender}
              </label> */}

          <div className="chat-conversation">
            <SimpleBar style={{ height: "516px" }}>
              <ul
                className="conversation-list"
                data-simplebar
                style={{ maxHeight: "367px", padding: "0px" }}
              >
                <li className="clearfix">
                  <div className="conversation-text first-box">
                    <div className="chat-message ">
                      <div className="flee-user-name">
                        <div className="user-name-card">
                          {this.state.message?.conversations?.length > 1 ? (
                            <div className="d-flex align-items-center gap-1">
                              <span
                                className={`prospect-nav-button prev cursor-pointer ${
                                  this.state.activeConvIndex == 0
                                    ? "disabled-opacity"
                                    : ""
                                }`}
                                disabled={this.state.activeConvIndex == 0}
                                onClick={() => {
                                  if (this.state.activeConvIndex > 0) {
                                    this.setState({
                                      activeConvIndex:
                                        this.state.activeConvIndex - 1,
                                    })
                                    this.props.setActiveConvIndex(
                                      this.state.activeConvIndex - 1
                                    )
                                    // this.checkSystemNumber(
                                    //   this.props.data?.conversations?.[
                                    //     this.state.activeConvIndex - 1
                                    //   ]?.messages?.[0]?.systemNumber
                                    // )
                                  }
                                }}
                                title="Previous conversation"
                              >
                                <img
                                  src={arrowLeft}
                                  alt=""
                                  style={{ width: "17px" }}
                                />
                              </span>

                              <div className="user-short-name">
                                {this.props.data.prospect?.first_name
                                  ?.charAt(0)
                                  .toUpperCase() +
                                  this.props.data.prospect?.last_name
                                    ?.charAt(0)
                                    .toUpperCase()}
                              </div>
                              <span
                                className={`prospect-nav-button next cursor-pointer ${
                                  this.state.activeConvIndex ==
                                  this.state.message?.conversations?.length - 1
                                    ? "disabled-opacity"
                                    : ""
                                }`}
                                disabled={
                                  this.state.activeConvIndex ==
                                  this.state.message?.conversations?.length - 1
                                }
                                onClick={() => {
                                  if (
                                    this.state.activeConvIndex <
                                    this.state.message?.conversations?.length -
                                      1
                                  ) {
                                    this.setState({
                                      activeConvIndex:
                                        this.state.activeConvIndex + 1,
                                    })
                                    this.props.setActiveConvIndex(
                                      this.state.activeConvIndex + 1
                                    )

                                    // this.checkSystemNumber(
                                    //   this.props.data?.conversations?.[
                                    //     this.state.activeConvIndex + 1
                                    //   ]?.messages?.[0]?.systemNumber
                                    // )
                                  }
                                }}
                                title="Next conversation"
                              >
                                <img
                                  src={arrowRight}
                                  alt=""
                                  style={{ width: "17px" }}
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="user-short-name">
                              {this.props.data.prospect?.first_name
                                ?.charAt(0)
                                .toUpperCase() +
                                this.props.data.prospect?.last_name
                                  ?.charAt(0)
                                  .toUpperCase()}
                            </div>
                          )}
                          <div className="relative w-100">
                            <div className="d-flex align-items-center gap-2 flex-wrap ">
                              <span className="user-name">
                                {this.props.data.prospect?.first_name +
                                  " " +
                                  this.props.data.prospect?.last_name}
                              </span>
                              <a
                                className="link-wr"
                                href={
                                  `http://www.zillow.com/homes/${
                                    this.props.data?.prospect
                                      ?.property_address +
                                    " " +
                                    this.props.data?.prospect?.property_city +
                                    ", " +
                                    this.props.data?.prospect?.property_state +
                                    " " +
                                    this.props.data?.prospect?.property_zip
                                  }`
                                    .toString()
                                    .split(" ")
                                    .join("-") + "_rb"
                                }
                                target="_blank"
                              >
                                <img src={linkIcon} alt="" />
                                <span>View on Zillow</span>
                              </a>
                            </div>
                            <div className="d-flex justify-content-between w-100 align-items-center flex-wrap gap-2">
                              {this.state.message?.conversations ? (
                                <p>
                                  From :{" "}
                                  {
                                    this.state.message?.conversations?.[
                                      this.state.activeConvIndex
                                    ]?.systemNumber
                                  }
                                  &nbsp; To :&nbsp;
                                  {
                                    this.state.message?.conversations?.[
                                      this.state.activeConvIndex
                                    ]?.prospectNumber
                                  }
                                  <br />
                                  {[
                                    this.props.data.prospect.property_address &&
                                      this.props.data.prospect
                                        .property_address + ", ",
                                    this.props.data.prospect.property_city &&
                                      this.props.data.prospect.property_city +
                                        ", ",
                                    this.props.data.prospect.property_state &&
                                      this.props.data.prospect.property_state +
                                        ", ",
                                    this.props.data.prospect.property_zip &&
                                      this.props.data.prospect.property_zip,
                                  ]}
                                </p>
                              ) : null}

                              <div
                                className="select-wrap style-2 chat-right-part"
                                style={{ flex: "1 1 auto" }}
                              >
                                <img src={dropDownArrow} alt="" />
                                <Input
                                  type="select"
                                  className=" bg-transparent px-3"
                                  value={this.state.selected_status}
                                  onChange={e => {
                                    this.onChange(
                                      e.target.value,
                                      this.props.data.prospect._id
                                    )
                                    this.setState({
                                      selected_status: e.target.value,
                                    })
                                  }}
                                >
                                  {this.props.leads.length > 0 &&
                                    this.props.leads.map((lead, index) => (
                                      <option value={lead._id} key={index}>
                                        {lead.lead_Stage}
                                      </option>
                                    ))}
                                </Input>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          className="has-drop"
                          onClick={() => this.setState({ sidebar: true })}
                        >
                          <img src={threeDot} alt="" />
                        </button>
                      </div>

                      <div className={"chat-tag-wrap-container"}>
                        {!this.props.hide && (
                          <div className="chat-tag-wrap">
                            {this.props.data.prospect.dnc ? (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"danger"}
                                onClick={e => {
                                  this.props.setDNC(
                                    this.props.data.prospect._id,
                                    false
                                  )
                                }}
                                title="Remove From DNC"
                              >
                                <i className="fas fa-phone-slash" />

                                <span>DNC</span>
                              </Button>
                            ) : (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"outline-secondary"}
                                onClick={e => {
                                  this.props.setDNC(
                                    this.props.data.prospect._id,
                                    true
                                  )
                                }}
                                title="Add To DNC"
                              >
                                <i className="fas fa-phone-slash" />
                                <span>DNC</span>
                              </Button>
                            )}{" "}
                            {this.props.data.prospect.verified === false ? (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"outline-secondary"}
                                onClick={() =>
                                  this.props.verifiedNumber(
                                    this.props.data.prospect._id,
                                    this.props.data?.prospect?.verified
                                  )
                                }
                                title="Add to Verified"
                              >
                                <i className="fas fa-check" />
                                <span>Verified</span>
                              </Button>
                            ) : (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"success"}
                                onClick={() =>
                                  this.props.verifiedNumber(
                                    this.props.data.prospect._id,
                                    this.props.data?.prospect?.verified
                                  )
                                }
                                title="Remove From Verified"
                              >
                                <i className="fas fa-check" />
                                <span>Verified</span>
                              </Button>
                            )}{" "}
                            {this.props.data.prospect.Wrong_Number === false ? (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"outline-secondary"}
                                onClick={() =>
                                  this.props.wrongNumber(
                                    this.props.data.prospect._id,
                                    this.props.data.prospect.Wrong_Number
                                  )
                                }
                                title="Add To Wrong Number"
                              >
                                <i className="fas fa-times" />
                                <span>Wrong Number</span>
                              </Button>
                            ) : (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"primary"}
                                onClick={() =>
                                  this.props.wrongNumber(
                                    this.props.data.prospect._id,
                                    this.props.data.prospect.Wrong_Number
                                  )
                                }
                                title="Remove From Wrong Number"
                              >
                                <i className="fas fa-times" />
                                <span>Wrong Number</span>
                              </Button>
                            )}{" "}
                            {this.props.data.prospect.isLead === false ? (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"outline-secondary"}
                                onClick={() =>
                                  this.props.qualifiedNumber(
                                    this.props.data.prospect._id,
                                    this.props.data.prospect.isLead
                                  )
                                }
                                title="Add To Qualified"
                              >
                                <i className="fas fa-star" />
                                <span>Qualified</span>
                              </Button>
                            ) : (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"info"}
                                onClick={() =>
                                  this.props.qualifiedNumber(
                                    this.props.data.prospect._id,
                                    this.props.data.prospect.isLead
                                  )
                                }
                                title="Remove From Qualified"
                              >
                                <i className="fas fa-star" />
                                <span>Qualified</span>
                              </Button>
                            )}{" "}
                            {this.props.data.prospect.isPriority === false ? (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"outline-secondary"}
                                onClick={() =>
                                  this.props.priorityNumber(
                                    this.props.data.prospect._id,
                                    this.props.data.prospect.isPriority
                                  )
                                }
                                title="Add To Priority"
                              >
                                <i className="fas fa-bolt" />
                                <span>Priority</span>
                              </Button>
                            ) : (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"warning"}
                                onClick={() =>
                                  this.props.priorityNumber(
                                    this.props.data.prospect._id,
                                    this.props.data.prospect.isPriority
                                  )
                                }
                                title="Remove From Priority"
                              >
                                <i className="fas fa-bolt" />
                                <span>Priority</span>
                              </Button>
                            )}{" "}
                            {this.props.data.prospect.isDripAdded === false ? (
                              <Button
                                className={"botton-btn me-1 "}
                                color={"outline-secondary"}
                                onClick={() => {
                                  // this.tog_drip()
                                  this.props.tog_drip(
                                    this?.props?.data?.prospect._id
                                  )
                                  this.setState({
                                    dripProspectId:
                                      this?.props?.data?.prospect?._id,
                                  })
                                }}
                                title="Add To Drip"
                              >
                                <img
                                  src={DripVaccent}
                                  alt=""
                                  style={{ marginBottom: "2px" }}
                                />
                                <span> Drip</span>
                              </Button>
                            ) : (
                              <Button
                                className={"botton-btn me-1 dripColor "}
                                color={"primary"}
                                backgroundColor={"#5932ea"}
                                onClick={() => {
                                  const dripTemplateData =
                                    this?.props?.data?.prospect
                                      ?.dripTemplateData
                                  const dripData =
                                    this?.props?.data?.prospect?.dripData
                                  const dripProspectId =
                                    this?.props?.data?.prospect?._id
                                  this.props.togRemoveDrip(
                                    dripProspectId,
                                    dripData,
                                    dripTemplateData
                                  )
                                }}
                                title="Remove From Drip"
                              >
                                {/* <img
                                  src={DripVaccent}
                                  alt=""
                                  style={{ marginBottom: "2px" , fill:'red' }}
                                />{" "} */}
                                <svg
                                  marginBottom="2px"
                                  width="14"
                                  height="17"
                                  viewBox="0 0 14 17"
                                  fill="white"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.46846 0.998848C7.19846 0.787168 6.82348 0.787168 6.55348 0.998848C5.12848 2.09505 0.920949 5.67093 0.943449 9.83649C0.943449 13.2082 3.66598 15.9601 7.01848 15.9601C10.371 15.9601 13.0935 13.2158 13.0935 9.84405C13.101 5.73897 8.88596 2.10261 7.46846 0.998848Z"
                                    fill="white"
                                  />
                                  <path
                                    d="M7.00977 0.840088V15.9601"
                                    stroke="#5932ea"
                                    stroke-width="1.125"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.00977 13.6617L12.7848 10.8342"
                                    stroke="#5932ea"
                                    stroke-width="1.125"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.00977 9.88177L12.5373 7.17529"
                                    stroke="#5932ea"
                                    stroke-width="1.125"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.00977 6.10171L10.7823 4.24951"
                                    stroke="#5932ea"
                                    stroke-width="1.125"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>{" "}
                                <span>Drip</span>
                              </Button>
                            )}{" "}
                            {this.state.message?.conversations?.[
                              this.state.activeConvIndex
                            ]?.unreadCount ? (
                              <Button
                                // className={"mt-2"}
                                className={"botton-btn me-1 "}
                                onClick={() =>
                                  this.changesstatus(this.state.message._id)
                                }
                                color="primary"
                              >
                                <i className={"fas fa-envelope-open pe-1"}></i>{" "}
                                Mark As Read
                              </Button>
                            ) : null}
                          </div>
                        )}
                        {/* <FormGroup
                          className={"mb-3 mt-2 form-group select-r"}
                          onClick={() => {}}
                        >
                          <Select
                            validate={{
                              required: true,
                            }}
                           
                            onChange={e => {
                              this.onChange(e, this.props.data.prospect._id)
                              this.setState({ selected_status: e.target.value })
                            }}
                            value={this.state.selected_status}
                        
                            options={this.props.leads.length > 0 && this.props.leads}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup> */}
                        {/* <div className="select-wrap style-2">
                          <img src={dropDownArrow} alt="" />
                          <Input
                            type="select"
                            className=" bg-transparent px-3"
                            value={this.state.selected_status}
                            onChange={e => {
                              this.onChange(e, this.props.data.prospect._id)
                              this.setState({ selected_status: e.target.value })
                            }}
                          >
                            {this.props.leads.length > 0 &&
                              this.props.leads.map((lead, index) => (
                                <option value={lead._id} key={index}>
                                  {lead.lead_Stage}
                                </option>
                              ))}
                          </Input>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </li>
                {this.state.message &&
                  this.state.message?.conversations?.length &&
                  this.state.message?.conversations[
                    this.state.activeConvIndex
                  ]?.messages.map((item, index) => (
                    <li
                      // className={
                      //   "clearfix " +
                      //   ((item.status == "delivered" || item.status == "queued") ? "odd" : "")
                      // }
                      className={`clearfix ${
                        item?.status !== "received" && "odd"
                      }`}
                      key={item._id}
                    >
                      <div className="conversation-text mb-3">
                        <div
                          className={
                            index ==
                              this.state.message?.conversations?.[
                                this.state.activeConvIndex
                              ]?.messages?.length -
                                1 &&
                            this.state.message?.conversations?.[
                              this.state.activeConvIndex
                            ]?.unreadCount
                              ? "chat-message new-mg"
                              : "chat-message"
                          }
                        >
                          {index ==
                            this.state.message?.conversations?.[
                              this.state.activeConvIndex
                            ]?.messages?.length -
                              1 &&
                          this.state.message?.conversations?.[
                            this.state.activeConvIndex
                          ]?.unreadCount ? (
                            <div className="new-text ms-3">New</div>
                          ) : null}
                          <p>{item.body}</p>
                        </div>
                        <div className="mt-1 chat-bottom-text">
                          {moment(item.createdAt)
                            .local()
                            .format("MM-DD-YYYY | hh:mm A")}
                          &nbsp;|&nbsp;
                          {item.status.charAt(0).toUpperCase() +
                            item.status.slice(1)}
                        </div>
                      </div>
                    </li>
                  ))}
                {/* <li ref={this.convRef} /> */}
              </ul>
            </SimpleBar>

            <AvForm
              key={this.state.reloadKey}
              className={`needs-validation validation-none chat-msg-form  ${
                this.state.checkedNumber?.length &&
                (this.state?.checkedNumber[this.state.activeConvIndex]
                  ?.status !== "Active" ||
                  this.state.checkedNumber[this.state.activeConvIndex]
                    ?.status === "NotAvailable")
                  ? "pt-1"
                  : ""
              }`}
              onValidSubmit={(e, v) => {
                this.handleValidSubmit(e, v)
              }}
            >
              <div style={{ marginBottom: "8px" }}>
                {
                  // this.state.checkedNumber.length &&
                  // (this.state?.checkedNumber[this.state.activeConvIndex]
                  //   ?.status !== "Active" ||
                  //   this.state.checkedNumber[this.state.activeConvIndex]
                  //     ?.status === "NotAvailable")

                  !this.state?.checkedNumber?.some(
                    item =>
                      item.number ==
                        this.state.message?.conversations?.[
                          this.state.activeConvIndex
                        ]?.messages[0]?.systemNumber &&
                      item?.status === "Active"
                  ) ? (
                    <span className="text-danger ">
                      <img
                        src={Exclamation}
                        style={{ width: "18px", height: "18px" }}
                        alt=""
                      />
                      <strong>
                        The System Number for this conversation is{" "}
                        {this.state?.checkedNumber?.some(
                          item =>
                            item.number ==
                            this.state.message?.conversations?.[
                              this.state.activeConvIndex
                            ]?.messages[0]?.systemNumber
                        )
                          ? "inactive!"
                          : "not available! "}
                      </strong>
                    </span>
                  ) : null
                }
                {!this.state?.checkedNumber?.some(
                  item =>
                    item.number ==
                      this.state.message?.conversations?.[
                        this.state.activeConvIndex
                      ]?.messages[0]?.systemNumber && item?.status === "Active"
                ) ? (
                  this.state.anotherActiveNumber !== null ? (
                    <strong
                      className="text-primary"
                      style={{
                        textDecoration: "underLine",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.setState({
                          activeConvIndex: this.state.anotherActiveNumber,
                        })
                      }}
                    >
                      {" "}
                      Start conversation with active number{" "}
                    </strong>
                  ) : (
                    <strong
                      className="d-flex justify-content-flex-start align-items-center gap-2 mt-1  "
                      style={{
                        display: "block",
                      }}
                    >
                      {" "}
                      <Switch
                        // onChange={checked => {
                        //   this.setState(prevState => ({
                        //     followupCampaignModal: {
                        //       ...prevState.followupCampaignModal,
                        //       isRetain: checked,
                        //     },
                        //   }))
                        // }}
                        onChange={e => {
                          this.setState({
                            createNewConv: e,
                          })
                        }}
                        checked={this.state?.createNewConv}
                        name="toggleFor new conversation"
                        uncheckedIcon={false} // Hide the unchecked icon
                        checkedIcon={false} // Hide the checked icon
                        height={20}
                        width={35}
                        onColor="#5932ea"
                        offColor="#d3d3d3"
                      />
                      Start conversation with new number{" "}
                    </strong>
                  )
                ) : null}
              </div>

              <div className=" d-flex gap-2">
                <div className="flex-grow-1 chat-inputbar">
                  <div className="form-group">
                    <div className="inp-grp">
                      <AvField
                        name={"message"}
                        type="textarea"
                        className="chat-input mb-0"
                        placeholder="Type a message"
                        required
                        innerRef={this.sendTextInput}
                        value={this.state.send}
                        // validate={{ required: { value: this.state.textlength?false:true,errorMessage: 'Text field is required'} }}
                        validate={{
                          required: {
                            value: this.state.textlength ? false : true,
                            errorMessage: "Text field is required",
                          },
                          // minLength:{value: -1, errorMessage: 'ext length must be less than 320'},
                          // maxLength: {value: 320, errorMessage: 'ext length must be less than 320'}
                        }}
                        onChange={e => {
                          this.setState({
                            send: e.target.value,
                            textlength: e.target.value.length,
                          })
                          this.props.updateSendText(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <span style={{ float: "right", fontSize: "12px" }}>
                    {this.state?.send.trim()?.length <= 160 ? (
                      <i
                        className="fas fa-check-circle fa-sm me-1"
                        style={{ color: "Green" }}
                      ></i>
                    ) : null}
                    {this.state.send.trim()?.length <= 320 &&
                    this.state.send.trim()?.length > 160 ? (
                      <i
                        className="fas fa-exclamation-circle fa-sm me-1"
                        style={{ color: "orange" }}
                      ></i>
                    ) : null}
                    {this.state.send.trim()?.length > 320 ? (
                      <i
                        className="fas fa-times-circle fa-sm me-1"
                        style={{ color: "red" }}
                      ></i>
                    ) : null}
                    {this.state.send.trim()?.length
                      ? this.state.send.trim()?.length
                      : 0}
                    /320
                  </span>
                </div>
                <div className=" chat-send">
                  <div className="">
                    <button
                      id={"Popover1"}
                      className={"has-icon main-btn"}
                      title={"Quick reply"}
                      onClick={e => {
                        e.preventDefault() // Add this line to prevent form submission
                        this.setState({
                          popoverOpen: !this.state.popoverOpen,
                        })
                      }}
                    >
                      <img src={astrixIcon} alt="" />
                    </button>

                    {/* <Popover
                      placement="bottom"
                      isOpen={this.state.popoverOpen}
                      target="Popover1"
                      toggle={() => {
                        this.setState({
                          popoverOpen: !this.state.popoverOpen,
                        })
                      }}
                    > */}
                    {/* <PopoverHeader>Quick Reply</PopoverHeader>
                      <PopoverBody>
                        {this.props.quickReplies != null &&
                          this.props.quickReplies.length > 0 &&
                          this.props.quickReplies.map(item => (
                            <div
                              className="btn btn-link"
                              onClick={() => this.setTextBody(item.message)}
                              key={item._id}
                            >
                              {item.message}
                            </div>
                          ))}
                      </PopoverBody> */}
                    {/* </Popover> */}

                    {/* {console.log(this.props.data.prospect.litigator  ,'litigator updated')} */}
                    <button
                      type="submit"
                      color="success"
                      className={`main-btn success-btn ${
                        // this?.props?.data?.prospect?.litigator ||
                        // this.props.data.prospect.dnc ||
                        // this.props.data.prospect.Wrong_Number ||
                        // !this.state.send?.trim()?.length ||
                        // this.state.checkedNumber[this.state.activeConvIndex]
                        //   ?.status !== "Active" ||
                        // this.state.checkedNumber[this.state.activeConvIndex]
                        //   ?.status === "NotAvailable"
                        //   ? "disabled"
                        //   : null

                        (this?.props?.data?.prospect?.litigator ||
                          this?.props?.data?.prospect?.dnc ||
                          this?.props?.data?.prospect?.Wrong_Number ||
                          !this.state.send?.trim()?.length ||  !this.validMessage(this.state.send) ||
                          !this.state.checkedNumber?.some(
                            item =>
                              item.number ==
                                this.state.message?.conversations?.[
                                  this.state.activeConvIndex
                                ]?.messages[0]?.systemNumber &&
                              item?.status === "Active"
                          )) &&
                        !this.state?.createNewConv
                          ? "disabled"
                          : null
                        // }`}
                      }`}
                      disabled={
                        (this?.props?.data?.prospect?.litigator ||
                          this?.props?.data?.prospect?.dnc ||
                          this?.props?.data?.prospect?.Wrong_Number ||
                          !this.state.send?.trim()?.length || !this.validMessage(this.state.send) ||
                          !this.state.checkedNumber?.some(
                            item =>
                              item.number ==
                                this.state.message?.conversations?.[
                                  this.state.activeConvIndex
                                ]?.messages[0]?.systemNumber &&
                              item?.status === "Active"
                          )) &&
                        !this.state?.createNewConv
                      }
                      title={
                        this.props.data?.prospect?.litigator ||
                        this.props.data?.prospect?.dnc ||
                        this.props.data?.prospect?.Wrong_Number
                          ? this.props.data?.prospect?.litigator &&
                            this.props.data?.prospect?.dnc &&
                            this.props.data?.prospect?.Wrong_Number
                            ? "Attempt to send to wrong number, DNC and unsubscribed recipient"
                            : this.props.data?.prospect?.dnc &&
                              this.props.data?.prospect?.litigator
                            ? "Attempt to send to DNC and unsubscribed recipient"
                            : this.props.data?.prospect?.Wrong_Number &&
                              this.props.data?.prospect?.litigator
                            ? "Attempt to send to wrong number and unsubscribed recipient"
                            : this.props.data.prospect.Wrong_Number &&
                              this.props.data.prospect.dnc
                            ? "Attempt to send to wrong number and DNC"
                            : this.props.data.prospect.litigator
                            ? "Attempt to send to unsubscribed recipient"
                            : this.props.data.prospect.dnc
                            ? "Attempt to send to DNC"
                            : this.props.data.prospect.Wrong_Number
                            ? "Attempt to send to wrong number"
                            : null
                          : !this.validMessage(this.state.send) ? 'No data available for replacement with merge field '
                          : null
                      }
                    >
                      {/* {this.state.checkNumberLoading ? 
                          <Spinner style={{ color: "#5932ea" }} />
                        : null} */}{" "}
                      {this.state.checkNumberLoading ? (
                        <img
                          src={chatLoading}
                          alt=""
                          style={{ width: "21px", height: "21px" }}
                        />
                      ) : (
                        <img src={sendIcon} alt="" />
                      )}
                      <span className={"d-none d-lg-inline-block"}>
                        {this.state.checkNumberLoading ? "Loading" : "Send"}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </AvForm>

            {this.state.dripRemoveConfirm?.isOpen ? (
              <SweetAlert
                title="Are you sure?"
                info
                showCancel
                confirmBtnText="Yes, remove it"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="dark"
                onConfirm={() => {
                  this.removeDrip()
                }}
                onCancel={() => {
                  this.setState({
                    dripRemoveConfirm: { isOpen: false },
                  })
                }}
              >
                <div className="d-flex flex-column align-items-start gap-1 p-2 border-bottom mb-2">
                  {/* <div>
                    <strong>Drip Name: </strong>{" "}
                    {this?.state?.dripRemoveConfirm?.dripTemplateData?.name}{" "}
                  </div> */}
                  <div>
                    <strong>Drip Start Date: </strong>{" "}
                    {moment
                      .utc(
                        this?.state?.dripRemoveConfirm?.dripData?.dripStartDate
                      )
                      .local()
                      .format("YYYY-MM-DD hh:mm:ss A")}
                  </div>
                </div>
                You want to remove drip? You won't be able to revert this!
              </SweetAlert>
            ) : null}

            <Modal
              isOpen={this.state.drip_modal}
              toggle={() => {
                this.tog_drip()
              }}
              backdrop="static"
              centered={true}
              className="drip-select-modal"
              key={this.state.dripReloadKey}
            >
              <div className="modal-header">
                <h5 className="modal-title mt-0">Select A Drip Automations</h5>
                <button
                  type="button"
                  onClick={() => {
                    this.setState({
                      drip_modal: false,
                      dripTemplateDetail: {
                        isOpen: false,
                        id: null,
                        data: {},
                      },
                    })
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="drip-select-container">
                  <div key={this.state.dripReloadKey}>
                    {!this.state?.dripTemplateDetail?.isOpen ? (
                      <div className="drip-aut-list">
                        <table className="c-table table">
                          <thead>
                            <tr>
                              <th>Drip Name</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dripTemplates &&
                              this.state.dripTemplates.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>
                                      <button
                                        className="main-btn sm secondary-btn min-btn"
                                        onClick={() => {
                                          this.getDripTemplateDetail(item._id)
                                        }}
                                      >
                                        <span>View Messages</span>
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="main-btn sm white-icon min-btn"
                                        onClick={() => {
                                          this.addDrip(item._id)
                                        }}
                                      >
                                        <img src={dripIconBorder} alt="" />
                                        <span>Select</span>
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="drip-messages-container">
                        <h3 className="head">
                          {this.state.dripTemplateDetail?.data?.name}
                        </h3>
                        <div className="drip-message-main-container">
                          {this.state.dripTemplateDetail?.data?.data.map(
                            (item, index) => {
                              return (
                                <div className="drip-message-wrap" key={index}>
                                  <div className="top">
                                    <h3 className="head">
                                      Message {index + 1}
                                    </h3>
                                    <span>Day {item.day}</span>
                                  </div>
                                  <p className="para">{item.message}</p>
                                </div>
                              )
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* drip automations END*** */}

                  {/* drip automation messages */}

                  {/* drip automation messages END*** */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => {
                    if (this.state.dripTemplateDetail?.isOpen) {
                      this.setState({
                        dripTemplateDetail: {
                          isOpen: false,
                          id: null,
                          data: {},
                        },
                      })
                    } else {
                      this.setState({
                        drip_modal: false,
                        dripTemplateDetail: {
                          isOpen: false,
                          id: null,
                          data: {},
                        },
                      })
                    }
                  }}
                >
                  {this.state.dripTemplateDetail?.isOpen ? "Back" : "Cancel"}
                </button>
                {this.state.dripTemplateDetail?.isOpen ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      this.addDrip()
                    }}
                  >
                    Select
                  </button>
                ) : null}
              </div>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Conversations.propTypes = { pushToWebhook: PropTypes.func }

const mapStatetoProps = state => {
  const { pushToWebhook } = state.Prospects
  return {
    pushToWebhook,
  }
}

export default withRouter(
  connect(mapStatetoProps, {
    pushToWebhook,
  })(Conversations)
)
