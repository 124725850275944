import { takeEvery, fork, put, all, call } from "redux-saga/effects"
import { Redirect } from "react-router-dom"
// Login Redux States
import {
  GET_CAMPAIGNS,
  GET_FOLLOW_UP_CAMPAIGNS,
  EDIT_CAMPAIGN,
  ADD_CAMPAIGN,
  DELETE_CAMPAIGN,
  GET_CAMPAIGN,
  GET_COUNT_STATS,
  DOWNLOAD_COUNT_STATS_STATS,
  GET_CAMPAIGN_METRICS,
  GET_CAMPAIGN_COUNT_PROSPECTS,
  GET_BATCH,
  GET_CLIENT_DATA,
  COUNT_TEMPLATE,
  SEND_MESSAGE,
  UPDATE_MESSAGE_STATUS_PROS,
  AUTO_SEND_CAMP,
  CANCEL_AUTO_SEND_CAMP,
  GET_UPLOADS_HISTROY,
  GET_SCHEDULER,
  UPDATE_SCHEDULER,
  CREATE_SCHEDULER,
  CANCEL_SCHEDULE,
  PAUSE_SCHEDULE,
  START_SCHEDULE,
  EXPORT_CAMP_PROS_DATA,
  EXIST_PROSPECTS_SUCCESS,
  EXIST_PROSPECTS,
  UPLOAD_PROSPECTS,
  UPLOAD_PROSPECTS_FILE,
  FILTER_PROSPECTS,
  CAMPAIGN_FILTER_PROSPECTS,
} from "./actionTypes"

import {
  addCampaignSuccess,
  editCampaignSuccess,
  getCampaignsError,
  getCampaignsSuccess,
  getFollowUpCampaignsSuccess,
  getMarketsSuccess,
  getCampaignSuccess,
  getCountStatsSuccess,
  getCampaignMetricsSuccess,
  countProspectsSuccess,
  getBatchSuccess,
  getTemplatesbycatSuccess,
  countTempletebyCatSuccess,
  sendPropectsSuccess,
  autoSendCampSuccess,
  getUploadsHistroySuccess,
  getSchedulerSuccess,
  exportCampProsDataSuccess,
  existsProspectSuccess,
  uploadProspectsFileSuccess,
  uploadProspectsSuccess,
  campaignFilterProspectsSuccess,
  //for update props
  update_props,
} from "./actions"

import {
  addCampaign,
  deleteCampaign,
  editCampaign,
  getCampaigns,
  getFollowUpCampaigns,
  getMarkets,
  getCampaign,
  getCampaignCountStatus,
  downloadCampaignCountStatus,
  getCampaignMetrics,
  getCampaignCountProspects,
  getBatch,
  getTemplatesByCat,
  countTempletebyCat,
  sendMessage,
  prospectUpdatestatus,
  campaignUpdatecampskip,
  campaignUpdatecamp,
  autoSendCamp,
  cancelAutoSendCamp,
  getCampaignUploadsHistroy,
  getScheduler,
  updateScheduler,
  createScheduler,
  cancelSchedule,
  pauseSchedule,
  startSchedule,
  exportCampProsData,
  existsProspects,
  uploadProspects,
  uploadProspectsFile,
  CampaignFilterProspects,
} from "../../helpers/fakebackend_helper"

const delay = ms => new Promise(res => setTimeout(res, ms))

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* getCampaignsApi() {
  try {
    const response = yield call(getCampaigns)
    yield put(getCampaignsSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getFollowUpCampaignsApi() {
  try {
    const response = yield call(getFollowUpCampaigns)
    yield put(getFollowUpCampaignsSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getMarketsApi() {
  try {
    const response = yield call(getMarkets)
    yield put(getMarketsSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* addCampaignApi({ payload: campaign }) {
  try {
    const response = yield call(addCampaign, campaign)
    yield put(addCampaignSuccess("Campaign added successfully"))
    const response1 = yield call(getCampaigns)
    yield put(getCampaignsSuccess(response1.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* editCampaignApi({ payload: campaign }) {
  try {
    const response = yield call(editCampaign, campaign)
    yield put(editCampaignSuccess("Campaign updated successfully"))
    yield delay(1000)
    if(response?.data?.isFollowUp){
      const response1 = yield call(getFollowUpCampaigns)
      yield put(getFollowUpCampaignsSuccess(response1.data))
    }else{
      const response1 = yield call(getCampaigns)
      yield put(getCampaignsSuccess(response1.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* deleteCampaignApi({ payload: campaign }) {
  try {
    const response = yield call(deleteCampaign, campaign)
    yield put(editCampaignSuccess("Campaign deleted successfully"))
    yield delay(1000)
    if(response?.data?.isFollowUp){
      const response1 = yield call(getFollowUpCampaigns)
      yield put(getFollowUpCampaignsSuccess(response1.data))
    }else{
      const response1 = yield call(getCampaigns)
      yield put(getCampaignsSuccess(response1.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getCampaignApi({ payload: id }) {
  try {
    const response = yield call(getCampaign, id)
    yield put(getCampaignSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getCountStatusApi({ payload: id }) {
  try {
    const response = yield call(getCampaignCountStatus, id)
    yield put(getCountStatsSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* downloadCountStatsStatsApi({ payload: data }) {
  try {
    yield put(addCampaignSuccess("Processing"))
    const response = yield call(downloadCampaignCountStatus, data)
    yield put(
      addCampaignSuccess("Process completed please see the notification.")
    )
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getCampaignMetricsApi({ payload: id }) {
  try {
    const response = yield call(getCampaignMetrics, id)
    yield put(getCampaignMetricsSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getCampaignCountProspectsApi({ payload: id }) {
  try {
    const response = yield call(getCampaignCountProspects, id)
    yield put(countProspectsSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getBatchApi({ payload: id }) {
  try {
    const response = yield call(getBatch, id)
    yield put(getBatchSuccess(response.data))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getTemplatesByCatApi({ payload: data }) {
  try {
    if (data) {
      const response = yield call(getTemplatesByCat, data)
      yield put(getTemplatesbycatSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* countTempletebyCatApi({ payload: data }) {
  try {
    if (data) {
      const response = yield call(countTempletebyCat, data)
      yield put(countTempletebyCatSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* updatestatuspros(
  id,
  cat,
  camp,
  count,
  procount,
  bulk,
  status = "skip"
) {
  try {
    yield call(prospectUpdatestatus, {
      id: id,
      camp: camp,
    })

    if (status === "skip") {
      let data1 = {
        camp: camp,
        procount: procount,
      }
      yield call(campaignUpdatecampskip, data1)

      let data2 = {
        id: cat,
        camp: camp,
        count: count,
        procount: procount,
        bulkid: bulk,
      }
      const response = yield call(getTemplatesByCat, data2)
      yield put(getTemplatesbycatSuccess(response.data))
    } else {
      let response
      // if (bulk == null) {
      //   response = yield call(getBatch, camp)
      //   yield put(getBatchSuccess(response.data))
      // }

      let data = {
        camp: camp,
        procount: procount,
        // bulk:
        //   bulk == null &&
        //   response &&
        //   response.data != undefined &&
        //   response.data.uid != undefined
        //     ? response.data.uid
        //     : bulk,
      }
      yield call(campaignUpdatecamp, data)

      let templateData = {
        id: id,
        camp: camp,
        count: count,
        procount: procount,
        bulkid: bulk,
      }
      response = yield call(getTemplatesByCat, templateData)
      yield put(getTemplatesbycatSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* sendMessageApi({ payload: data }) {
  try {
    let message_data = {
      body: data.body,
      phone: data.phone,
      prs: data.prosid,
      camp: data.camp,
      procount: data.procount,
      market: data.market,
      bulk: data.bulkid,
    }

    // if((data.procount - 1 == 0) || ((data.procount - 1) % 100 == 0)) {
    //   try {
    //     const response = yield call(getBatch,data.camp)
    //     message_data.bulk = data.bulkid == null ?  response.data.uid : data.bulkid;
    //   } catch (e){
    //     message_data.bulk = data.bulkid;
    //   }
    // }

    yield call(sendMessage, message_data)

    yield call(
      updatestatuspros,
      data.prosid,
      data.id,
      data.camp,
      data.count,
      data.procount,
      message_data.bulk
    )
    yield put(sendPropectsSuccess(data.send_count))
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* updateStatusProsApi({ payload: data }) {
  yield call(
    updatestatuspros,
    data.prosid,
    data.id,
    data.camp,
    data.count,
    data.procount,
    data.bulk,
    data.skip
  )
}

function* autoSendCampApi({ payload: data }) {
  try {
    if (data) {
      yield call(autoSendCamp, data)
      yield put(autoSendCampSuccess("Processing"))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* cancelAutoSendCampApi({ payload: data }) {
  try {
    if (data) {
      yield call(cancelAutoSendCamp, data)
      yield put(autoSendCampSuccess("Cancelled"))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getUploadsHistroyApi({ payload: data }) {
  try {
    if (data) {
      const response = yield call(getCampaignUploadsHistroy, data)
      yield put(getUploadsHistroySuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* getSchedulerApi({ payload: data }) {
  try {
    if (data) {
      const response = yield call(getScheduler, data)
      yield put(getSchedulerSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* updateSchedulerApi({ payload: data }) {
  try {
    if (data) {
      yield call(updateScheduler, data)

      const response = yield call(getScheduler, data.camp)
      yield put(getSchedulerSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* existProspectsApi({ payload: data }) {
  try {
    if (data) {
      yield call(existsProspects, data)
      yield put(existsProspectSuccess("Lead stage updated successfully."))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* uploadProspectsFileApi({ payload: data }) {
  try {
    if (data) {
      const response = yield call(uploadProspectsFile, data)
      yield put(uploadProspectsFileSuccess(response.data))
      yield put(getCampaignsError(null))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* uploadProspectsApi({ payload: data }) {
  try {
    if (data) {
      const response = yield call(uploadProspects, data)
      yield put(uploadProspectsSuccess("Prospects uploaded successfully."))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* filterProspectsApi({ payload: data }) {
  try {
    if (data) {
      const response = yield call(CampaignFilterProspects, data)
      yield put(
        campaignFilterProspectsSuccess("Follow Up created successfully.")
      )
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* createSchedulerApi({ payload: data }) {
  try {
    if (data) {
      yield call(createScheduler, data)

      const response = yield call(getScheduler, data.id)
      yield put(getSchedulerSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* cancelScheduleApi({ payload: data }) {
  try {
    if (data) {
      yield call(cancelSchedule, data)

      const response = yield call(getScheduler, data.camp)
      yield put(getSchedulerSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* pauseScheduleApi({ payload: data }) {
  try {
    if (data) {
      yield call(pauseSchedule, data)

      const response = yield call(getScheduler, data.camp)
      yield put(getSchedulerSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* startScheduleApi({ payload: data }) {
  try {
    if (data) {
      yield call(startSchedule, data)

      const response = yield call(getScheduler, data.camp)
      yield put(getSchedulerSuccess(response.data))
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

function* exportCampProsDataApi({ payload: data }) {
  try {
    if (data) {
      yield call(exportCampProsData, data)
      yield put(
        exportCampProsDataSuccess("File is Processing, Please See Notification")
      )
    }
  } catch (error) {
    yield put(getCampaignsError(error))
  }
}

export function* watchCampaigns() {
  yield takeEvery(GET_CAMPAIGNS, getCampaignsApi)
  yield takeEvery(GET_FOLLOW_UP_CAMPAIGNS, getFollowUpCampaignsApi)
  //yield takeEvery(GET_MARKETS, getMarketsApi)
  yield takeEvery(EDIT_CAMPAIGN, editCampaignApi)
  yield takeEvery(ADD_CAMPAIGN, addCampaignApi)
  yield takeEvery(DELETE_CAMPAIGN, deleteCampaignApi)
  yield takeEvery(GET_CAMPAIGN, getCampaignApi)
  yield takeEvery(GET_COUNT_STATS, getCountStatusApi)
  yield takeEvery(DOWNLOAD_COUNT_STATS_STATS, downloadCountStatsStatsApi)
  yield takeEvery(GET_CAMPAIGN_METRICS, getCampaignMetricsApi)
  yield takeEvery(GET_CAMPAIGN_COUNT_PROSPECTS, getCampaignCountProspectsApi)
  yield takeEvery(GET_BATCH, getBatchApi)
  yield takeEvery(GET_CLIENT_DATA, getTemplatesByCatApi)
  yield takeEvery(COUNT_TEMPLATE, countTempletebyCatApi)
  yield takeEvery(SEND_MESSAGE, sendMessageApi)
  yield takeEvery(UPDATE_MESSAGE_STATUS_PROS, updateStatusProsApi)
  yield takeEvery(AUTO_SEND_CAMP, autoSendCampApi)
  yield takeEvery(CANCEL_AUTO_SEND_CAMP, cancelAutoSendCampApi)
  yield takeEvery(GET_UPLOADS_HISTROY, getUploadsHistroyApi)
  yield takeEvery(GET_SCHEDULER, getSchedulerApi)
  yield takeEvery(UPDATE_SCHEDULER, updateSchedulerApi)
  yield takeEvery(CREATE_SCHEDULER, createSchedulerApi)
  yield takeEvery(CANCEL_SCHEDULE, cancelScheduleApi)
  yield takeEvery(PAUSE_SCHEDULE, pauseScheduleApi)
  yield takeEvery(START_SCHEDULE, startScheduleApi)
  yield takeEvery(EXPORT_CAMP_PROS_DATA, exportCampProsDataApi)

  yield takeEvery(EXIST_PROSPECTS, existProspectsApi)
  yield takeEvery(UPLOAD_PROSPECTS_FILE, uploadProspectsFileApi)
  yield takeEvery(UPLOAD_PROSPECTS, uploadProspectsApi)
  yield takeEvery(CAMPAIGN_FILTER_PROSPECTS, filterProspectsApi)
}

function* CampaignsSaga() {
  yield all([fork(watchCampaigns)])
}

export default CampaignsSaga
