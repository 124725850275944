import React from "react";
import _debounce from "lodash.debounce"
import MetaTags from "react-meta-tags";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardTitle,
    Col, Dropdown, DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Modal,
    Row
} from "reactstrap";
import {Link, Redirect, withRouter} from "react-router-dom";
import {AvField, AvForm} from "availity-reactstrap-validation";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {changePassword} from "../../store/changePassword/actions";


class ChangePassword extends React.Component
{

    constructor(props) {
        super(props);
        this.state = {
        };
    }


    componentDidMount() {

    }

    handleValidSubmit(event, values) {
        this.props.changePassword({
            opassword:values.old_password,
            password:values.password
        });
    }


    render()
    {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Change Password | TextLaunchPad.com </title>
                    </MetaTags>
                    <div className="container-fluid">
                        <Breadcrumbs maintitle="TextLaunchPad"  breadcrumbItem="Change Password" />
                        <Row>
                            <Col className={'order-sm-12'}>
                                <Card>
                                    <CardBody>
                                        {this.props.apiError && typeof this.props.apiError === 'string' ? (
                                            <Alert color="danger" style={{ marginTop: "13px" }} className="mt-2">
                                                {this.props.apiError}
                                            </Alert>
                                        ) : null}

                                        {this.props.apiError && typeof this.props.apiError === 'object' ? (
                                            (this.props.apiError.response ? (
                                                <Alert color="danger" style={{ marginTop: "13px" }} className="mt-2">
                                                    {this.props.apiError.response.data.error}
                                                </Alert>
                                            ):(
                                                <Alert color="danger" style={{ marginTop: "13px" }} className="mt-2">
                                                    {this.props.apiError.message}
                                                </Alert>
                                            ))
                                        ) : null}

                                        {this.props.successMsg ? (
                                            <Alert color="success" style={{ marginTop: "13px" }} className="mt-2">
                                                {this.props.successMsg}
                                            </Alert>
                                        ) : null}

                                        <AvForm
                                            onValidSubmit={(e, v) => this.handleValidSubmit(e, v)}
                                            className="needs-validation">
                                            <Row>
                                                <Col xl="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom03">
                                                            Old Password
                                                        </Label>
                                                        <AvField
                                                            name="old_password"
                                                            placeholder="Password"
                                                            type="password"
                                                            errorMessage="Enter Password"
                                                            className="form-control"
                                                            validate={{ required: { value: true }}}
                                                            id="validationCustom03"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom01">
                                                            Password
                                                        </Label>
                                                        <AvField
                                                            name="password"
                                                            placeholder="Password"
                                                            type="password"
                                                            errorMessage="Enter Valid Password"
                                                            className="form-control"
                                                            validate={{ required: { value: true },minLength:{value:8,errorMessage: "Password must be at least 8 chars."} }}
                                                            id="validationCustom01"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xl="6">
                                                    <FormGroup className="mb-3">
                                                        <Label htmlFor="validationCustom02">
                                                            Confirm Password
                                                        </Label>
                                                        <AvField
                                                            name="confirm_password"
                                                            placeholder="Confirm Password"
                                                            type="password"
                                                            errorMessage="Enter Password"
                                                            className="form-control"
                                                            validate={{ required: { value: true },minLength:{value:8,errorMessage: "Password must be at least 8 chars."},match:{value:'password',errorMessage:"Password does not match"} }}
                                                            id="validationCustom01"
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <div className="d-flex flex-wrap gap-2">
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className=""
                                                >
                                                    Submit
                                                </Button>{" "}
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ChangePassword.propTypes = {
    apiError: PropTypes.any,
    successMsg: PropTypes.any,
    changePassword: PropTypes.func
}

const mapStatetoProps = state => {
    const { apiError, successMsg,  changePassword } = state.ChangePassword
    return { apiError, successMsg, changePassword }
}

export default withRouter(
    connect(mapStatetoProps, { changePassword })(ChangePassword)
)
