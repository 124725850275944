import React, { useState } from "react"
import {
  Container,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Label,
  Card,
  CardBody,
  FormGroup,
  Input,
  Alert,
} from "reactstrap"
import MetaTags from "react-meta-tags"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { Link, Redirect } from "react-router-dom"
import toast, { Toaster } from "react-hot-toast"

// import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import axios from "axios"
const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      if (typeof text !== "string") {
        if (text?.message && typeof text?.message === "string") {
          toast.error(text?.message)
        } else {
          toast.error("An unexpected error occured")
        }
      } else {
        toast.error(text)
      }
      break

    default:
      break
  }
}

const ImportDncFile = () => {
  let authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user.user.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  // const navigate = useHistory()
  
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [res, setRes] = useState("")
  const [data, setData] = useState([])
  const [dnc, setDnc] = useState(true)
  const [shouldRedirect, setShouldRedirect] = useState({
    state: false,
    path: "",
  })
  //upload file
  const upload_file = async () => {
    if (!file) {
      return
    }
    if (file.type !== "text/csv") {
      notify('error',"file type must be csv" )
      // setRes("file type must be csv")
      // setTimeout(() => {
      //   setRes("")
      // }, 2000)
      return
    }
    // let a = [{"phone":241545564,"dnc":true,"first_name":"test","last_name":"test last name"},{"phone":253456349,"dnc":true,"first_name":"test1","last_name":"test last name1"},{"phone":263456789,"dnc":false,"first_name":"test2","last_name":"test last name2"},{"phone":273456789,"dnc":false,"first_name":"test3","last_name":"test last name3"}]
    let formData = {
      data: JSON.stringify(data),
      //   data: JSON.stringify(a),
    }
    if(dnc)
    {
      try{
        setLoading(true)
        const res = await axios.post(
          process.env.REACT_APP_PORT + "/api/prospect/importDNCCSV",
          formData,
          config
        )
        if (res.status === 200) {
          notify("success",'file uploaded successfully')
          // setRes("file uploaded successfully")
          setLoading(false)
          setTimeout(() => {
            // setRes("")
            // navigate.push("/dnc-list")
            setShouldRedirect({
              state: true,
              path: "/restrictedContact/dnc-list",
            })
          }, 2000)
        } 
        // else {
        //   setRes("file upload failed")
        //   setTimeout(() => {
        //     setRes("")
        //     setLoading(false)
        //   }, 2000)
        // }
  
      }
      catch(err){
        notify('error',err)
        setLoading(false)
  
      }
    }
    else{
      try{
        setLoading(true)
        const res = await axios.post(
          process.env.REACT_APP_PORT + "/api/wrongNumbers/importByCsv",
          formData,
          config
        )
        if (res.status === 200) {
          notify("success",'file uploaded successfully')
          // setRes("file uploaded successfully")
          setLoading(false)
          setTimeout(() => {
            // setRes("")
            // navigate.push("/dnc-list")
            setShouldRedirect({
              state: true,
              path: "/restrictedContact/wrongNumber",
            })
          }, 2000)
        } 
        // else {
        //   setRes("file upload failed")
        //   setTimeout(() => {
        //     setRes("")
        //     setLoading(false)
        //   }, 2000)
        // }
  
      }
      catch(err){
        notify('error',err)
        setLoading(false)
      }
    }
  
   
  }

  ///read csv
  const handleFileUpload = event => {
    const file = event.target.files[0]
    const reader = new FileReader()

    reader.onload = e => {
      const content = e.target.result
      processData(content)
    }

    reader.readAsText(file)
  }

  const processData = content => {
    const rows = content.split("\n")
    const headers = rows[0].split(",")
    const data = []

    for (let i = 1; i < rows.length; i++) {
      const row = rows[i].split(",")
      if (row.length === headers.length) {
        const rowData = {}
        for (let j = 0; j < headers.length; j++) {
          const key = headers[j].trim()
          const value = row[j].trim().replace(/\r/g, "")
          rowData[key] = value
        }
        data.push(rowData)
      }
    }
    setData(data)
  }

  return (
    <React.Fragment>
      <Toaster/>
      {shouldRedirect && shouldRedirect.state && (
        <Redirect to={shouldRedirect.path} />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | TextLaunchPad.com</title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12}>
              <div className="page-title-box">
                <h4 className="font-size-18">Upload Restricted Contact</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/#">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Restricted Contact</BreadcrumbItem>
                  {/* <BreadcrumbItem>
                    <Link to="/dnc-list">Dnc List</Link>
                  </BreadcrumbItem> */}
                  <BreadcrumbItem active>
                    Upload Restricted Contact
                  </BreadcrumbItem>
                </ol>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center pt-4">
            <Col xl="6">
              {res && (
                <Alert
                  color={
                    res == "file uploaded successfully" ? "success" : "danger"
                  }
                >
                  {res}
                </Alert>
              )}
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Label className="form-check-label mb-2">
                      Select Contact Type
                    </Label>
                    <div className="d-flex gap-5 mb-2">
                        <div className="d-flex gap-2">
                        <Input
                          type="radio"
                          id="customRadioInline1"
                          name="contactType"
                          className="form-check-input"
                          defaultChecked={dnc}
                          onChange={()=> setDnc(true)}
                        />
                        <Label
                          className="form-check-label"
                          htmlFor="customRadioInline1"
                        >
                          DNC 
                        </Label>
                         </div>
                        <div className="d-flex gap-2">
                        <Input
                          type="radio"
                          label="wrongNumber  "
                          id="forWrongNumber"
                          className="form-check-input"
                          name="contactType"
                          
                          onChange={()=> setDnc(false)  }
                        />
                         <Label
                          className="form-check-label"
                          htmlFor="forWrongNumber"
                        >
                          Wrong Number
                        </Label>
                       

                        </div>
                        </div>

                    <FormGroup className="mb-3">
                      <Label htmlFor="uploadContact01">Upload File</Label>
                      <input
                        name="upload_file"
                        type="file"
                        // validate={{required:{value:true}}}
                        className="form-control"
                        id="uploadContact01"
                        onChange={e => {
                          setFile(e.target.files[0])
                          handleFileUpload(e)
                        }}
                      />
                    </FormGroup>
                    <Button
                      type="submit"
                      disabled={loading}
                      onClick={() => {
                        upload_file()
                        // navigate.push("/prospects", {state: {from: 2}})
                        // setShouldRedirect({
                        //   state: true,
                        //   path: `/prospects`,
                        // })
                      }}
                      className="btn btn-primary-contact"
                    >
                      {loading ? "Uploading..." : "Save"}
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ImportDncFile
