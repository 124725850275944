import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Select from "react-select"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  BreadcrumbItem,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
} from "reactstrap"
import MetaTags from "react-meta-tags"

import { Link, Redirect } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import axios from "axios"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import Whitesettingicon from "../../assets/images/settingWhite.svg"

import { connect } from "react-redux"

import plusicon from "../../assets/images/white-plus.svg"

import drop from "../../assets/images/drop.svg"
import kebabMenu from "../../assets/images/kebab-icon.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"

import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"
import sDrop from "../../assets/images/select-drop.svg"
import phoneIcon from "../../assets/images/phone.svg"
import boltIcon from "../../assets/images/bolt.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import toast, { Toaster } from "react-hot-toast"
import DataTable from "react-data-table-component"
import "chartist/dist/scss/chartist.scss"
import moment from "moment"

//i18n
import { withTranslation } from "react-i18next"
import { success } from "toastr"

const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      toast.error(text)
      break

    default:
      break
  }
}

const Tags = props => {
  const [modal, setModal] = useState({ isOpen: false })
  const [colors, setColors] = useState([])
  const [tags, setTags] = useState([])
  const [selectedColor, setSelectedColor] = useState(null)
  const [deleteAlert, setDeleteAlert] = useState({
    isOpen: false,
  })
  const [isLoading, setIsLoading] = useState(true)

  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  const getTags = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/tags/pullTags",
        config
      )

      if (response.data) {
        setTags(response.data.data)
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }

    // if (response.data) {
    //   let tempArr = response.data.data.map(item => {
    //     return { ...item, isExpanded: false }
    //   })
    //   this.state.tagsTemplate(tempArr);
    // }
  }

  const getColors = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/colors/pullColors",
        config
      )

      if (response.data.data) {
        setColors(response.data.data)
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }

    // if (response.data) {
    //   let tempArr = response.data.data.map(item => {
    //     return { ...item, isExpanded: false }
    //   })
    //   this.state.tagsTemplate(tempArr);
    // }
  }

  const batchData1 = tags.map((item, index) => ({
    color: (
      <div
        className="tag-color-dis"
        style={{ backgroundColor: `${item.color.color}` }}
      ></div>
    ),
    name: item.name,
    prospects: item.prospectsCount,
    created: moment
      .utc(item.color.createdAt)
      .local()
      .format("MM-DD-YYYY | hh:mm A"),
    edit: (
      <Button
        onClick={() => {
          openModalHandler("updateTag", item)
        }}
        className={"has-icon me-2"}
        color={"outline-primary"}
      >
        <i className="fas fa-pencil-alt"></i>
      </Button>
    ),
    delete: (
      <Button
        onClick={() => {
          setDeleteAlert({ isOpen: true, ...item })
        }}
        className={"has-icon"}
        color={"outline-danger"}
      >
        <i className="fas fa-trash-alt"></i>
      </Button>
    ),
  }))

  const batchesColumns = [
    {
      name: "Color",
      selector: "color",
    },
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "Prospects",
      selector: "prospects",
    },
    {
      name: "Created",
      selector: "created",
    },
    {
      name: "Edit",
      selector: "edit",
    },
    {
      name: "Delete",
      selector: "delete",
    },
  ]

  const openModalHandler = (action, data = {}) => {
    switch (action) {
      case "createTag": {
        setModal({
          isOpen: true,
          title: "Create Tag",
          confirmText: "Save",
          action,
        })
        break
      }
      case "updateTag": {
        setSelectedColor(data.colorId)
        setModal({
          isOpen: true,
          title: "Update Tag",
          confirmText: "Update",
          ...data,
          action,
        })
        break
      }

      default:
        break
    }
  }

  const handleModalSubmit = (e, value) => {
    if (selectedColor) {
      value.colorId = selectedColor
    }
    switch (modal.action) {
      case "createTag":
        handleCreatTag(value)
        break
      case "updateTag":
        handleUpdateTag(value, modal._id)
        break
    }
  }
  const handleCreatTag = async val => {
    try {
      const data = {
        name: val.name?.trim(),
        colorId: val.colorId,
      }
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/tags/createTag",
        data,
        config
      )

      if (response) {
        setSelectedColor(null)
        getTags()
        setModal({ isOpen: !modal?.isOpen })
        notify("success", "Tag Created Successfully")
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const handleUpdateTag = async (val, id) => {
    try {
      const data = {
        name: val.name?.trim(),
        colorId: val.colorId,
      }
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/tags/updateTag/${id}`,
        data,
        config
      )
      if (response) {
        setSelectedColor(null)
        getTags()
        setModal({ isOpen: !modal?.isOpen })

        notify("success", "Tag Updated Successfully")
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const handleDeleteTag = async () => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT + "/api/tags/deleteTag/" + deleteAlert._id,
        config
      )
      if (response) {
        getTags()
        setDeleteAlert({ isOpen: false })
        notify("success", "Tag Deleted Successfully")
      }
    } catch (err) {
      setDeleteAlert({ isOpen: false })
      notify("error", err.response.data.error)
    }
  }

  useEffect(() => {
    getColors()
    getTags()
  }, [])
  return (
    <React.Fragment>
      {isLoading && (
        <div className="spinner-wr">
          <Spinner style={{ color: "#5932ea" }} />
        </div>
      )}
      <Toaster />
      {deleteAlert && deleteAlert.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            handleDeleteTag()
          }}
          onCancel={() => {
            setDeleteAlert({ isOpen: false })
          }}
        >
          You want to delete <strong>{deleteAlert.name}</strong>. You won't be
          able to revert this!
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Tags | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Toaster />

          <Row className="align-items-center breadcrumb-box mb-5">
            <div className="col-md-8 col-12">
              <div className="page-title-box">
                <h4  className="font-size-18">Tags Management</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  
                  <BreadcrumbItem active>Tags Management </BreadcrumbItem>
                </ol>
              </div>
            </div>
            <div className="col-md-4 col-12 d-flex gap-3">
              <button
                className="main-btn border-btn mt-3"
                // to="/add-campaign"
                onClick={() => openModalHandler("createTag")}
                type="button"
              >
                <img src={plusPurple} alt="" />
                <span>Add Tag</span>
              </button>

              {/* <button
                                className="main-btn mt-3"
                                // to="/add-campaign"
                                onClick={() => openModalHandler('createTag')}
                                type="button"
                            >
                                <img src={plusicon} alt="" />
                                <span>Add Tag</span>
                            </button> */}
              {/* <button
                                className="main-btn mt-3"
                                // to="/add-campaign"
                                //   onClick={() => this.tog_add()}
                                type="button"
                            >
                                <img src={settingicon} alt="" />
                                <span>Manage Colors</span>
                            </button> */}
              <Link to="/manageColors" className="main-btn mt-3">
                <img src={Whitesettingicon} alt="" />
                <span>Manage Colors</span>
              </Link>
            </div>
          </Row>
          <DataTable
            // title="Campaign Metrics"
            columns={batchesColumns}
            data={batchData1}
            fixedHeader
            pagination
            className="c-table"
          />
        </div>
      </div>
      <Modal
        isOpen={modal?.isOpen}
        toggle={() => {
          setModal({ isOpen: !modal?.isOpen })
        }}
        className="c-modal"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">{modal?.title}</h5>
          <button
            type="button"
            onClick={() => {
              setModal({ isOpen: false })
              setSelectedColor(null)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleModalSubmit(e, v)}
              // onSubmit={(e, v) => handleModalSubmit(e, v, modal?.title)}
              className="needs-validation"
            >
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="name"
                      placeholder="Tag Name"
                      type="text"
                      errorMessage="Enter Tag Name"
                      className="form-control cc-form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid Tag name",
                        },
                      }}
                      id="validationCustom01"
                      defaultValue={modal.name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <Select
                      className="cc-form-control"
                      name="colorId"
                      defaultValue={{
                        value: modal.colorId,
                        label: (
                          <>
                            {modal.colorId ? (
                              <div className="d-flex gap-3 p-2">
                                <div
                                  className="tag-color-dis mx-2"
                                  style={{
                                    backgroundColor: colors?.filter(
                                      item => item._id === modal.colorId
                                    )?.[0]?.color,
                                  }}
                                ></div>
                                {
                                  colors?.filter(
                                    item => item._id === modal.colorId
                                  )?.[0]?.name
                                }
                              </div>
                            ) : (
                              <div className="d-flex gap-3 p-2">
                                Please select color
                              </div>
                            )}
                          </>
                        ),
                      }}
                      options={colors.map((item, index) => ({
                        value: item._id,
                        label: (
                          <div className="d-flex gap-2 p-2" key={index}>
                            <div
                              className="tag-color-dis"
                              style={{ backgroundColor: item.color }}
                            ></div>
                            {item.name}
                          </div>
                        ),
                      }))}
                      isSearchable={false} // Optional: Disable the search bar
                      placeholder="Select Color"
                      onChange={e => {
                        setSelectedColor(e.value)
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Link
                  to="/tags"
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setModal({ isOpen: false })
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Link>
                <Button
                  type="submit"
                  color="primary"
                  className="main-btn"
                  disabled={!selectedColor && !modal.colorId}
                >
                  <span>{modal?.confirmText}</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>
    </React.Fragment>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(Tags)
