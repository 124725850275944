import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Modal,
  FormGroup,
  Button,
  Spinner,
  BreadcrumbItem,
} from "reactstrap"
import MetaTags from "react-meta-tags"

import { Link, Redirect } from "react-router-dom"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import axios from "axios"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import Whitesettingicon from "../../assets/images/settingWhite.svg"
import { connect } from "react-redux"
import plusicon from "../../assets/images/white-plus.svg"
import drop from "../../assets/images/drop.svg"
import kebabMenu from "../../assets/images/kebab-icon.svg"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"
import sDrop from "../../assets/images/select-drop.svg"
import phoneIcon from "../../assets/images/phone.svg"
import boltIcon from "../../assets/images/bolt.svg"
import plusPurple from "../../assets/images/plus-purple.svg"
import toast, { Toaster } from "react-hot-toast"
import DataTable from "react-data-table-component"
import "chartist/dist/scss/chartist.scss"
import moment from "moment"

//i18n
import { withTranslation } from "react-i18next"

const notify = (type, text) => {
  switch (type) {
    case "success":
      toast.success(text)
      break
    case "error":
      toast.error(text)
      break

    default:
      break
  }
}

const Rules = props => {
  const [modal, setModal] = useState({ isOpen: false, day: 1 })
  const [rules, setRules] = useState([])

  const [deleteAlert, setDeleteAlert] = useState({
    isOpen: false,
  })
  const [isLoading, setIsLoading] = useState(true)

  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  const defaultObjSchema = {
    day: 1,
    // message: "",
    // showTextSpinner: false,
    // messageCursorPosition: 0,
    // isOptOutOpen: false,
  }

  const getRules = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_PORT + "/api/skipRules/pullSkipRules",
        config
      )

      if (response.data) {
        setRules(response.data.data)
        setIsLoading(false)
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const handleDayCount = (index, action, value) => {
    let tempArr = [...dataArr]
    if (action === "inc") {
      tempArr[index].day++
      setDataArr([...tempArr])
    } else if (action === "dec" && tempArr[index].day >= 2) {
      tempArr[index].day--
    } else if (action === "change") {
      tempArr[index].day = +value
    }
    setDataArr([...tempArr])
    // setReloadKey(Math.random())
  }

  const rulesData = rules?.map((item, index) => ({
    name: item.name,
    type: item.type,

    edit: (
      <Button
        key={index}
        onClick={() => {
          openModalHandler("updateSkipRule", item)
        }}
        className={"has-icon me-2"}
        color={"outline-primary"}
        disabled={item.name == "any"}
      >
        <i className="fas fa-pencil-alt"></i>
      </Button>
    ),
    delete: (
      <Button
        key={index}
        onClick={() => {
          setDeleteAlert({ isOpen: true, ...item })
        }}
        className={"has-icon"}
        color={"outline-danger"}
        disabled={item.name == "any"}
      >
        <i className="fas fa-trash-alt"></i>
      </Button>
    ),
  }))

  const batchesColumns = [
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "Type",
      selector: "type",
    },

    {
      name: "Edit",
      selector: "edit",
    },
    {
      name: "Delete",
      selector: "delete",
    },
  ]

  const openModalHandler = (action, data = {}) => {
    switch (action) {
      case "createSkipRule": {
        setModal({
          isOpen: true,
          title: "Add Day Skip Rule",
          confirmText: "Save",
          action,
          dayCount: 1,
        })
        break
      }
      case "updateSkipRule": {
        setModal({
          isOpen: true,
          title: "Update Day Skip Rule",
          confirmText: "Update",
          dayCount: +data?.data?.dayCount || 1,
          action,
          ...data,
        })
        break
      }
      default:
        break
    }
  }

  const handleModalSubmit = (e, value) => {
    switch (modal.action) {
      case "createSkipRule":
        handleCreateRule(value)
        break
      case "updateSkipRule":
        handleUpdateRule(value, modal._id)
        break
    }
  }
  const handleCreateRule = async val => {
    try {
      const data = {
        name: val.name.trim(),
        type: "skipDayRule",
        data: {
          dayCount: modal.dayCount,
        },
      }
      const response = await axios.post(
        process.env.REACT_APP_PORT + "/api/skipRules/createSkipRule",
        data,
        config
      )
      if (response) {
        getRules()
        setModal({ isOpen: !modal?.isOpen })
        notify("success", "Skip Rule Created Successfully")
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const handleUpdateRule = async (val, id) => {
    try {
      const data = {
        name: val.name,
        type: "skipDayRule",
        data: {
          dayCount: modal.dayCount,
        },
      }
      const response = await axios.put(
        process.env.REACT_APP_PORT + `/api/skipRules/updateSkipRule/${id}`,
        data,
        config
      )
      if (response) {
        getRules()
        setModal({ isOpen: !modal?.isOpen })

        notify("success", "Skip Rule Updated Successfully")
      }
    } catch (err) {
      notify("error", err.response.data.error)
    }
  }
  const handleDeleteRule = async () => {
    try {
      const response = await axios.delete(
        process.env.REACT_APP_PORT +
          "/api/skipRules/deleteSkipRule/" +
          deleteAlert._id,
        config
      )
      if (response) {
        getRules()
        setDeleteAlert({ isOpen: false })
        notify("success", "Skip Rule Deleted Successfully")
      }
    } catch (err) {
      setDeleteAlert({ isOpen: false })
      notify("error", err.response.data.error)
    }
  }
  const [dataArr, setDataArr] = useState([defaultObjSchema])

  useEffect(() => {
    getRules()
  }, [])
  return (
    <React.Fragment>
      {isLoading && (
        <div className="spinner-wr">
          <Spinner style={{ color: "#5932ea" }} />
        </div>
      )}
      <Toaster />
      {deleteAlert && deleteAlert.isOpen ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnText="Yes, remove it"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="dark"
          onConfirm={() => {
            handleDeleteRule()
          }}
          onCancel={() => {
            setDeleteAlert({ isOpen: false })
          }}
        >
          You want to delete <strong>{deleteAlert.name}</strong>. You won't be
          able to revert this!
        </SweetAlert>
      ) : null}
      <div className="page-content">
        <MetaTags>
          <title>Skip Rules Management | TextLaunchPad.com </title>
        </MetaTags>
        <div className="container-fluid">
          <Toaster />

          <Row className="align-items-center breadcrumb-box mb-5">
            <div className="col-md-8 col-12">
              <div className="page-title-box">
                <h4 className="font-size-18">Skip Rules Management</h4>
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Skip Rules Management </BreadcrumbItem>
                </ol>
              </div>
            </div>

            <div className="col-md-4 col-12 d-flex flex-row-reverse">
            
              <button
                className="main-btn"
                onClick={() => openModalHandler("createSkipRule")}
                type="button"
              >
                <img src={plus} alt="" />
                <span>Add Day Skip Rule</span>
              </button>
            </div>
          </Row>
          <DataTable
            // title="Campaign Metrics"
            columns={batchesColumns}
            data={rulesData}
            fixedHeader
            pagination
            className="c-table"
          />
        </div>
      </div>
      <Modal
        isOpen={modal?.isOpen}
        toggle={() => {
          setModal({ isOpen: !modal?.isOpen })
        }}
        className="c-modal"
        // backdrop="static"
        centered={true}
      >
        <div className="modal-body ">
          <h5 className="modal-title mt-0 mb-3">{modal?.title}</h5>
          <button
            type="button"
            onClick={() => {
              setModal({ isOpen: false })
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img src={close} alt="" />
          </button>

          {
            <AvForm
              onValidSubmit={(e, v) => handleModalSubmit(e, v)}
              // onSubmit={(e, v) => handleModalSubmit(e, v, modal?.title)}
              className="needs-validation"
            >
              <Row>
                <Col xl="12">
                  <FormGroup className="mb-3">
                    <AvField
                      name="name"
                      placeholder="Skip Day Rule Name"
                      type="text"
                      errorMessage="Enter Skip Day Rule Name"
                      className="form-control cc-form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid Rule name",
                        },
                      }}
                      id="validationCustom01"
                      defaultValue={modal.name}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col style={{ marginLeft: "20px" }}>
                  <div className="d-aut-head d-flex flex-row align-items-center gap-3">
                    <p className="mb-0">Day Count</p>
                    <div className="d-aut-counter">
                      <span
                        type="button"
                        className=" rounded-circle bg-light text-dark d-flex flex-row justify-content-center align-items-center"
                        style={{ width: "50px", height: "50px" }}
                        onClick={() => {
                          if (modal.dayCount > 1)
                            setModal({
                              ...modal,
                              dayCount: +modal.dayCount - 1,
                            })
                        }}
                      >
                        -
                      </span>
                      {modal && !isNaN(modal.dayCount) && (
                        <input
                          type="number"
                          value={+modal.dayCount}
                          onChange={e => {
                            setModal({ ...modal, dayCount: +e.target.value })
                          }}
                        />
                      )}
                      <span
                        type="button"
                        className="main-btn color text-white rounded-circle display-1 "
                        style={{ width: "50px", height: "50px" }}
                        onClick={() =>
                          setModal({ ...modal, dayCount: +modal.dayCount + 1 })
                        }
                      >
                        +
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                <Link
                  to="/skipRules"
                  className="main-btn secondary-btn"
                  onClick={() => {
                    setModal({ isOpen: false })
                  }}
                  role="button"
                >
                  <span>Cancel</span>
                </Link>
                <Button
                  type="submit"
                  color="primary"
                  className="main-btn"
                  // disabled={!selectedColor && !modal.colorId}
                >
                  <span>{modal?.confirmText}</span>
                </Button>{" "}
              </div>
            </AvForm>
          }
        </div>
      </Modal>
    </React.Fragment>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any
// }

export default withTranslation()(Rules)
