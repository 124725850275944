import React, { useState,useRef } from "react"
import MetaTags from "react-meta-tags"
import Axios from "axios"
import { useEffect } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Modal,
  Label,
  FormGroup,
  Alert,
  BreadcrumbItem,
  Button,
  Spinner,
  Container,
} from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"
import toast, { Toaster } from "react-hot-toast"
// import {
//   editCategory,
//   getCategories,
//   deleteCategory,
//   addCategory,
// } from "../../store/categories/actions"
import SweetAlert from "react-bootstrap-sweetalert"
import { AvForm, AvField } from "availity-reactstrap-validation"
import DataTable from "react-data-table-component"
import plus from "../../assets/images/white-plus.svg"
import close from "../../assets/images/close.svg"

const Categories = () => {
  const notify = (type, text) => {
    switch (type) {
      case "success":
        toast.success(text)
        break
      case "error":
        if (typeof text !== "string") {
          if (text?.message && typeof text?.message === "string") {
            toast.error(text?.message)
          } else {
            toast.error("An unexpected error occured")
          }
        } else {
          toast.error(text)
        }
        break

      default:
        break
    }
  }
  // states
  const [categories, setCategories] = useState([])
  const [modal, setModal] = useState({ isOpen: false, forFollowUp: false })
  const [deleteCategory, setDeleteCategory] = useState({ confirm_alert: false })
  const [tags, setTags] = useState(["[@companyName]", "[@repNameAlias]"])
  const [activeTab, setActiveTab] = useState("regular")
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalCount, setTotalCount] = useState(0)
  const [totalCategories, setTotalCategories] = useState()
  const [tableReloadKey, setTableReloadKey] = useState(Math.random())
  const [selectedMessageIndex, setSelectedMessageIndex] = useState() 
  const [formReloadKey, setFormReloadKey] = useState(Math.random())
  const [dataArr, setDataArr] = useState({
    alternatemessage: "",
    messageCursorPosition: 0,
  })

  const messageTextAreaRef = useRef(null)

  // hooks
  useEffect(() => {
    getCategories()
  }, [])
  // api calls
  const authUser = localStorage.getItem("authUser")
  let token = false
  if (authUser) {
    let user = JSON.parse(authUser)
    token = user?.user?.token
  } else {
    token = false
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  const getCategories = async (
    type = activeTab,
    crrPage = page,
    limit = rowsPerPage
  ) => {
    if (!type) {
      type = activeTab
    }
    const queryParams = new URLSearchParams({ type, limit, page: crrPage })
    setIsLoading(true)

    try {
      const response = await Axios.get(
        process.env.REACT_APP_PORT +
          `/api/template/getAllCategories?` +
          queryParams,
        config
      )

      if (response.data) {
        setTotalCount(response?.data?.totalCount)
        // setTotalCount(Math.ceil(+response?.data?.totalCount / limit))
        setTotalCategories(response?.data?.totalCount)
        setCategories(response?.data?.categories)
        setIsLoading(false)
      }
    } catch (err) {
      setIsLoading(false)
      notify("error", err?.response?.data?.error)
    }
  }
  const handleAddCategory = async value => {
    const data = {
      name: modal.name,
      alternatemessage: dataArr.alternatemessage,
      forFollowUp: modal.forFollowUp,
    }

    try {
      const response = await Axios.post(
        process.env.REACT_APP_PORT + "/api/template/createCategory",
        data,
        config
      )
      if (response.data) {
        setModal({ isOpen: false })
        setActiveTab(modal.forFollowUp ? "followUp" : "regular")
        getCategories(modal.forFollowUp ? "followUp" : "regular")
        notify("success", "Category added successfully")
        setDataArr({
          alternatemessage : '',
          messageCursorPosition  :0 
        })
      }
    } catch (err) {
      notify("error", err?.response?.data?.error)
    }
  }
  const handleDeleteCategory = async id => {
    try {
      const response = await Axios.post(
        process.env.REACT_APP_PORT + `/api/template/deleteCategory/${id}`,
        {},
        config
      )
      if (response) {
        getCategories()
        notify("success", "Category deleted successfully")
      }
    } catch (err) {
      notify("error", err?.response?.data?.error)
      getCategories()
    }
  }

  const handleUpdateCategory = async value => {
    const data = {
      name: modal?.name,
      alternatemessage: dataArr?.alternatemessage,
      forFollowUp: modal?.forFollowUp,
      id: modal?._id,
    }
    try {
      const response = await Axios.post(
        process.env.REACT_APP_PORT +
          `/api/template/updateCategory/${modal._id}`,
        data,
        config
      )

      if (response.data) {
        setModal({ isOpen: false })
        getCategories()
        notify("success", "Category updated successfully")
        setDataArr({
          alternatemessage : '',
          messageCursorPosition  :0 
        })
      }
    } catch (err) {
      notify("error", err?.response?.data?.error)
    }
  }
  // modal handle function
  const openModalHandler = (action, data = {}) => {
    switch (action) {
      case "addCategory": {
        setModal({
          isOpen: true,
          title: "Add Category",
          confirmText: "Submit",
          action,
        })
        setDataArr({
          alternatemessage : '',
          messageCursorPosition  :0 
        })
        
        break
      }
      case "updateCategory": {
        setModal({
          isOpen: true,
          title: "Edit Category",
          confirmText: "Update",
          action,
          ...data,
        })
        setDataArr({
          alternatemessage : data?.alternatemessage,
          messageCursorPosition  :data?.alternatemessage?.length
        })
        break
      }
      default:
        break
    }
  }

  const handleModalSubmit = (e, value) => {
    switch (modal.action) {
      case "addCategory":
        handleAddCategory(value)
        break
      case "updateCategory":
        handleUpdateCategory(value, modal._id)
        break
    }
  }



  

  // data table columns
  const categoriesColumns = [
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "Validation",
      selector: "validation",
    },
    {
      name: "Edit",
      selector: "edit",
    },
    {
      name: "Delete",
      selector: "delete",
    },
  ]

  const categoriesData =
    categories && categories.length
      ? categories?.map((item, index) => ({
          id: item?._id,
          name: item?.name,
          // type: item.forFollowUp ? "Follow-Up" : "Regular",

          validation: (
            <span className={item.validations ? "text-success" : "text-danger"}>
              {item.validations ? "Set" : "Unset"}
            </span>
          ),
          edit: (
            <Button
              className={"has-icon me-2"}
              color={"outline-primary"}
              onClick={() => openModalHandler("updateCategory", item)}
              // onClick={() =>
              //   this.editCategory(
              //     item.name,
              //     item._id,
              //     item.alternatemessage,
              //     item.forFollowUp
              //   )
              // }
            >
              <i className="fas fa-edit"></i>
            </Button>
          ),
          delete: (
            <Button
              onClick={() =>
                setDeleteCategory({
                  category_name: item?.name,
                  category_id: item?._id,
                  confirm_alert: true,
                  confirm_button_text:
                    "Yes, remove " + deleteCategory.category_name,
                })
              }
              className={"has-icon mx-1"}
              color={"outline-danger"}
            >
              <i className="fas fa-trash-alt"></i>
            </Button>
          ),
        }))
      : []

  // follow up category columns

  const handleSelectedChange = e => {
    const textArea = e.target
    const selectionStartIndex = textArea.selectionStart
    const selectionEndIndex = textArea.selectionEnd
    if (selectionStartIndex || selectionEndIndex) {
      setSelectedMessageIndex({
        start: selectionStartIndex,
        end: selectionEndIndex,
      })
    }
  }


  function updateMessageCursorPosition(position) {
    let tempArr = dataArr
    tempArr.messageCursorPosition = position
    setDataArr(tempArr)
  }

  function addTag(tag) {
    let tempArr = dataArr
    if(selectedMessageIndex?.start || selectedMessageIndex?.end)
    {
      const start = selectedMessageIndex.start
      const end = selectedMessageIndex.end
      tempArr.alternatemessage =
        tempArr.alternatemessage.substring(0, start) +
        tag +
        tempArr.alternatemessage.substring(end)

        setSelectedMessageIndex()
        updateMessageCursorPosition(start + tag.length)
        
    }
     else {
      tempArr.alternatemessage =
        tempArr.alternatemessage?.slice(0, tempArr.messageCursorPosition) +
        tag +
        tempArr.alternatemessage?.slice(tempArr.messageCursorPosition)
        updateMessageCursorPosition(tempArr.messageCursorPosition + tag.length )
    }

    setDataArr(tempArr)
    setFormReloadKey(Math.random())
    
  }

  function handleDataChange(key, val) {
    let tempArr = dataArr
    tempArr[key] = val
    setDataArr(tempArr)
  }

  return (
    <React.Fragment>
      <Toaster />
      <div className="page-content">
        <MetaTags>
          <title>Categories | TextLaunchPad.com </title>
        </MetaTags>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={6}>
              <div className="page-title-box">
                <h4 className="font-size-18" style={{ display: "inline" }}>
                  Categories
                </h4>
                {/* <span style={{ display: "inline", color: "#185cff" }}>
                  &nbsp; {totalCategories ? `( ${totalCategories} )` : null}
                </span> */}
                <ol className="breadcrumb mb-0">
                  <BreadcrumbItem>
                    <Link to="/#">TextLaunchPad</Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem active>Categories</BreadcrumbItem>
                </ol>
              </div>
            </Col>
            <Col xs={6}>
              <div className="float-end">
                <button
                  className="main-btn"
                  type="button"
                  onClick={() => openModalHandler("addCategory")}
                  role="button"
                >
                  <img src={plus} alt="" />
                  <span>Add Category</span>
                </button>
              </div>
            </Col>
            <Col md={12} className={"pb-2"}>
              <span
                className={
                  "border border-2 border-start-0 border-top-0 border-end-0 border-bottom-2 d-block"
                }
              ></span>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xl={12} md={12} sm={12}>
              <div className="d-flex gap-3 px-3">
                <div
                  xl={3}
                  md={3}
                  sm={3}
                  className="d-flex justify-content-center"
                >
                  <button
                    className={activeTab === "regular" ? "active-tab" : "tabs"}
                    onClick={() => {
                      setActiveTab("regular")
                      setPage(1)
                      setTableReloadKey(Math.random)
                      getCategories("regular", 1)
                    }}
                  >
                    {/* {activeTab === "regular" && !isLoading ? totalCategories : null } */}
                    {/* {isNaN(totalCategories) ? "" : `(${totalCategories})`} */}
                    {activeTab === "regular"
                      ? `${totalCategories ? totalCategories : '' } Initial Messages  `
                      : `Initial Messages`}
                    {/* {activeTab === "regular" ? (
                      <span
                        style={{
                          display: "inline",
                          color: "#8a42e2",
                          fontWeight: "400",
                        }}
                      >
                        {isNaN(totalCategories) ? "" : `(${totalCategories})`}
                      </span>
                    ) : null} */}
                  </button>
                </div>
                <div
                  xl={3}
                  md={3}
                  sm={3}
                  className="d-flex justify-content-center"
                >
                  <button
                    className={activeTab === "followUp" ? "active-tab" : "tabs"}
                    onClick={() => {
                      setActiveTab("followUp")
                      setPage(1)
                      setTableReloadKey(Math.random)
                      getCategories("followUp", 1)
                    }}
                  >
                    {/* {activeTab === "followUp"  && !isLoading  ? totalCategories : null }
                     {"  "} */}
                    {activeTab === "followUp"
                      ? `${totalCategories ? totalCategories : '' } Follow-Ups `
                      : `Follow-Ups `}
                    {"  "}
                    {/* {activeTab === "followUp" ? (
                      <span
                        style={{
                          display: "inline",
                          color: "#8a42e2",
                          fontWeight: "400",
                        }}
                      >
                        {isNaN(totalCategories) ? "" : `(${totalCategories})`}
                      </span>
                    ) : null} */}
                  </button>
                </div>
              </div>
            </Col>
          </Row>

          {/* Regular section */}
          {/* data table  */}

          {isLoading ? (
            <div className="spinner-wr">
              <Spinner style={{ color: "#5932ea" }} />
            </div>
          ) : null}

          <DataTable
            key={tableReloadKey}
            // title="Wrong Numbers"
            columns={categoriesColumns}
            data={categoriesData}
            fixedHeader
            highlightOnHover
            pagination
            paginationServer
            paginationTotalRows={totalCount}
            paginationPerPage={rowsPerPage}
            onChangeRowsPerPage={rows => {
              setRowsPerPage(rows)
              getCategories(null, 1, rows)
            }}
            onChangePage={page => {
              setPage(page)
              getCategories(null, page)
            }}
            // className="c-table"
          />

          {/* {isLoading ? <div className="spinner-wr">
                <Spinner style={{ color: "#5932ea" }} />
              </div> : null }
          <DataTable
            // title="Wrong Numbers"
            columns={followUpCategoriesColumns}
            data={categoriesData}
            fixedHeader
            highlightOnHover
            pagination
            // paginationServer
            // paginationTotalRows={totalCount}
            // paginationPerPage={rowsPerPage}
            // onChangeRowsPerPage={rows => {
            //   setRowsPerPage(rows)
            //   getWrongNumbers(page, rows)
            // }}
            // onChangePage={page => {
            //   setPage(page)
            //   getWrongNumbers(page)
            // }}
            // className="c-table"
            // selectableRows

            // onSelectedRowsChange={state =>
            //   setSelectedRows(state.selectedRows)
            // }

            keyField={"id"}
          /> */}

          {/* modal add and edit category */}
          



          {/* new modal  */}

          <Modal
             isOpen={modal.isOpen}
             toggle={() => {
               setModal({
                 isOpen: !modal.isOpen,
               })
             }}
            className="c-modal"
            centered={true}
          >
            <div className="modal-body ">
              <h5 className="modal-title mt-0 mb-3">{modal?.title}</h5>
              <button
                  type="button"
                  onClick={() => {
                    setModal({
                      isOpen: false,
                    })
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img src={close} alt="" />
                </button>

              {
                <AvForm
                key={formReloadKey}
                onValidSubmit={(e, v) => handleModalSubmit(e, v)}
                  className="needs-validation"
                >
                  <Row>
                  <Col>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Category</Label>
                    <AvField
                      name="category_name"
                      placeholder="Category Name"
                      type="text"
                      errorMessage="Enter Category Name"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        pattern: {
                          value: /^[A-Za-z0-9()$@#%^&*! -_\\]+$/,
                          errorMessage: "Enter valid category name",
                        },
                      }}
                      id="validationCustom01"
                      value={modal?.name}
                      onChange={e => {
                        setModal({
                          ...modal,
                          name: e.target.value,
                        })
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom02">
                      Alternate Message
                    </Label>
                    <AvField
                      name="alternate_message"
                      placeholder="Alternate Message"
                      type="textarea"
                      ref={messageTextAreaRef}
                      errorMessage="Enter Alternate Message"
                      className="form-control"
                      validate={{
                        required: { value: true },
                        maxLength: { value: 320 },
                      }}
                      id="validationCustom02"
                      value={dataArr.alternatemessage}
                      rows={5}
                      
                      onChange={e => {
                        handleDataChange("alternatemessage", e.target.value)
                        updateMessageCursorPosition(e.target.selectionStart)
                      }}
                      

                      onClick={e => {
                        updateMessageCursorPosition(e.target.selectionStart)
                      }}

                      onSelect ={e=>{
                        handleSelectedChange(e)
                      }}
                    />
                    <Col sm={12}>
                      <div className="from-group mt-sm-12 mt-3">
                        <div className="inp-grp">
                          <div className="cus-checkbox">
                            <input
                              type="checkbox"
                              id="c1"
                              name="forfollowUP"
                              //  checked={setModal({
                              //   forFollowUp : !modal.forFollowUp
                              // })}
                              // value={modal?.forFollowUp}
                              defaultChecked={modal?.forFollowUp}
                              on
                              onChange={() => {
                                setModal({
                                  ...modal,
                                  forFollowUp: !modal.forFollowUp,
                                })
                              }}
                            />
                            <label htmlFor="c1">
                              <span>For Follow-Up </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <span style={{ float: "right", fontSize: "12px" }}>
                      {dataArr?.alternatemessage?.length <= 160 ? (
                        <i
                          className="fas fa-check-circle fa-sm me-1"
                          style={{ color: "Green" }}
                        ></i>
                      ) : null}
                      {dataArr?.alternatemessage?.length <= 320 &&
                      dataArr?.alternatemessage?.length > 160 ? (
                        <i
                          className="fas fa-exclamation-circle fa-sm me-1"
                          style={{ color: "orange" }}
                        ></i>
                      ) : null}
                      {dataArr?.alternatemessage?.length > 320 ? (
                        <i
                          className="fas fa-times-circle fa-sm me-1"
                          style={{ color: "red" }}
                        ></i>
                      ) : null}
                      {dataArr?.alternatemessage?.length
                        ? dataArr?.alternatemessage?.length
                        : 0}
                      /320
                    </span>
                  </FormGroup>
                  <div className="mb-2 d-flex flex-wrap">
                    {tags?.map((item, index) => {
                      return (
                        <Button
                          key={index}
                          type={"button"}
                          color={"outline-primary"}
                          className="m-2"
                          onClick={() => {
                            addTag(item)
                          }}
                        >
                          {item}
                        </Button>
                      )
                    })}
                  </div>
                </Col>
                  </Row>

                  <div className="text-end py-2 d-flex justify-content-end align-items-center gap-2">
                    <Button
                      className="main-btn secondary-btn"
                      onClick={() => {
                        setModal({
                          isOpen: false,
                        })
                        setDataArr({
                          alternatemessage : '',
                          messageCursorPosition  :0 
                        })
                      }}
                      role="button"
                    >
                      <span>Cancel</span>
                    </Button>
                    <Button type="submit" color="primary" className="main-btn">
                      <span>{modal?.confirmText}</span>
                    </Button>{" "}
                  </div>
                </AvForm>
              }
            </div>
          </Modal>

          {/* delete sweet alert  */}
          {deleteCategory.confirm_alert ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmBtnText="Yes, remove it"
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="dark"
              onConfirm={() => {
                setDeleteCategory({ confirm_alert: false })
                handleDeleteCategory(deleteCategory.category_id)
              }}
              onCancel={() => {
                setDeleteCategory({ confirm_alert: false })
              }}
            >
              You won't be able to revert{" "}
              <strong>{deleteCategory?.category_name}</strong>!
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Categories
